import React from "react";
import Icon from '@material-ui/core/Icon'
import {IconUtil} from '../../util/icon-util';

import './BigIcon.css';

const ua = window.navigator.userAgent;
const isIE = /MSIE|Trident/.test(ua);
const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

const customStyles = {
    circle: {
        display: 'flex',
        borderRadius: '50%',
        width: '60px',
        height: '60px',
        border: '0.5px dashed #009550',
        marginTop: 0,
    },
    circleMobile: {
        display: 'flex',
        borderRadius: '50%',
        width: '5rem',
        height: '5rem',
        border: '0.5px dashed #009550',

    },
};
class GIEIcon extends React.Component<any, any> {

    getStyleMobile = () => {
        let css = null
        if (this.props.circle === true) {
            css = customStyles.circleMobile;
        }
        return css
    }

    getHeigtIcon = () => {
        return isIE ? '55%' : '100%';
    }

    getClassNameIcon = () => {
        let className = null;
        if (this.props.circle === true && isChrome) {
            className = 'linear-icon-outline2';
        } else if (this.props.circle === true && isIE) {
            className = 'linear-icon-outline4';
        } else if (this.props.circle === true) {
            className = 'linear-icon-outline3';
        }
        return className
    }

    getWidth = () => {
        let width = this.props.icon === 'handShake' ? '50%' : '45%'
        if (this.props.header === true) {
            width = null;
        }
        if (this.props.icon === 'menu') {
            width = null;
        }
        return width

    }

    getHeight = () => {
        let height;
        if (this.props.isMobile && isIE) {
            height = '55%';
        }
        if (this.props.isMobile && !isIE) {
            height = '100%';
        }
        if (!this.props.isMobile && isIE) {
            height = '80%';
        }
        if (!this.props.isMobile && !isIE) {
            height = '99%';
        }

        return height;
    }

    getMarginLeft = () => {
        let marginLeft;
        if (this.props.isMobile && isIE) {
            marginLeft = '2px';
        }
        if (this.props.isMobile && !isIE) {
            marginLeft = '0px';
        }
        if (!this.props.isMobile && isIE) {
            marginLeft = '3px';
        }
        if (!this.props.isMobile && !isIE) {
            marginLeft = '0px';
        }

        return marginLeft;
    }

    render() {
        let width = this.getWidth();

        return (
            <div>
                {this.props.isMobile === true ? (
                    <div style={this.getStyleMobile()}>
                        <Icon style={{margin: 'auto', height: this.getHeight(), width: width}}>
                            <img style={{marginLeft: this.getMarginLeft(), height: this.getHeigtIcon()}} alt="icon"
                                 src={IconUtil.getIcon(this.props.icon)}/>
                        </Icon>
                    </div>
                ) : (
                    <div className={this.getClassNameIcon()}>
                        <Icon style={{
                            margin: 'auto',
                            height: this.getHeight(),
                            width: width,
                            marginTop: this.props.icon === 'warning' ? '-1px' : '',
                        }}>
                            <img style={{
                                marginLeft: this.getMarginLeft(),
                                marginTop: isIE ? '15px' : '',
                                height: isIE ? '84%' : '100%',
                                width: isIE ? '86%' : '100%',
                                borderRadius: '0',
                            }} alt="icon" src={IconUtil.getIcon(this.props.icon)}/>
                        </Icon>
                    </div>
                )}
            </div>
        )
    }
}

export default GIEIcon;
