// import React...
import React from 'react';

const customStyles = {
    title: {
        color: '#9297b0',
        marginLeft: '20px'
    }
}

export default class HeaderGearForm extends React.Component<any, any> {

    render() {
        return (
            <div style={{
                width: this.props.width,
                height: '100%',
                backgroundColor: 'white',
                marginBottom: '-3.5vh',
                padding: '25px',
                display: 'flex'
            }}>
                <div style={customStyles.title}><span
                    style={{fontWeight: this.props.step === 0 ? 'bold' : 'normal'}}>1 - Votre projet</span></div>
                <div style={customStyles.title}><span
                    style={{fontWeight: this.props.step === 1 ? 'bold' : 'normal'}}>2 - Vos coordonnées</span></div>
            </div>
        );
    }
}
