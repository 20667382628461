import React from 'react';
import Modal from 'react-modal';
import MediaQuery from 'react-responsive';
import Button from '@material-ui/core/Button';
import CardHeader from "../../components/Card/CardHeader";
import Card from "../../components/Card/Card.jsx";
import {DateUtil} from '../../util/date-util';
import {Responsive} from '../../util/responsive';
import {FormValidator} from '../../util/form-validator';
import {PopUpUtil} from '../../util/pop-up-util';

const customStyles = PopUpUtil.getStyle();
const customStylesMobile = PopUpUtil.getStyleMobile();
const customStylesTablet = PopUpUtil.getStyleTablet();

export default class PopUpError extends React.Component<any, any> {
    dateUtils: DateUtil = new DateUtil();
    formValidator: FormValidator = new FormValidator();

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            date: '',
            momentInday: 'matin',
            formControls: {
                email: {
                    value: '',
                    error: false,
                    errorMessage: ''
                },
                mobile: {
                    value: '',
                    error: false,
                    errorMessage: ''
                }
            }
        }
        this.closeModal = this.closeModal.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps !== this.props) {
            this.setState({open: this.props.open});
        }
    }

    closeModal() {
        this.props.closeModal();
    }

    render() {
        return (
            <div>
                <MediaQuery minWidth={Responsive.getMinDesktop()}>
                    <Modal
                        appElement={document.getElementById("root")}
                        isOpen={this.state.open}
                        onRequestClose={this.closeModal}
                        style={customStyles}
                        onClose={this.closeModal}>
                        <Card style={{
                            width: '100%',
                            height: 'auto',
                            maxHeight: '500px',
                            marginTop: 0,
                            marginBottom: 0,
                            overflowY: 'auto',
                            zIndex: 9999}}>
                            <CardHeader color="danger">
                                <h4>Une erreur s'est produite </h4>
                            </CardHeader>
                            <div style={{marginTop: '20px', padding: '5px', textAlign: 'center'}}>
                                <span style={customStyles.text} dangerouslySetInnerHTML={{__html: this.props.text + "<br/>Merci de nous contacter directement pour modifier votre rendez-vous."}}>
                                </span>
                            </div>
                            <div style={{marginBottom: '20px', textAlign: 'center'}}>
                                <Button onClick={this.closeModal} variant="contained" color="primary"
                                        component="span"><span style={{fontWeight: 'bold'}}>Confirmer</span></Button>
                            </div>
                        </Card>
                    </Modal>
                </MediaQuery>
                <MediaQuery minWidth={Responsive.getMinTablet()} maxWidth={Responsive.getMaxTablet()}>
                    <Modal
                        appElement={document.getElementById("root")}
                        isOpen={this.state.open}
                        onRequestClose={this.closeModal}
                        style={customStylesTablet}
                        onClose={this.closeModal}>
                        <Card style={{width: '100%', height: '100%', maxHeight: '500px', marginTop: 0, marginBottom: 0, overflowY: 'auto', zIndex: 9999}}>
                            <CardHeader color="danger">
                                <div style={{marginTop: '20px'}}>
                                    <h4>Une erreur s'est produite </h4>
                                </div>
                            </CardHeader>
                            <div style={{marginTop: '20px', textAlign: 'center'}}>
                                <span style={{...customStyles.text, fontSize: '15px'}} dangerouslySetInnerHTML={{__html: this.props.text}}></span>
                                <div style={customStyles.text}>Merci de nous contacter directement pour modifier votre rendez-vous</div>
                            </div>
                            <div style={{marginBottom: '20px', textAlign: 'center'}}>
                                <Button onClick={this.closeModal} variant="contained" color="primary"
                                        component="span"><span style={{fontWeight: 'bold'}}>Confirmer</span></Button>
                            </div>
                        </Card>
                    </Modal>
                </MediaQuery>
                <MediaQuery maxWidth={Responsive.getMaxMobile()}>
                    <Modal
                        appElement={document.getElementById("root")}
                        isOpen={this.state.open}
                        onRequestClose={this.closeModal}
                        style={customStylesMobile}
                        onClose={this.closeModal}>
                        <Card style={{width: '100%', height: '100%', maxHeight: '500px', marginTop: 0, marginBottom: 0, overflowY: 'auto', zIndex: 9999}}>
                            <CardHeader color="danger">
                                <div style={{marginTop: '20px', fontSize: '12px'}}>
                                    <h5>Une erreur s'est produite </h5>
                                </div>
                            </CardHeader>
                            <div style={{marginTop: '20px', padding: '5px', textAlign: 'center'}}>
                                <span style={customStyles.textMobile} dangerouslySetInnerHTML={{__html: this.props.text}}></span>
                                <div style={customStyles.text}>Merci de nous contacter directement pour modifier votre rendez-vous</div>
                            </div>
                            <div style={{marginBottom: '20px', textAlign: 'center'}}>
                                <Button onClick={this.closeModal} variant="contained" color="primary"
                                        component="span"><span style={{fontWeight: 'bold'}}>Confirmer</span></Button>
                            </div>
                        </Card>
                    </Modal>
                </MediaQuery>
            </div>
        );
    }
}
