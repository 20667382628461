import React from 'react';
import MediaQuery from 'react-responsive';
import {Responsive} from '../../util/responsive';
import './title.css';
import {TitleUtil} from '../../util/title-util';
import userInformations from '../../userInformation/user_informations';

const customStyles = TitleUtil.getStyle();

export default class TitleListScreen extends React.Component<any, any> {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
        }
    }

    componentDidMount() {
        this.setState({name: userInformations.getUserName()})
    }

    render() {
        return (
            <>
                <MediaQuery minWidth={Responsive.getMinDesktop()}>
                    <div style={customStyles.content}>
                        <div style={{marginLeft: '-85px'}}>
                            <span><a style={{fontWeight: 550}} href="accueil">Accueil</a><span
                                style={{color: '#009550', fontWeight: 'bold'}}> | </span><a
                                style={customStyles.boldLink} href={this.props.href}>{this.props.miniTilte}</a></span>
                        </div>
                        <div style={{marginTop: '90px'}}><span style={customStyles.text}>{this.props.miniTilte}</span>
                        </div>
                        <div style={{marginTop: '11px'}}><span style={customStyles.bigText}>{this.props.title} </span>
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery minWidth={Responsive.getMinTablet()} maxWidth={Responsive.getMaxTablet()}>
                    <div style={{...customStyles.content, marginBottom: '60px', padding: '0 75px'}}>
                        <div style={{marginLeft: '-60px'}}>
                            <span><a style={{fontWeight: 550}} href="accueil">Accueil</a><span
                                style={{color: '#009550', fontWeight: 'bold'}}> | </span><a
                                style={customStyles.boldLink} href={this.props.href}>{this.props.miniTilte}</a></span>
                        </div>
                        <div style={{marginTop: '80px'}}><span style={customStyles.text}>{this.props.miniTilte}</span>
                        </div>
                        <div style={{marginTop: '11px'}}><span
                            style={{...customStyles.bigText, fontSize: '34px'}}>{this.props.title}</span></div>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={Responsive.getMaxMobile()}>
                    <div style={{...customStyles.content, marginBottom: '70px', padding: '0 25px'}}>
                        <div>
                            <span><a style={{fontWeight: 550}} href="accueil">Accueil</a><span
                                style={{color: '#009550', fontWeight: 'bold'}}> | </span><a
                                style={customStyles.boldLink} href={this.props.href}>{this.props.miniTilte}</a></span>
                        </div>
                        <div style={{marginTop: '60px'}}><span style={customStyles.text}>{this.props.miniTilte}</span>
                        </div>
                        <div style={{marginTop: '11px'}}><span
                            style={{...customStyles.bigText, fontSize: '26px'}}>{this.props.title} </span></div>
                    </div>
                </MediaQuery>
            </>
        );
    }
}
