// import React...
import React from 'react';
import GIETextField from '../../components/GIETextField/GieTextField';
import {ValidatorForm} from 'react-material-ui-form-validator';
import GridItem from "../../components/Grid/GridItem.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import GIECard from '../../components/GIECard/GieCard';
import GieButton from '../../components/GIEButton/GieButton';
import {FormValidator} from '../../util/form-validator';
import InformationsController from '../../controller/informations.controller';
import GIECheckBox from '../../components/GIECheckBox/GieCheckBox';
import userInformations from '../../userInformation/user_informations';

const customStyles = {
    content: {
        padding: ' 0 40px'
    },
    spanGreen: {
        color: '#009550',
        textTransform: 'none',
        fontFamily: 'Hind Vadodara',
        fontSize: '16px'

    } as React.CSSProperties,
    inputLabel: {
        color: '#253A9F',
        fontDamily: 'Hind Vadodara'
    },

    textValidator: {width: '100%', marginTop: 10},
    textValidatorMultiLine: {width: '100%', marginTop: 10, height: 50},
    greenButton: {
        color: 'white',
        textTransform: 'none',
        fontFamily: 'Hind Vadodara',
        fontSize: '16px'
    } as React.CSSProperties
};

export default class EmergencyFormDetail extends React.Component<any, any> {
    [x: string]: any;

    formValidator: FormValidator = new FormValidator();
    informationsController: InformationsController = new InformationsController();

    constructor(props: any) {
        super(props);
        this.state = {
            isValid: true,
            formControls: {
                lastName: {
                    value: '',
                    error: false,
                    errorMessage: ''
                },
                email: {
                    value: '',
                    error: false,
                    errorMessage: ''
                },
                phoneNumber: {
                    value: '',
                    error: false,
                    errorMessage: ''
                },
                adresse: {
                    value: '',
                    error: false,
                    errorMessage: ''
                },
                city: {
                    value: '',
                    error: false,
                    errorMessage: ''
                },
                customerNumber: {
                    value: '',
                    error: false,
                    errorMessage: ''
                },
                contractNumber: {
                    value: '',
                    error: false,
                    errorMessage: ''
                },
                confidentiality: {
                    value: '',
                    error: false,
                    errorMessage: ''
                }
            }
        }
        this.checkValidForm = this.checkValidForm.bind(this);
    }

    componentDidMount() {
        const noContract = userInformations.getNoContract();
        if (noContract == null || noContract.length === 0) {
            window.location.href = "/admin/login"
        } else {
            this.informationsController.getUserInformations().then((data: any) => {
                this.getData(data, noContract)
            }).catch(err => {
                console.log(err)
            })
        }
    }

    getData = (data: any, noContract: string) => {
        let formControls = this.state.formControls;
        formControls.lastName.value = data.user.name;
        formControls.email.value = data.user.email;
        formControls.phoneNumber.value = data.user.phone_no;
        formControls.adresse.value = data.user.address;
        formControls.customerNumber.value = data.user.no;
        formControls.contractNumber.value = data.contract.contractNo;
        formControls.city.value = data.user.city;

        this.setState({formControls})
    }

    checkValidForm = () => {
        let formControls = this.state.formControls;
        let isError = false;
        const nonRequiredFields = ['phoneMobile', 'phoneHome', 'phoneOffice'];
        Object.keys(formControls).forEach(name => {
            if (!nonRequiredFields.includes(name)) {
                const error = this.formValidator.returnError(formControls[name].value, name)
                formControls[name].error = error.error;
                formControls[name].errorMessage = error.errorMessage;
                if (error.error === true) {
                    isError = true
                }
            }
        });
        this.setState({formControls, isValid: !isError})
        return isError;
    }

    handleSubmit = () => {
        if (this.checkValidForm() === false) {
            this.setState({sending: true});
            this.props.emit(this.state.formControls, this.handleSentOrError);
        }
    }

    handleSentOrError= () => {
        this.setState({sending: false});
    }

    changeHandler = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        const error = this.formValidator.returnError(value, name);
        this.setState({
            formControls: {
                ...this.state.formControls,
                [name]: {
                    ...this.state.formControls[name],
                    value,
                    error: error.error,
                    errorMessage: error.errorMessage
                }
            }
        });
        setTimeout(() => {
            this.checkValidForm() ? this.setState({isValid: true}) : this.setState({isValid: false})
        }, 100);
    }

    checkCGV = (event) => {
        let formControls = this.state.formControls;
        formControls.confidentiality.value = !formControls.confidentiality.value
        this.setState({formControls})
    }

    render() {
        return (
            <GIECard width={this.props.width} height={'100%'}>
                <div>
                    <ValidatorForm
                        ref="form"
                        onSubmit={this.handleSubmit}
                        instantValidate={true}
                        onError={errors => console.log(errors)}>
                        <GridContainer direction="row"
                                       justifyContent="flex-start"
                                       alignItems="flex-start"
                                       style={{...customStyles.content, paddingTop: '40px'}}>
                            <GridItem xs={12}>
                                <GIETextField label="Nom du titulaire" required
                                              disabled
                                              onChange={this.changeHandler}
                                              name="lastName" value={this.state.formControls.lastName.value}
                                              helperText={this.state.formControls.lastName.errorMessage}
                                              error={this.state.formControls.lastName.error}/>
                            </GridItem>
                        </GridContainer>
                        <GridContainer direction="row"
                                       justifyContent="flex-start"
                                       alignItems="center"
                                       style={customStyles.content}>
                            <GridItem xs={12}>
                                <GIETextField label="Adresse du site" required disabled
                                              onChange={this.changeHandler}
                                              name="adresse" value={this.state.formControls.adresse.value}
                                              id="my-input" aria-describedby="my-helper-text"
                                              error={this.state.formControls.adresse.error}
                                              helperText={this.state.formControls.adresse.errorMessage}/>
                            </GridItem>
                            <GridItem xs={12}>
                                <GIETextField label="Ville du site" required disabled
                                              onChange={this.changeHandler}
                                              name="city" value={this.state.formControls.city.value}
                                              error={this.state.formControls.city.error}
                                              helperText={this.state.formControls.city.errorMessage}/>
                            </GridItem>
                        </GridContainer>
                        <GridContainer direction="row"
                                       justifyContent="flex-start"
                                       alignItems="center"
                                       style={customStyles.content}>
                            <GridItem xs={12}>
                                <GIETextField
                                    label="Votre email"
                                    onChange={this.changeHandler}
                                    name="email"
                                    required
                                    value={this.state.formControls.email.value}
                                    InputLabelProps={{style: customStyles.inputLabel}}
                                    style={customStyles.textValidator}
                                    helperText={this.state.formControls.email.errorMessage}
                                    error={this.state.formControls.email.error}/>
                            </GridItem>
                            <GridItem xs={12}>
                                <GIETextField
                                    label="Votre n° de téléphone" required
                                    onChange={this.changeHandler}
                                    name="phoneNumber" value={this.state.formControls.phoneNumber.value}
                                    error={this.state.formControls.phoneNumber.error}
                                    helperText={this.state.formControls.phoneNumber.errorMessage}/>
                            </GridItem>
                        </GridContainer>
                        <GridContainer direction="row"
                                       justifyContent="flex-start"
                                       alignItems="center"
                                       style={customStyles.content}>
                            <GridItem xs={12}>
                                <GIETextField
                                    label="Votre numéro de client" required disabled
                                    onChange={this.changeHandler}
                                    name="customerNumber" value={this.state.formControls.customerNumber.value}
                                    error={this.state.formControls.customerNumber.error}
                                    helperText={this.state.formControls.customerNumber.errorMessage}/>
                            </GridItem>
                            <GridItem xs={12}>
                                <GIETextField label="Votre numéro de contrat" required disabled
                                              onChange={this.changeHandler}
                                              name="contractNumber" value={this.state.formControls.contractNumber.value}
                                              helperText={this.state.formControls.contractNumber.errorMessage}
                                              error={this.state.formControls.contractNumber.error}/>
                            </GridItem>
                        </GridContainer>
                        <GridContainer direction="row"
                                       justifyContent="flex-start"
                                       alignItems="flex-starts"
                                       style={customStyles.content}>
                            <GridItem xs={12}>
                                <div style={{marginTop: '-20px', marginBottom: '20px'}}>
                                    <GIECheckBox isGrey
                                                 hecked={this.state.formControls.confidentiality.value === true}
                                                 name="confidentiality"
                                                 errorText={this.state.formControls.confidentiality.errorMessage}
                                                 error={this.state.formControls.confidentiality.error}
                                                 emit={(event) => this.checkCGV(event)}
                                                 label="En cochant cette case, j’accepte que mes données personnelles soient utilisées par GIE à des fins de traitement de ma demande"/>
                                </div>
                            </GridItem>
                        </GridContainer>
                        <GridContainer direction="row"
                                       justifyContent="space-between"
                                       alignItems="center"
                                       style={{paddingBottom: '40px'}}>
                            <GridItem>
                                <div style={{marginLeft: '40px'}}>
                                    <GieButton style={{border: '1px solid #009550', padding: '12px 20px'}}
                                               onClick={this.props.previous}>
                                        <span style={customStyles.spanGreen}>Précédent</span>
                                    </GieButton>
                                </div>
                            </GridItem>
                            <GridItem>
                                <div style={{marginLeft: this.props.isMobile ? '40px' : '', marginRight: '40px'}}>
                                    {!this.state.sending ?
                                      <GieButton onClick={this.handleSubmit} green={true}
                                                 style={{padding: '12px 30px'}}>
                                        <span style={{
                                            ...customStyles.greenButton,
                                            fontSize: '16px'
                                        }}>Suivant</span>
                                      </GieButton>
                                      :
                                      <GieButton style={{border: '1px solid #009550', padding: '12px 20px'}}
                                                 disabled>
                                          <span style={customStyles.spanGreen}>Envoi en cours...</span>
                                      </GieButton>
                                    }
                                </div>
                            </GridItem>
                        </GridContainer>
                    </ValidatorForm>
                </div>
            </GIECard>
        );
    }
}
