import React from "react";
import MediaQuery from 'react-responsive';
import Button from "@material-ui/core/Button";
import GridItem from "../Grid/GridItem.jsx";
import GridContainer from "../Grid/GridContainer.jsx";
import GieButton from '../../components/GIEButton/GieButton';
import {Responsive} from '../../util/responsive';
import logoWhite4 from '../../assets/img/logo-white4.png';

const ua = window.navigator.userAgent;
const isIE = /MSIE|Trident/.test(ua);
const customStyles = {
    main: {
        // paddingTop:'6vw',
        // paddingLeft:'4vw',
        // paddingRight:'4vw',
        paddingBottom: '40px',
    },
    content: {
        paddingTop: '1%',
    },
    text: {
        color: 'white',
        fontSize: '12px',
        fontFamily: 'Hind Vadodara',
    },
    bigText: {
        color: 'white',
        alignItems: 'center',
        textAlign: 'center',
        fontFamily: 'Hind Vadodara',
    } as React.CSSProperties,
    buttonText: {
        color: 'white',
        textTransform: 'none',
        fontSize: 'bold',
        fontFamily: 'Hind Vadodara',
    } as React.CSSProperties,
    buttonMobile: {
        borderRadius: '2px',
        height: '11vh',
        width: '40vw',
        marginTop: '5vh',
    },
};

class FooterTop extends React.Component {
    contact = () => {
        window.location.href = "https://gie-sa.fr/contact/"
    }

    site = () => {
        window.location.href = "https://gie-sa.fr/"
    }

    render() {
        return (
            <div>
                <MediaQuery minWidth={Responsive.getMinDesktop()}>
                    <div style={{...customStyles.main, marginTop: isIE ? '10%' : ''}}>
                        <GridContainer
                            direction={"row"}
                            justifyContent={"flex-start"}
                            spacing={3}>
                            <GridItem xs={4}>
                                <div style={{width: '182px', height: '51px'}}>
                                    <img alt={"logo"} src={logoWhite4}/>
                                </div>
                                <div style={{marginTop: '20px'}}>
                                    <span style={{...customStyles.bigText, fontSize: '16px'}}>L'énergie est notre avenir, économisons-la !</span>
                                </div>
                            </GridItem>

                            <GridItem xs={4} style={customStyles.content}></GridItem>

                            <GridItem xs={4} style={{margin: 'auto'}}>
                                <div style={{display: 'flex', justifyContent: "flex-end", alignContent: 'center'}}>
                                    <Button
                                        style={{borderRadius: '2px', padding: '12px 20px', backgroundColor: '#253A9F'}}>
                                        <span style={{...customStyles.buttonText, fontSize: '16px'}}
                                              onClick={this.contact}>Contactez-nous</span></Button>
                                    <GieButton onClick={this.site} style={{
                                        borderRadius: '2px',
                                        marginLeft: '20px',
                                        padding: '12px 20px',
                                    }} green>
                                        <span style={{...customStyles.buttonText, fontSize: '16px'}}>
                                            Retour au site
                                        </span>
                                    </GieButton>
                                </div>
                            </GridItem>
                        </GridContainer>
                    </div>
                </MediaQuery>
                {/*tablet*/}
                <MediaQuery minWidth={Responsive.getMinTablet()} maxWidth={Responsive.getMaxTablet()}>
                    <div style={customStyles.main}>
                        <GridContainer
                            direction={"row"}
                            justifyContent={"flex-start"}
                            spacing={3}>
                            <GridItem xs={6}>
                                <div style={{width: '182px', height: '51px'}}>
                                    <img alt={'logo'} src={logoWhite4}/>
                                </div>
                                <div style={{marginTop: '20px'}}>
                                    <span style={{...customStyles.bigText, fontSize: '16px'}}>L'énergie est notre avenir, économisons-la !</span>
                                </div>
                            </GridItem>

                            <GridItem xs={6} style={{margin: 'auto 0 auto auto'}}>
                                <div style={{display: 'flex', justifyContent: "flex-end", alignContent: 'center'}}>
                                    <Button
                                        style={{borderRadius: '2px', padding: '12px 20px', backgroundColor: '#253A9F'}}>
                                        <span style={{...customStyles.buttonText, fontSize: '16px'}}
                                              onClick={this.contact}>Contactez-nous</span></Button>
                                    <GieButton onClick={this.site} style={{
                                        borderRadius: '2px',
                                        marginLeft: '20px',
                                        padding: '12px 20px',
                                    }} green>
                                        <span style={{...customStyles.buttonText, fontSize: '16px'}}>
                                            Retour au site
                                        </span>
                                    </GieButton>
                                </div>
                            </GridItem>
                        </GridContainer>
                    </div>
                </MediaQuery>
                {/*mobile*/}
                <MediaQuery maxWidth={Responsive.getMaxMobile()}>
                    <div style={customStyles.main}>
                        <GridContainer
                            direction={"row"}
                            justifyContent={"flex-start"}
                            spacing={3}>
                            <GridItem xs={9}>
                                <div style={{width: '182px', height: '51px'}}>
                                    <img alt={'logo'} src={logoWhite4}/>
                                </div>
                            </GridItem>

                            <GridItem xs={9} style={{marginTop: '40px'}}>
                                <div style={{display: 'flex', flexDirection: 'column', justifyContent: "flex-start"}}>
                                    <Button
                                        style={{borderRadius: '2px', padding: '12px 20px', backgroundColor: '#253A9F'}}>
                                        <span style={{...customStyles.buttonText, fontSize: '16px'}}
                                              onClick={this.contact}>Contactez-nous</span></Button>
                                    <GieButton onClick={this.site} style={{
                                        borderRadius: '2px',
                                        marginTop: '20px',
                                        padding: '12px 20px',
                                    }} green>
                                        <span style={{...customStyles.buttonText, fontSize: '16px'}}>
                                            Retour au site
                                        </span>
                                    </GieButton>
                                </div>
                            </GridItem>
                        </GridContainer>
                    </div>
                </MediaQuery>
            </div>
        );
    }
}

export default FooterTop;
