import {I18n} from './i18n';

export class WebEventUtil {

    static getBodyOfEmail = (form: Object): string => {
        let bodyOfMail = '';
        const i18nFile: any = I18n.getFile();
        for (let keyForm in form) {
            let keyMail = i18nFile[keyForm] != null ? i18nFile[keyForm] : keyForm;
            let value = i18nFile[form[keyForm]] != null ? i18nFile[form[keyForm]] : form[keyForm];
            bodyOfMail = bodyOfMail + keyMail + ": " + value + "\n"
        }
        return bodyOfMail
    }
}
