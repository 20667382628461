// import React...
import React from 'react';
import MediaQuery from 'react-responsive';
import {Responsive} from '../../util/responsive';
import GieCard from '../../components/GIECard/GieCard';
import GIEIcon from '../../components/GIEIcon/GIEIcon';
import GridItem from "../../components/Grid/GridItem.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import {DateUtil} from '../../util/date-util';
import GIEIconTablet from '../../components/GIEIcon/GIEIconTablet';
import {MyInformationsUtil} from '../../util/my-informations-util';

const customStyles = MyInformationsUtil.getStyle();
export default class CardContract extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            contractNumber: 'n°123456',
            startDate: '01/09/2019',
            endDate: '01/09/2020',
            price: 165,
        }
    }

    redirect = (url) => {
        window.location.href = url
    }

    render() {
        return (
            <>
                <MediaQuery minWidth={Responsive.getMinDesktop()}>
                    <GieCard width="23vw" height="23vw">
                        <div style={{
                            display: 'block',
                            width: "100%",
                            height: "100%",
                            marginLeft: '15px',
                            padding: '10% 0 5%',
                        }}>
                            <GridContainer direction="column"
                                           alignItems="center"
                                           justifyContent="flex-start"
                                           spacing={5}
                                           style={{width: "100%", height: "100%"}}>
                                <GridItem style={{height: '30%', marginBottom: '5%'}}>
                                    <GIEIcon isMobile={false} circle={true} clasName="icon" icon={'contract'}
                                             height={'15vw'}/>
                                </GridItem>
                                <GridItem style={{justifyContent: 'center', height: '60%'}}>
                                    <div style={{display: 'flex', flexDirection: 'column'}}>
                                        <div style={{margin: 'auto', marginLeft: '0%', width: '100%'}}>
                                        <span style={{...customStyles.firstText}}>
                                            Votre contrat
                                        </span>
                                        </div>
                                        <div style={{margin: 'auto', marginLeft: '0%'}}>
                                        <span style={customStyles.secondText}>
                                            {this.props.contract == null ? '' : this.props.contract.contractNo}
                                        </span>
                                        </div>
                                        <div style={{margin: 'auto', marginLeft: '15%'}}>
                                        <span style={customStyles.text}>
                                               <span> du {this.props.contract == null || this.props.contract.startingDate == null ? '' : DateUtil.convertDateInString(new Date(this.props.contract.startingDate))}</span>
                                        </span>
                                        </div>

                                    </div>
                                </GridItem>
                            </GridContainer>
                        </div>
                    </GieCard>
                </MediaQuery>
                <MediaQuery minWidth={Responsive.getMinTablet()} maxWidth={Responsive.getMaxTablet()}>
                    <GieCard width="75vw" height="23vw">
                        <div style={{display: 'flex', width: "100%", height: "100%", padding: '5% 10%'}}>
                            <GridContainer direction="column"
                                           alignItems="flex-start"
                                           justifyContent="center"
                                           spacing={2}
                                           style={{width: "100%", height: "100%"}}
                            >
                                <GridItem>
                                    <GIEIconTablet isMobile={true} circle={true} clasName="icon" icon={'contract'}
                                                   height={'15vw'} width={'7vw'}/>
                                </GridItem>
                                <GridItem style={{justifyContent: 'flex-start', marginLeft: '-45px'}}>
                                    <div style={{textAlign: 'center'}}>
                                        <div>
                                        <span style={{...customStyles.firstText, fontSize: '14px'}}>
                                            Votre contrat numéro
                                        </span>
                                        </div>
                                        <div>
                                        <span style={{...customStyles.secondText, fontSize: '24px'}}>
                                            {this.props.contract == null ? '' : this.props.contract.contractNo}
                                        </span>
                                        </div>
                                        <div>
                                        <span style={{...customStyles.text, fontSize: '16px'}}>
                                                <span> du {this.props.contract == null || this.props.contract.startingDate == null ? '' : DateUtil.convertDateInString(new Date(this.props.contract.startingDate))}</span>
                                         </span>
                                        </div>

                                    </div>
                                </GridItem>
                            </GridContainer>
                        </div>
                    </GieCard>
                </MediaQuery>
                <MediaQuery maxWidth={Responsive.getMaxMobile()}>
                    <GieCard width="75vw" height="100%">
                        <div style={{
                            display: 'block',
                            width: "100%",
                            minHeight: "60vw",
                            marginLeft: '15px',
                            padding: '10% 0',
                        }}>
                            <GridContainer direction="column"
                                           alignItems="center"
                                           justifyContent="flex-start"
                                           spacing={2}
                                           style={{width: "100%", height: "100%"}}>
                                <GridItem style={{height: '30%', marginBottom: '5%'}}>
                                    <GIEIcon isMobile={true} circle={true} clasName="icon" icon={'contract'}
                                             marginLeft="18px" height={'15vw'} width={'5vw'}/>
                                </GridItem>
                                <GridItem style={{justifyContent: 'center', height: '50%'}}>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyItems: 'center',
                                        alignItems: 'center',
                                    }}>
                                        <div style={{textAlign: 'center'}}>
                                        <span style={{...customStyles.firstText, fontSize: '14px'}}>
                                            Votre contrat numéro
                                        </span>
                                        </div>
                                        <div style={{textAlign: 'center'}}>
                                        <span style={{...customStyles.secondText, fontSize: '24px'}}>
                                            {this.props.contract == null ? '' : this.props.contract.contractNo}
                                        </span>
                                        </div>
                                        <div style={{textAlign: 'center'}}>
                                        <span style={{...customStyles.text, fontSize: '16px'}}>
                                                <span> du {this.props.contract == null || this.props.contract.startingDate == null ? '' : DateUtil.convertDateInString(new Date(this.props.contract.startingDate))}</span>
                                         </span>
                                        </div>

                                    </div>
                                </GridItem>
                            </GridContainer>
                        </div>
                    </GieCard>
                </MediaQuery>
            </>
        );
    }
}
