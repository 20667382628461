
import React, { Component } from "react";
const isDev=false;

class FixedPlugin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      classes: "dropdown",
      classesHelp:'dropdown'
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleClickHelp = this.handleClickHelp.bind(this);
    this.handleClickEye=this.handleClickEye.bind(this);
  }
   
  handleClickEye() {
      this.props.showSideBar();
   }
  handleClick() {
    if (this.state.classes === "dropdown") {
      this.setState({ classes: "dropdown show" });
    } else {
      this.setState({ classes: "dropdown" });
    }
  }
  handleClickHelp(){
      if (this.state.classesHelp === "dropdown") {
          this.setState({ classesHelp: "dropdown show" });
        } else {
          this.setState({ classesHelp: "dropdown" });
        }
  }
  render() {
    return (
    <div>
  {isDev===true?(
          <div style={{marginTop:'160px'}} className="fixed-plugin">
                    <div>
                        <div onClick={this.handleClickEye}>
                            <i style={{ color: 'white', padding: 10 }} className="fas fa-eye fa-2x"></i>
                        </div>
          </div>   
       </div>):(null)}  
  </div>
    );
  }
}

export default FixedPlugin;
