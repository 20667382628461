// import React...
import React from 'react';
import FormHelperText from '@material-ui/core/FormHelperText';
import Icon from '@material-ui/core/Icon';
import error from '../../assets/img/icons/error.svg';
import EquipmentCard from './EquipmentCard';
import MediaQuery from 'react-responsive';
import {Responsive} from '../../util/responsive';

const customStyles = {
    spanBlue: {
        color: '#253A9F',
        fontWeight: 600,
        fontSize: '16px',
        fontFamily: 'Hind Vadodara',
        lineHeight: '135%',
    },
};

class EquipmentChoice extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            value: '',
        }
    }

    check = (value) => {
        this.props.emit(value)
        this.setState({value})
    }

    render() {
        return (
            <>
                <MediaQuery minWidth={Responsive.getMinTablet()}>
                    <div style={{width: '100%', backgroundColor: this.props.gearError === true ? '#fff0f0' : 'white'}}>
                        <span style={customStyles.spanBlue}>{this.props.title}</span>
                        <div style={{display: 'flex', flexWrap: 'wrap', marginTop: '10px'}}>
                            <EquipmentCard emit={() => this.check('gas')} title="Une chaudière gaz "
                                           checked={this.state.value === 'gas'}/>
                            <EquipmentCard emit={() => this.check('fioul')} title="Une chaudière fioul "
                                           checked={this.state.value === 'fioul'}/>
                            <EquipmentCard emit={() => this.check('air_conditioning')} title="Une climatisation "
                                           checked={this.state.value === 'air_conditioning'}/>
                            <EquipmentCard emit={() => this.check('heat_pump')} title="Une pompe à chaleur "
                                           width="15vw" checked={this.state.value === 'heat_pump'}/>
                            <EquipmentCard emit={() => this.check('solar')} title="Une production d'eau chaude solaire"
                                           checked={this.state.value === 'solar'}/>
                        </div>
                        {this.props.gearError === true ?
                            (<div style={{marginTop: '1vh', display: 'flex'}}>
                                <Icon style={{height: '100%', marginTop: '-0.7vh', marginRight: '-20px'}}>
                                    <img style={{height: '100%'}} alt="icon" src={error}/>
                                </Icon>
                                <FormHelperText error>Vous devez choisir un équipement</FormHelperText>
                            </div>) : null}
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={Responsive.getMaxMobile()}>
                    <div style={{width: '100%', backgroundColor: this.props.gearError === true ? '#fff0f0' : 'white'}}>
                        <span style={customStyles.spanBlue}>{this.props.title}</span>
                        <div style={{display: 'flex', flexDirection: 'column', flexWrap: 'wrap', marginTop: '10px'}}>
                            <EquipmentCard emit={() => this.check('gas')} title="Une chaudière gaz "
                                           checked={this.state.value === 'gas'}/>
                            <EquipmentCard emit={() => this.check('fioul')} title="Une chaudière fioul "
                                           checked={this.state.value === 'fioul'}/>
                            <EquipmentCard emit={() => this.check('air_conditioning')} title="Une climatisation "
                                           checked={this.state.value === 'air_conditioning'}/>
                            <EquipmentCard emit={() => this.check('heat_pump')} title="Une pompe à chaleur "
                                           width="15vw" checked={this.state.value === 'heat_pump'}/>
                            <EquipmentCard emit={() => this.check('solar')} title="Une production d'eau chaude solaire"
                                           checked={this.state.value === 'solar'}/>
                        </div>
                        {this.props.gearError === true ?
                            (<div style={{marginTop: '1vh', display: 'flex'}}>
                                <Icon style={{height: '100%', marginTop: '-0.7vh', marginRight: '-20px'}}>
                                    <img style={{height: '100%'}} alt="icon" src={error}/>
                                </Icon>
                                <FormHelperText error>Vous devez choisir un équipement</FormHelperText>
                            </div>) : null}
                    </div>
                </MediaQuery>
            </>
        );
    }
}

export default (EquipmentChoice);
