/* istanbul ignore file */
import axios from 'axios';
import ConfigUrl from '../config/config-url';

export default class GenericController {
    configUrl: ConfigUrl;
    instanceBack: any

    constructor() {
        this.configUrl = new ConfigUrl();
        this.instanceBack = axios.create({
            baseURL: this.configUrl.getBaseBackUrl(),
            timeout: 60000,
            headers: this.configUrl.getBackHeader(),
        });
    }

    /**
     * Generic request for back GET
     */
    requestGetBack = (url: string): any => {
        return new Promise((resolve, reject) => {
            this.instanceBack.get(url).then((response: any): any => {
                resolve(response.data)
            }).catch((err: any): any => {
                this.manageError(url, err)
                reject(err);
            })
        })
    }

    /**
     * generic request POST
     */
    requestPostBack = (url: string, payload: any): any => {
        return new Promise((resolve, reject) => {
            this.instanceBack.post(url, payload).then((response: any): any => {
                resolve(response.data)
            }).catch((err: any): any => {
                this.manageError(url, err)
                reject(err);
            })
        })
    }

    manageError = (url, err) => {
        if (err && err.response && err.response.status === 401 && !url.includes('account')) {
            console.error(err)
            window.location.href = "/admin/login";
        }
    }
}
