import React from 'react';
import GridItem from "../../components/Grid/GridItem.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import {FormValidator} from '../../util/form-validator';
import DataDefault from '../../data/dataDefault';
import GIECard from '../../components/GIECard/GieCard';
import GIEButton from '../../components/GIEButton/GieButton';
import EquipmentChoice from '../../components/EquipmentChoice/EquipmentChoice';
import EquipmentCard from '../../components/EquipmentChoice/EquipmentCard';
import YourAskingForm from './YourAskingForm';
import { ChangeMyOfferUtil } from '../../util/change-my-offer-util';

const ua = window.navigator.userAgent;
const isIE = /MSIE|Trident/.test( ua );
const customStyles = ChangeMyOfferUtil.getStyle();

export default class YourAsking extends React.Component<any, any> {
    dataDefault: DataDefault = new DataDefault();
    formValidator: FormValidator = new FormValidator();
    askingForm: any;

    constructor( props: any ) {
        super( props );
        this.state = {
            gearChanged: false,
            formControls: {
                brand: {
                    value: '',
                    error: false,
                    errorMessage: ''

                },
                model: {
                    value: '',
                    error: false,
                    errorMessage: ''

                },
                year: {
                    value: '',
                    error: false,
                    errorMessage: ''

                },
                installYear: {
                    value: '',
                    error: false,
                    errorMessage: ''

                },
                gear: {
                    value: '',
                    error: false,
                    errorMessage: ''

                }
            }
        }
        this.checkValidForm = this.checkValidForm.bind( this )
        this.askingForm = React.createRef();
    }

    gearChanged = ( isChanged: boolean ) => {
        this.setState( { gearChanged: isChanged } )
    }

    changeHandler = ( formControls ) => {
        formControls.gear = this.state.formControls.gear;
        this.setState( { formControls } )
    }

    checkValidForm = () => {
        let formControls = this.state.formControls;
        let isError = false;
        Object.keys( formControls ).forEach( name => {
            const error = this.formValidator.returnError( formControls[name].value, name )
            formControls[name].error = error.error;
            formControls[name].errorMessage = error.errorMessage;
            if ( error.error === true ) {
                isError = true
            }
        } );
        this.askingForm.current.checkValidForm();
        this.setState( formControls )
        return isError;
    }

    next = () => {
        if ( this.state.gearChanged === false ) {
            this.props.next( this.state )
        } else if ( this.checkValidForm() === false ) {
            this.props.next( this.state );
        }
    }

    changeGear = ( value ) => {

        this.setState( {
            formControls: {
                ...this.state.formControls,
                gear: {
                    ...this.state.formControls.gear,
                    value,
                    error: false,
                    errorMessage: ''
                }
            }
        } );
    }

    getHeight = () => {
        let height = '';
        if ( isIE && this.state.gearChanged === false ) {
            height = '250px';
        } else if ( isIE && this.state.gearChanged === true ) {
            height = '1200px'
        } else {
            height = '100%';
        }
        return height;
    }

    render() {
        return (
            <div>
                <GIECard width={this.props.width} height={this.getHeight()}>

                    <div style={{ width: '100%', padding: '40px 40px 10px' }}>
                        <span style={customStyles.firstTitle}>Votre équipement à entretenir a-t-il changé ?</span>
                        <div style={{ display: 'flex' }}>
                            <EquipmentCard emit={() => this.gearChanged( true )} title='Oui' checked={this.state.gearChanged === true}></EquipmentCard>
                            <EquipmentCard emit={() => this.gearChanged( false )} title='Non' checked={this.state.gearChanged === false}></EquipmentCard>
                        </div>
                    </div>

                    {this.state.gearChanged === true ?
                        (
                            <div style={{ width: '100%', padding: '10px 40px' }}>
                                <GridContainer direction="row"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                >
                                    <GridItem xs={12}>
                                        <div style={{ marginBottom: '20px' }}>
                                            <span style={{ ...customStyles.firstTitle }}>Quel équipement souhaitez-vous entretenir ?</span>
                                            <EquipmentChoice gearError={this.state.formControls.gear.error} emit={this.changeGear} />
                                        </div>
                                    </GridItem>
                                </GridContainer>
                                <YourAskingForm ref={this.askingForm} emit={( formControls ) => this.changeHandler( formControls )} />
                            </div> ) : null}
                    <GridContainer direction="column"
                        justifyContent="flex-end"
                        alignItems="flex-end"
                        style={{ padding: '0 40px 40px' }}>
                        <GridItem xs={6}>
                            <GIEButton onClick={this.next} green={true} style={{ borderRadius: '2px', marginTop: '20px', padding: '12px 30px' }}><span style={customStyles.greenButton}>Suivant</span></GIEButton>
                        </GridItem>
                    </GridContainer>
                </GIECard>
            </div>
        );
    }
}
