export class TitleUtil {

    static getStyle = () => {
        return {
            content: {
                //marginTop:'-52vh',
                marginBottom: '100px',
                marginLeft: '-0vw',
                padding: '0 120px',
            },
            text: {
                fontFamily: 'Hind Vadodara',
                color: '#253A9F',
                borderRadius: '2px',
                fontWeight: 'bold',
                fontSize: '14px',
                lineHeight: '21px',
                display: 'flex',
                alignItems: 'center',
                letterSpacing: '0.04em',
                textTransform: 'uppercase',
            } as React.CSSProperties,
            bigText: {
                fontFamily: 'Hind Vadodara',
                color: '#202956',
                borderRadius: '2px',
                fontWeight: 'bold',
                fontSize: '2.7vw',
                lineHeight: '120%',
            } as React.CSSProperties,
            boldLink: {
                fontWeight: 'bold',
                color: '#626886',
            } as React.CSSProperties,
        };
    }
}
