import React from 'react';
import MediaQuery from 'react-responsive';
import {Responsive} from '../../util/responsive';
import GieCard from '../../components/GIECard/GieCard';
import GIEIcon from '../../components/GIEIcon/GIEIcon';
import './Home.css';
import GIEIconTablet from '../../components/GIEIcon/GIEIconTablet';

const customStyles = {
    content: {
        marginLeft: '5%',
        width: '100%',
        height: '100%',
        cursor: 'pointer'
    },
    firstTitle: {
        color: '#253A9F',
        borderRadius: '2px',
        fontWeight: 'bold',
        //marginLeft:'1vw',
        //width:'110%',
        textAlign: 'center',
        // marginTop:'6vh',
        display: 'flex',
        justifyContent: 'center',
        fontFamily: 'Hind Vadodara'
    } as React.CSSProperties,
    smallTitle: {
        color: '#253A9F',
        borderRadius: '2px',
        fontSize: '0.55vw',
        fontWeight: 'bold',
        //width:'110%',
        textAlign: 'center',
        //marginTop:'4vh',
        fontFamily: 'Hind Vadodara',
        display: 'flex'
        //justifyContent: 'center',
    } as React.CSSProperties
};

export default class MiniCardHome extends React.Component<any, any> {

    redirect = () => {
        window.location.href = this.props.url;
    }

    render() {
        return (
            <div>
                <MediaQuery minWidth={Responsive.getMinDesktop()}>
                    <GieCard height={'100%'} width={'23vw'}>
                        <div onClick={this.redirect} style={{...customStyles.content}}>
                            <div style={{display: 'flex', height: '100%', alignItems: 'center', padding: '5% 5px'}}>
                                <div style={{marginRight: '15px'}}>
                                    <GIEIcon isMobile={false} circle clasName="icon" icon={this.props.icon}
                                             height={'15vw'}/>
                                </div>
                                {this.props.tools === true ? (
                                        <div><span style={{
                                            ...customStyles.smallTitle, textAlign: 'right', fontSize: '1.2vw'
                                        }}>{this.props.firstLine}</span>
                                            <div><span style={{
                                                ...customStyles.smallTitle, textAlign: 'right', fontSize: '1.2vw'
                                            }}>{this.props.secondLine}</span></div>
                                        </div>)
                                    : (<span
                                        style={{...customStyles.firstTitle, textAlign: 'right', fontSize: '1.2vw'}}>
                                                        {this.props.title}</span>)}
                            </div>
                        </div>
                    </GieCard>
                </MediaQuery>
                <MediaQuery minWidth={Responsive.getMinTablet()} maxWidth={Responsive.getMaxTablet()}>
                    <GieCard height={'200px'} width={'100%'}>
                        <div onClick={this.redirect}
                             style={{...customStyles.content, marginLeft: '0px', padding: '0 3vw'}}>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                                alignItems: 'center'
                            }}>
                                <div style={{marginTop: '20px', marginBottom: '20px'}}>
                                    <GIEIconTablet isMobile={true} circle={true} clasName="icon" icon={this.props.icon}
                                                   height={'15vw'} width={'7vw'}/>
                                </div>
                                {this.props.tools === true ? (
                                        <div><span style={{
                                            ...customStyles.smallTitle,
                                            justifyContent: 'center',
                                            fontSize: '18px'
                                        }}>{this.props.firstLine}</span>
                                            <div><span style={{
                                                ...customStyles.smallTitle,
                                                justifyContent: 'center',
                                                fontSize: '18px'
                                            }}>{this.props.secondLine}</span></div>
                                        </div>)
                                    : (
                                        <div>
                                            <span style={{...customStyles.firstTitle, fontSize: '18px'}}>
                                                        {this.props.title}</span></div>)}
                            </div>
                        </div>
                    </GieCard>
                </MediaQuery>
                <MediaQuery maxWidth={Responsive.getMaxMobile()}>
                    <GieCard height={'100%'} width={'75vw'}>
                        <div onClick={this.redirect}
                             style={{...customStyles.content, marginLeft: '0', padding: '0 10px 0 30px'}}>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                height: '100%',
                                alignItems: 'center',
                                padding: '5% 5px'
                            }}>
                                <div style={{marginRight: '20px'}}>
                                    <GIEIcon isMobile={true} circle className="icon" icon={this.props.icon}
                                             height={'12vw'} width={'10vw'}/>
                                </div>
                                {this.props.tools === true ? (
                                        <div><span style={{
                                            ...customStyles.smallTitle,
                                            display: 'contents',
                                            justifyContent: 'right',
                                            fontSize: '18px'
                                        }}>{this.props.firstLine}</span>
                                            <div><span style={{
                                                ...customStyles.smallTitle,
                                                display: 'contents',
                                                justifyContent: 'right',
                                                fontSize: '18px'
                                            }}>{this.props.secondLine}</span></div>
                                        </div>)
                                    : (
                                        <div>
                                            <span style={{
                                                ...customStyles.firstTitle,
                                                display: 'contents',
                                                justifyContent: 'right',
                                                fontSize: '18px'
                                            }}>
                                                        {this.props.title}</span></div>)}
                            </div>
                        </div>
                    </GieCard>
                </MediaQuery>
            </div>
        );
    }
}
