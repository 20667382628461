// import React...
import React from 'react';
import ReactGA from 'react-ga';
import MediaQuery from 'react-responsive';
import {Responsive} from '../../util/responsive';
import imgUrl from '../../assets/img/kitchen.jpg';
import GIEContainer from '../../components/GIEContainer/GIEContainer';
import GridItem from "../../components/Grid/GridItem.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import TitleInformation from './TitleInformations';
import CardContract from './CardContract';
import CardInformations from './CardInformations';
import CardBank from './CardBank';
import MiniCardHome from '../../views/Home/MiniCardHome';
import InformationsController from '../../controller/informations.controller';
import userInformations from '../../userInformation/user_informations';

const ua = window.navigator.userAgent;
const isIE = /MSIE|Trident/.test(ua);

export default class MyInformations extends React.Component<any, any> {
    informationsController: InformationsController = new InformationsController();

    constructor(props: any) {
        super(props);
        this.state = {
            bankInformations: {},
            contract: {},
            user: {}
        }
    }

    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search);
        const noContract = userInformations.getNoContract();
        if (!noContract?.length) {
            window.location.href = "/admin/login"
        } else {
            this.getData()
        }
    }

    getData = () => {
        this.informationsController.getUserInformations().then((data: any) => {
            this.setState({bankInformations: data.bankInformations, contract: data.contract, user: data.user})
        }).catch(err => {
            console.log(err)
        })
    }

    redirect = (url) => {
        window.location.href = url
    }

    render() {
        const {bankInformations} = this.state
        return (
            <div className="content">
                <GIEContainer imgUrl={imgUrl}>
                    <TitleInformation/>
                    <MediaQuery minWidth={Responsive.getMinDesktop()}>
                        <GridContainer direction="row"
                                       justifyContent="center"
                                       alignItems="center">
                            <GridItem>
                                <CardContract contract={this.state.contract}/>
                                <div style={{height: '40px'}}></div>
                                <MiniCardHome icon="multiContract" title="Modifier mon offre"
                                              url="/admin/changer-mon-offre"/>
                            </GridItem>
                            <GridItem>
                                <CardInformations user={this.state.user}/>
                                <div style={{height: '40px'}}></div>
                                <MiniCardHome icon="contractPen" tools={true} secondLine="mes coordonnées"
                                              firstLine="Mettre à jour " url="/admin/coordonnees"/>
                            </GridItem>
                            <GridItem>
                                <CardBank bankInformations={bankInformations}/>
                                <div style={{height: '40px'}}></div>
                                <MiniCardHome icon="handShake" big tools secondLine=" prélèvement automatique"
                                              firstLine="Souscrire au " url="/admin/coordonnees-bancaires"/>
                            </GridItem>
                        </GridContainer>
                    </MediaQuery>
                    <MediaQuery minWidth={Responsive.getMinTablet()} maxWidth={Responsive.getMaxTablet()}>
                        <div style={{marginLeft: '160px'}}>
                            <GridContainer direction="column"
                                           justifyContent="flex-start"
                                           alignItems="flex-start">
                                <GridItem xs={4}>
                                    <CardContract contract={this.state.contract}/>
                                </GridItem>
                                <GridItem style={{marginTop: isIE ? '150px' : ''}} xs={4}>
                                    <CardInformations user={this.state.user}/>
                                </GridItem>
                                <GridItem style={{marginTop: isIE ? '150px' : ''}} xs={4}>
                                    <CardBank bankInformations={this.state.bankInformations}/>
                                </GridItem>
                            </GridContainer>
                            <div style={{height: '10px', marginTop: isIE ? '250px' : '', marginLeft: '-150px'}}></div>
                            <div style={{marginLeft: '-120px'}}>
                                <GridContainer direction="row"
                                               justifyContent="center"
                                               alignItems="center"
                                               spacing={2}>
                                    <GridItem xs={3}>
                                        <MiniCardHome icon="multiContract" title="Modifier mon offre"
                                                      url="/admin/changer-mon-offre"/>
                                    </GridItem>
                                    <GridItem xs={3}>
                                        <MiniCardHome icon="contractPen" tools={true} secondLine="mes coordonnées"
                                                      firstLine="Mettre à jour " url="/admin/coordonnees"/>
                                    </GridItem>
                                    <GridItem xs={3}>
                                        <MiniCardHome icon="handShake" big tools secondLine=" prélèvement automatique"
                                                      firstLine="Souscrire au " url="/admin/coordonnees-bancaires"/>
                                    </GridItem>
                                </GridContainer>
                            </div>
                        </div>
                    </MediaQuery>
                    <MediaQuery maxWidth={Responsive.getMaxMobile()}>
                        <div style={{marginLeft: '10vw'}}>
                            <GridContainer direction="column"
                                           justifyContent="flex-start"
                                           alignItems="flex-start"
                                           spacing={3}>
                                <GridItem xs={3}>
                                    <CardContract contract={this.state.contract}/>
                                    <MiniCardHome icon="multiContract" title="Modifier mon offre"
                                                  url="/admin/changer-mon-offre"/>
                                </GridItem>
                                <GridItem xs={3}>
                                    <CardInformations user={this.state.user}/>
                                    <MiniCardHome icon="contractPen" tools={true} secondLine="mes coordonnées"
                                                  firstLine="Mettre à jour " url="/admin/coordonnees"/>

                                </GridItem>
                                <GridItem xs={3}>
                                    <CardBank bankInformations={this.state.bankInformations}/>
                                    <MiniCardHome icon="handShake" big tools secondLine=" prélèvement automatique"
                                                  firstLine="Souscrire au " url="/admin/coordonnees-bancaires"/>
                                </GridItem>
                            </GridContainer>
                        </div>
                    </MediaQuery>
                </GIEContainer>
            </div>
        );
    }
}
