/* istanbul ignore file */
import GenericController from './generic.controller';

export default class InterventionController extends GenericController {

    getInterventionsList = () => {
        return this.requestGetBack('intervention/intervention-list').then(data => {
            return data
        }).catch(err => {
            console.log(err);
            throw err;
        })
    }
}
