// import React...
import React from 'react';
const customStyles = {
        title:{
            color: '#9297b0',
            marginLeft:'20px'
        },
}
export default class HeaderBankForm extends React.Component<any,any> {
  render() {
    return (    
        <div style={{width:this.props.width, height:'100%',backgroundColor:'white',padding:'25px',marginBottom:'-2.2vh',display:'flex'}}>
                <div style={customStyles.title}><span style={{fontWeight:this.props.step===0?'bold':'normal'}} >1 - Vos coordonnées</span></div>
                <div style={customStyles.title}><span  style={{fontWeight:this.props.step===1?'bold':'normal'}}>2 - Vos coordonnées bancaires</span></div>
                <div style={customStyles.title}><span  style={{fontWeight:this.props.step===2?'bold':'normal'}}>3 - Votre mandat de prélèvement </span></div>
        </div>               
    );
  }
}