import moment from 'moment';

export class DateUtil {

    static convertDateInString = (date: Date): string => {
        return moment(date).format('L');
    }

    static convertDateForCalendar = (date: Date): string => {
        return moment(date).format('DD/MM/YY');
    }

    static convertDateTimeInString = (date: Date): string => {
        if (!date || !moment.isDate(date) || date.getFullYear() === 1970) {
            return 'null';
        }
        const dateMoment: moment.Moment = moment(date);
        return dateMoment.format('L') + dateMoment.format('LTS');
    }

    static convertDateInStringAllInFrench = (date: Date): string => {
        return moment(date).format('LL');
    }

    static convertDateNavisionToDate = (dateStr: string): Date => {
        return moment(dateStr, 'DD/MM/YYYY').toDate();
    }
}
