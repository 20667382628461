/* istanbul ignore file */
import GenericController from './generic.controller';
import {WebEventRequest} from '../../typing';

export default class WebEventController extends GenericController {

    insertWebEvent = (request: WebEventRequest) => {
        return new Promise((resolve, reject) => {
            this.requestPostBack('soap-web-event/insert-web-event', request).then(data => {
                resolve(data)
            }).catch(err => {
                console.log(err);
                reject(err)
            })
        })
    }
}
