import React from 'react';
import ReactGA from 'react-ga';

import GIECard from '../../components/GIECard/GieCard';
import GridItem from "../../components/Grid/GridItem.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import GIETextField from '../../components/GIETextField/GieTextField';
import GIEButton from '../../components/GIEButton/GieButton';
import {FormValidator} from '../../util/form-validator';
import LoginController from '../../controller/login.controller';
const ua = window.navigator.userAgent;
const isIE = /MSIE|Trident/.test(ua);

const customStyles = {
    content: {
        backgroundColor: '#202956',
        height: '100%',
        minHeight: '100vh',
        display : 'flex',
        justifyContent: 'center',
        paddingBottom: '10%'
    },
    contentTablet: {
        backgroundColor: '#202956',
        height: '70vh'
    },
    title: {
        fontFamily: 'Hind Vadodara',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '28px',
        lineHeight: '135%',
        color: '#202956',
        textAlign:'center'
    } as React.CSSProperties,
    greenButton: {
        color: 'white',
        textTransform: 'none',
        fontSize: 'bold',
        fontFamily: 'Hind Vadodara'
    } as React.CSSProperties,
    forgetPass: {
        fontFamily: 'Hind Vadodara',
        fontStyle: 'normal',
        fontSize: '14px',
        lineHeight: '140%',
        color: '#253A9F',
        marginTop: '-60px',
        marginLeft: '20px',
        textAlign: 'center',
        whiteSpace: 'pre-wrap'


    } as React.CSSProperties,
    connexion: {
        fontFamily: 'Hind Vadodara',
        fontStyle: 'normal',
        fontSize: '14px',
        lineHeight: '140%',
        color: '#253A9F',


    } as React.CSSProperties

};


export default class PasswordForget extends React.Component<any, any> {
    formValidator: FormValidator = new FormValidator();
    loginController: LoginController = new LoginController();

    constructor( props ) {
        super( props );
        this.state = {
            formControls: {
                noContract: {
                    value: '',
                    error: false,
                    errorMessage: ''

                }

            },
            emailSend: false
        }

    }
    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }
    changeHandler = ( event ) => {
        const name = event.target.name;
        const value = event.target.value;
        const error = this.formValidator.returnError( value, name );
        this.setState( {
            formControls: {
                ...this.state.formControls,
                [name]: {
                    ...this.state.formControls[name],
                    value,
                    error: error.error,
                    errorMessage: error.errorMessage
                }
            }
        } );
    }
    checkValidForm = () => {
        let formControls = this.state.formControls;
        let isError = false;
        Object.keys( formControls ).forEach( name => {
            const error = this.formValidator.returnError( formControls[name].value, name )
            formControls[name].error = error.error;
            formControls[name].errorMessage = error.errorMessage;
            if ( error.error === true ) {
                isError = true
            }

        } );
        this.setState( formControls )
        return isError;
    }

    sendEmail = () => {
        if ( !this.checkValidForm() ) {
            const request = {
                noContract: this.state.formControls.noContract.value
            }
            this.loginController.sendTokenToRegeneratePassword( request ).then( data => {
                this.setState( { emailSend: true } )
            } ).catch( err => {
                this.manageError(err)
                console.log(err)
            } )


        }
    }

    manageError=(err)=>{
        console.log( err )
        let formControls = this.state.formControls;
        if ( err && err.response && err.response.status === 404 ) {
            formControls.noContract.error = true;
            formControls.noContract.errorMessage = "Il n'y a pas de compte portant ce numéro"
        } else {
            formControls.noContract.error = true;
            formControls.noContract.errorMessage = "Une erreur s'est produite"
        }
        this.setState( { formControls } )
    }
    render() {
        return (
            <div className="content">
                    <div style={customStyles.content}>
                        <div style={{ margin:'auto', marginTop: '10%', position: "absolute",marginLeft:isIE?'-15%':'' }}>
                            <GIECard width="410px" height="100%">
                                <div style={{width:'100%', padding:'10% 10% 5%'}}>
                                    <GridContainer direction="row"
                                        justifyContent="flex-start"
                                        alignItems="flex-start">
                                        <GridItem xs={12}>
                                            <div>
                                                <h2 style={customStyles.title}>Mot de passe oublié</h2>
                                            </div>
                                        </GridItem>

                                        <GridItem xs={12}>
                                                <GIETextField label="Votre n° de contrat" required
                                                    onChange={this.changeHandler}
                                                    name="noContract" value={this.state.formControls.noContract.value}
                                                    helperText={this.state.formControls.noContract.errorMessage}
                                                    error={this.state.formControls.noContract.error} />

                                        </GridItem>
                                        <GridItem xs={12}>
                                            <div style={{ width: '310px', textAlign: 'center', }}>
                                                {this.state.emailSend === false ? ( null ) : ( <span style={customStyles.forgetPass}>Un mail vous a été envoyé pour modifier votre mot de passe.</span> )}
                                            </div>
                                        </GridItem>

                                        <GridItem xs={12}>
                                            <div style={{padding:'30px 0 0', display: 'flex', justifyContent:'space-between', alignItems:'center'}}>

                                                <a style={customStyles.connexion} href='/admin/login'> Retour</a>

                                              {this.state.emailSend === false ? (   <GIEButton onClick={this.sendEmail} green={true} style={{ height: '50px', width: '100px' }}><span style={customStyles.greenButton}>Valider</span></GIEButton>)
                                              :(null)}
                                            </div>
                                        </GridItem >
                                    </GridContainer>
                                </div>
                            </GIECard>
                        </div>
                    </div>
                </div>
        );
    }
}
