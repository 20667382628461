// import React...
import React from 'react';
import MediaQuery from 'react-responsive';
import ReactGA from 'react-ga';
import {Responsive} from '../../util/responsive';
import imgUrl from '../../assets/img/bald_family_reversed.jpg';
import GIEContainer from '../../components/GIEContainer/GIEContainer';
import MessageSuccess from '../../components/form/MessageSuccess';
import YourAsking from './YourAsking';
import Offer from './Offer';
import MyCoordinatesFormDetail from '../../components/form/MyCoordinateFormDetail';
import TitleScreen from '../../components/TitleScreen/TitleScreen';
import HeaderForm from './HeaderOfferForm';
import WebEventController from '../../controller/web-event.controller';
import {WebEventUtil} from '../../util/web-event-util';
import {I18n} from '../../util/i18n';

export default class ChangeMyOffer extends React.Component<any, any> {

    webEventController: WebEventController = new WebEventController();

    constructor(props: any) {
        super(props);
        this.state = {
            step: 0,
            dataStep1: null,
            dataStep2: null
        }
    }

    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }

    saveDataStep1 = (dataStep1) => {
        this.setState({dataStep1})
        this.next();
    }

    saveDataStep2 = (dataStep2) => {
        this.setState({dataStep2})
        this.next();
    }

    handleSubmit = (form) => {
        const dataStep1 = this.state.dataStep1;
        const i18nFile: any = I18n.getFile();
        const dataStep2 = i18nFile[this.state.dataStep2] != null ? i18nFile[this.state.dataStep2] : this.state.dataStep2;
        let bodyOfMail: any = {};
        Object.keys(form).forEach((name, key) => {
            bodyOfMail[name] = form[name].value
        });
        Object.keys(dataStep1.formControls).forEach((name, key) => {
            bodyOfMail[name] = dataStep1.formControls[name].value
        });
        bodyOfMail.gearChanged = dataStep1.gearChanged;
        bodyOfMail.offerType = dataStep2;
        bodyOfMail.gear = i18nFile[bodyOfMail.gear] != null ? i18nFile[bodyOfMail.gear] : bodyOfMail.gear;

        const request = {
            noContrat: bodyOfMail.noContract,
            eventType: "3",
            eventCode: 'CHGOFFRE',
            description: "Installer ou remplacer un équipement",
            confirm: '0',
            mail_object: WebEventUtil.getBodyOfEmail(bodyOfMail),
            noClient: null,
            codeSite: null
        }
        this.webEventController.insertWebEvent(request).then(data => {
            this.setState({dataSend: true})
            this.next();
        }).catch(err => {
            console.log(err)
        })
    }

    next = () => {
        this.setState({step: this.state.step + 1})
    }

    previous = () => {
        this.setState({step: this.state.step - 1})
    }

    getMarginLeftDeskTop = () => {
        return this.state.step < 3 ? '20vw' : '0%';
    }

    getMarginLeftTablet = () => {
        return this.state.step < 3 ? '7vw' : '0%';
    }

    render() {
        return (
            <div className="content">
                <GIEContainer form imgUrl={imgUrl}>
                    <TitleScreen title="Modifier mon offre" hello={true} isForm href="changer-mon-offre"/>
                    <MediaQuery minWidth={Responsive.getMinDesktop()}>
                        <div style={{marginLeft: this.getMarginLeftDeskTop()}}>
                            {this.state.step <= 3 ? (<HeaderForm width="60vw" step={this.state.step}/>) : null}
                            {this.state.step === 0 ? (<YourAsking width="60vw" next={this.saveDataStep1}/>) : null}
                            {this.state.step === 1 ? (
                                <Offer width="60vw" previous={this.previous} next={this.saveDataStep2}/>) : null}
                            {this.state.step === 2 ? (<MyCoordinatesFormDetail confidentiality previous={this.previous}
                                                                               emit={this.handleSubmit} offer={true}
                                                                               width="60vw" height="142vh"
                                                                               withButton={false}/>) : null}
                            {this.state.step < 3 ? null : (<MessageSuccess/>)}
                        </div>
                    </MediaQuery>
                    <MediaQuery minWidth={Responsive.getMinTablet()} maxWidth={Responsive.getMaxTablet()}>
                        <div style={{marginLeft: this.getMarginLeftTablet()}}>
                            {this.state.step < 3 ? (<HeaderForm width="86vw" step={this.state.step}/>) : null}
                            {this.state.step === 0 ? (<YourAsking width="86vw" next={this.saveDataStep1}/>) : null}
                            {this.state.step === 1 ? (
                                <Offer width="86vw" previous={this.previous} next={this.saveDataStep2}/>) : null}
                            {this.state.step === 2 ? (<MyCoordinatesFormDetail confidentiality previous={this.previous}
                                                                               emit={this.handleSubmit} offer={true}
                                                                               width="86vw" height="142vh"
                                                                               withButton={false}/>) : null}
                            {this.state.step < 3 ? null : (<MessageSuccess/>)}
                        </div>
                    </MediaQuery>
                    <MediaQuery maxWidth={Responsive.getMaxMobile()}>
                        <div>
                            {this.state.step < 3 ? (<HeaderForm width="100vw" step={this.state.step}/>) : null}
                            {this.state.step === 0 ? (<YourAsking width="100vw" next={this.saveDataStep1}/>) : null}
                            {this.state.step === 1 ? (
                                <Offer width="100vw" previous={this.previous} next={this.saveDataStep2}/>) : null}
                            {this.state.step === 2 ? (<MyCoordinatesFormDetail confidentiality previous={this.previous}
                                                                               emit={this.handleSubmit} offer={true}
                                                                               width="100vw" height="142vh"
                                                                               withButton={false}/>) : null}
                            {this.state.step < 3 ? null : (<MessageSuccess/>)}
                        </div>
                    </MediaQuery>
                </GIEContainer>
            </div>
        );
    }
}
