export class PopUpUtil {

    static getStyle = () => {
        return {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                borderRadius: '25px',
                width: '40vw',
                overflowY: 'hidden',
                padding: 0,
                zIndex: 9999
            },
            text: {
                color: '#253A9F',
                borderRadius: '2px',
                fontSize: '16px',
                fontWeight: 600,
                fontFamily: 'Hind Vadodara',
                marginTop: '20px',
                textAlign: 'center',
                whiteSpace: 'pre-wrap',
                wordBreak: 'keep-all',
            } as React.CSSProperties,
            textMobile: {
                color: '#253A9F',
                borderRadius: '2px',
                fontSize: '12px',
                fontWeight: 600,
                fontFamily: 'Hind Vadodara',
                textAlign: 'center',
                marginTop: '20px',
                marginLeft: '10%',
                justifyContent: 'center'
            } as React.CSSProperties,
        };
    }

    static getStyleMobile = () => {
        return {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                borderRadius: '25px',
                width: '100vw',
                overflowY: 'hidden',
                padding: 0,
                zIndex: 9999
            }
        };
    }

    static getStyleTablet = () => {
        return {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                borderRadius: '25px',
                width: '80vw',
                overflowY: 'hidden',
                padding: 0,
                zIndex: 9999
            }
        }
    }
}
