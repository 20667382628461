import React from 'react';
import {DateUtil} from '../../util/date-util';

const customStyles = {
    content: {
        width: '50vw',
        backgroundColor: 'white',
        marginBottom: '20px',
        padding: '20px'
    },
    span: {
        fontfamily: 'Hind Vadodara',
        fontStyle: 'normal',
        textAlign: 'justify'

    } as React.CSSProperties,
    bigText: {
        fontFamily: 'Hind Vadodara',
        color: '#202956',
        borderRadius: '2px',
        fontWeight: 'bold',
        fontSize: '26px',
        lineHeight: '120%'

    } as React.CSSProperties
}

export default class CardWithTitleCalendar extends React.Component<any, any> {

    constructor(props) {
        super(props);
        this.state = {
            freeItem: '',
            appointment: ''
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.freeItem !== this.props.freeItem && this.props.freeItem != null) {
            this.changeFreeItems(this.props.freeItem)
        }
    }

    changeFreeItems = data => {
        let freeItem: any = DateUtil.convertDateNavisionToDate(data);
        freeItem = DateUtil.convertDateInStringAllInFrench(freeItem);
        this.setState({freeItem})
    }

    render() {
        return (
            <div>
                <div style={{...customStyles.content, width: this.props.width}}>
                    <div style={{color: '#253A9F'}}>
                        <div style={{marginTop: '10px', marginBottom: '10px'}}><span style={customStyles.bigText}>Votre prochain rendez-vous est prévu le : {this.props.appointmentString}</span>
                        </div>
                        <div style={{marginTop: '10px'}}>
                            {this.props.freeItem == null || this.props.itsTooLate === true ? null : (
                                <span style={{
                                    ...customStyles.span,
                                    fontSize: this.props.isMobile ? '10px' : '14px',
                                    fontWeight: 600
                                }}>
                                    Le premier rendez-vous disponible est le {this.state.freeItem}.
                                </span>)}
                        </div>
                        <div>
                            <span style={{
                                ...customStyles.span,
                                fontSize: this.props.isMobile ? '10px' : '14px',
                                fontWeight: 400
                            }}>
                                Vous disposez de jusqu’à 7 jours avant votre rendez-vous initial pour le reporter.
                            </span>
                        </div>
                        <div style={{marginTop: 10}}>
                            <span style={{
                                ...customStyles.span,
                                fontSize: this.props.isMobile ? '10px' : '14px',
                                fontWeight: 400
                            }}>
                                Sachez que, même de plusieurs semaines, le report de votre rendez-vous d’entretien n’aura aucun impact
                                sur la sécurité et le bon fonctionnement de votre installation.
                                </span>
                        </div>
                        <div style={{marginTop: '10px'}}>
                            {this.props.itsTooLate === true ? (
                                <span style={{
                                    ...customStyles.span,
                                    color: '#D11149',
                                    fontSize: this.props.isMobile ? '10px' : '14px',
                                    fontWeight: 600
                                }}>
                                    Votre rendez-vous d’entretien est prévu dans {this.props.diffDays} jours. Vous ne pouvez plus le replanifier.
                                </span>) : null}
                        </div>
                        <div style={{marginTop: '10px'}}>
                            {this.props.errorText ? (
                                <span style={{
                                    ...customStyles.span,
                                    color: '#D11149',
                                    fontSize: this.props.isMobile ? '10px' : '14px',
                                    fontWeight: 600
                                }} dangerouslySetInnerHTML={{__html: this.props.errorText}}>
                                </span>) : null}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
