/* istanbul ignore file */
import GenericController from './generic.controller';
import {RawUpdateUserInformationRequest} from '../../typing'

export default class InformationsController extends GenericController {

    getUserInformations = () => {
        return new Promise((resolve, reject) => {
            this.requestGetBack('informations/get-informations').then(data => {
                resolve(data)
            }).catch(err => {
                console.log(err);
                reject(err)
            })
        })
    }

    changeUserInformations = (request: RawUpdateUserInformationRequest) => {
        return new Promise((resolve, reject) => {
            this.requestPostBack('soap-coordinate/save-coordinates', request).then(data => {
                resolve(data)
            }).catch(err => {
                reject(err)
            })
        })
    }
}
