import React from "react";
import MaterialIcon from 'material-icons-react';
import Icon from '@material-ui/core/Icon';

import GIEButton from '../GIEButton/GieButton';
import GridItem from "../../components/Grid/GridItem.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import greyWarning from '../../assets/img/icons/greyWarning.svg';
import {SideBarStyleUtils} from './SideBarStyleUtils';
import './Header.css';

const customStyles = SideBarStyleUtils.getStyle();

class SideBarContent extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            openMyAccount: false
        }
    }

    redirect = (endUrl: string) => {
        window.location.href = '/admin/' + endUrl
    }

    goService = () => {
        window.location.href = "/admin/services"
    }

    openAccount = (event) => {
        this.setState({openMyAccount: !this.state.openMyAccount});
    }

    close = () => {
        this.props.close()
    }

    render() {
        return (
            <div style={customStyles.sideBar}>
                {this.state.openMyAccount === false ?
                    (<div>
                        <div onClick={this.close}
                             style={{paddingTop: '25px', paddingLeft: '25px', marginBottom: '44px'}}>
                            <MaterialIcon size={20} color="#202956" icon="clear"/>
                        </div>

                        <div style={{...customStyles.buttonDiv}}>
                            <div style={{...customStyles.emergencyDiv}}>
                                <Icon style={{height: '34px'}}>
                                    <img style={{height: '16px', width: '14px'}} alt="icon" src={greyWarning}/>
                                </Icon>
                                <a href="/admin/formulaire-d-urgence">SIGNALER UNE PANNE</a>
                            </div>

                            <GIEButton onClick={this.goService} style={{
                                ...customStyles.buttonGreen
                            }} green={true}>
                                <span style={{
                                    ...customStyles.buttonText,
                                    marginRight: '3px'
                                }}>{'Services en ligne'}</span>
                                <MaterialIcon size={'20vh'} color="white" icon="keyboard_arrow_right"/>
                            </GIEButton>

                            <GIEButton
                                aria-haspopup="true" onClick={this.openAccount}
                                style={{...customStyles.buttonBlue}}>
                                <span style={{...customStyles.buttonText, marginRight: '3px'}}>{'Mon compte'} </span>
                                <MaterialIcon size={'20vh'} color="white" icon="keyboard_arrow_right"/>
                            </GIEButton>

                        </div>
                    </div>) : (
                        <div>
                            <div onClick={this.close}
                                 style={{paddingTop: '25px', paddingLeft: '25px', marginBottom: '44px'}}>
                                <MaterialIcon size={20} color="#202956" icon="clear"/>
                            </div>
                            <GridContainer direction="column"
                                           alignItems="flex start"
                                           spacing={5}>
                                <GridItem>
                                    <div onClick={this.openAccount} style={customStyles.greySpan}>
                                        <MaterialIcon size={'25vh'} color="#009550" icon="keyboard_arrow_left"/>
                                        <span style={{marginTop: '0.5vh'}}>RETOUR</span>
                                    </div>
                                </GridItem>
                                <GridItem>
                                    <div onClick={() => this.redirect('documents')} style={customStyles.blueSpan}>
                                        <span>MES DOCUMENTS</span>
                                    </div>
                                </GridItem>
                                <GridItem>
                                    <div onClick={() => this.redirect('mes-informations')}
                                         style={customStyles.blueSpan}>
                                        <span>MES INFORMATIONS</span>
                                    </div>
                                </GridItem>
                                <GridItem>
                                    <div onClick={() => this.redirect('login')} style={customStyles.blueSpan}>
                                        <span>DECONNEXION</span>
                                    </div>
                                </GridItem>
                            </GridContainer>
                        </div>
                    )}
            </div>
        )
    }
}

export default SideBarContent;
