/*eslint-disable*/
import React from "react";
import MaterialIcon from 'material-icons-react';
import Button from "@material-ui/core/Button";
import GridItem from "../Grid/GridItem.jsx";
import GridContainer from "../Grid/GridContainer.jsx";
import {Responsive} from '../../util/responsive';
import imageLogo from "../../assets/img/logo-white4.png";
import pg from '../../assets/img/pg.svg';
import effigaz from "../../assets/img/effigaz.svg";
import pyramide from "../../assets/img/pyramide.svg";

const customStyles = {
    main: {
        // paddingTop:'6vw',
        // paddingLeft:'4vw',
        // paddingRight:'4vw',
        paddingBottom: '40px'
    },
    content: {
        paddingTop: '1%'
    },
    text: {
        color: 'white',
        fontFamily: 'Hind Vadodara',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '143%'
    } as React.CSSProperties,
    bigText: {
        color: 'white',
        alignItems: 'center',
        textAlign: 'center',
        fontFamily: 'Hind Vadodara'
    } as React.CSSProperties,
    headline: {
        color: 'white',
        fontFamily: 'Hind Vadodara',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '21px',
        /* identical to box height */
        letterSpacing: '0.04em',
        textTransform: 'uppercase'
    } as React.CSSProperties,
    buttonText: {
        color: 'white',
        textTransform: 'none',
        fontSize: 'bold',
        fontFamily: 'Hind Vadodara'
    } as React.CSSProperties,
    buttonMobile: {
        borderRadius: '2px',
        height: '11vh',
        width: '40vw',
        marginTop: '5vh'
    }
};

class GIEFooterTop extends React.Component {

    contact = () => {
        window.location.href = "https://gie-sa.fr/contact/"
    }

    render() {
        return (
            <div>
                <div style={customStyles.main}>
                    <GridContainer
                        direction={"row"}
                        justifyContent={"flex-start"}
                        spacing={3}>
                        <GridItem xs={3}>
                            <div style={{width: '182px', height: '51px'}}>
                                <img src={imageLogo}/>
                            </div>
                            <div style={{marginTop: '20px'}}>
                                <span style={{...customStyles.bigText, fontSize: '16px'}}>L'énergie est notre avenir, économisons-la !</span>
                            </div>
                            <div style={{marginTop: '20px'}}>
                                <Button style={{borderRadius: '2px', padding: '12px 20px', backgroundColor: '#253A9F'}}>
                                        <span style={{...customStyles.buttonText, fontSize: '16px'}}
                                              onClick={this.contact}>Contactez-nous</span>
                                </Button>
                            </div>
                        </GridItem>

                        <GridItem xs={3}>
                            <div><span style={{...customStyles.headline}}>Nous Trouver</span></div>
                            <div style={{marginTop: '20px'}}>
                                <span style={{...customStyles.text}}>14 Place des Halles</span>
                            </div>
                            <div>
                                <span style={{...customStyles.text}}>67000 Strasbourg</span>
                            </div>
                            <div>
                                <span style={{...customStyles.text}}>Lundi - vendredi</span>
                            </div>
                            <div>
                                <span style={{...customStyles.text}}>7h30 à 12h - 13h30 à 17h</span>
                            </div>
                        </GridItem>

                        <GridItem xs={3}>
                            <div><span style={{...customStyles.headline}}>Accès Rapide</span></div>
                            <div style={{marginTop: '20px'}}>
                                <ul style={{padding: '0', listStyle: 'none'}}>
                                    <li style={{display: 'flex', alignItems: 'center', paddingBottom: '8px'}}>
                                        <MaterialIcon size={'20vh'} color="#009550" icon="keyboard_arrow_right"/>
                                        <a style={{...customStyles.text}} href={"https://gie-sa.fr/notre-entreprise/"}>Notre
                                            entreprise</a>
                                    </li>
                                    <li style={{display: 'flex', alignItems: 'center', paddingBottom: '8px'}}>
                                        <MaterialIcon size={'20vh'} color="#009550" icon="keyboard_arrow_right"/>
                                        <a style={{...customStyles.text}}
                                           href={"https://gie-sa.fr/chauffage/"}>Chauffage</a>
                                    </li>
                                    <li style={{display: 'flex', alignItems: 'center', paddingBottom: '8px'}}>
                                        <MaterialIcon size={'20vh'} color="#009550" icon="keyboard_arrow_right"/>
                                        <a style={{...customStyles.text}}
                                           href={"https://gie-sa.fr/climatisation/"}>Climatisation</a>
                                    </li>
                                    <li style={{display: 'flex', alignItems: 'center', paddingBottom: '8px'}}>
                                        <MaterialIcon size={'20vh'} color="#009550" icon="keyboard_arrow_right"/>
                                        <a style={{...customStyles.text}}
                                           href={"https://gie-sa.fr/energies-renouvelables/"}>Énergies renouvelables</a>
                                    </li>
                                </ul>
                            </div>
                        </GridItem>

                        <GridItem xs={3}>
                            <div><span style={{...customStyles.headline}}>Nos Qualifications</span></div>
                            <div style={{display: 'flex', marginTop: '20px'}}>
                                <div style={{display: 'flex'}}>
                                    <img src={pyramide}/>
                                    <img style={{marginLeft: '24px'}} src={pg}/>
                                    <img style={{marginLeft: '24px'}} src={effigaz}/>
                                </div>
                            </div>
                        </GridItem>
                    </GridContainer>
                </div>
            </div>
        );
    }
}

export default GIEFooterTop;
