import React from 'react';
import MediaQuery from 'react-responsive';
import ReactGA from 'react-ga';
import CalendarMonthView from '../../assets/react-calendar-month-view';
import PopUpPerso from './PopUpCalendar';
import GridItem from "../../components/Grid/GridItem.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import imgUrl from '../../assets/img/kitchen.jpg';
import GIEContainer from '../../components/GIEContainer/GIEContainer';
import TitleCalendar from './TitleCalendar';
import GIECard from '../../components/GIECard/GieCard';
import CalendarController from '../../controller/calendar.controller';
import GIECheckBox from '../../components/GIECheckBox/GieCheckBox';
import GieButton from '../../components/GIEButton/GieButton';
import {ListUtil} from '../../util/list-util';
import {Responsive} from '../../util/responsive';
import {DateUtil} from '../../util/date-util';
import CardWithTitleCalendar from './CardWithTitleCalendar';
import Button from '@material-ui/core/Button';
import PopUpError from './PopUpError';
import userInformations from '../../userInformation/user_informations';

const userAgent = navigator.userAgent.toLowerCase();
const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);
const ua = window.navigator.userAgent;
const isIE = /MSIE|Trident/.test(ua);
const customStyles = {

    titleSearch: {
        fontFamily: 'Hind Vadodara',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '29px',
        display: 'flex',
        alignItems: 'center',
        color: 'rgb(32, 41, 86)',
        marginLeft: '0%',
        marginBottom: '20px',
        marginTop: '10px'
    },
    titleSearchMobile: {
        fontFamily: 'Hind Vadodara',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '10px',
        lineHeight: '29px',
        display: 'flex',
        alignItems: 'center',
        color: 'rgb(32, 41, 86)',
        marginLeft: '10%',
        marginBottom: '20px',
        marginTop: '10px'
    },
    spanGreen: {
        color: '#009550',
        textTransform: 'none',
        fontSize: 'bold',
        fontFamily: 'Hind Vadodara'

    } as React.CSSProperties,
    greenButton: {
        color: 'white',
        textTransform: 'none',
        fontFamily: 'Hind Vadodara'
    } as React.CSSProperties,
    text: {
        fontFamily: 'Hind Vadodara',
        color: '#253A9F',
        borderRadius: '2px',
        fontWeight: 'bold',
        fontSize: '14px',
        lineHeight: '21px',
        display: 'flex',
        alignItems: 'center',
        letterSpacing: '0.04em',
        textTransform: 'uppercase'
    } as React.CSSProperties
};

export default class GIECalendar extends React.Component<any, any> {
    calendarController: CalendarController = new CalendarController();
    disableSelection: boolean = false;

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            date: null,
            listAppointment: [],
            listFreeAppointment: [],
            isCancel: false,
            listOfDay: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
            appointmentString: '',
            itsTooLate: false,
            diffDays: 0,
            manualSearch: false,
            startDateCalendar: null,
            nbOfPlanif: 0,
            responseErrorText: '',
            mail: '',
            error: false,
            errorText: '',
            rawData: {afternoon: '', morning: ''}
        }
    }

    componentDidMount() {
        this.closeModal = this.closeModal.bind(this)
        this.manageStarDateCalendar(DateUtil.convertDateForCalendar(new Date()))

        this.getData(true);
        ReactGA.pageview(window.location.pathname + window.location.search);
        this.setState({emailSend: false})
    }

    getData = (isInitial: boolean) => {
        const noContract = userInformations.getNoContract()
        if (!noContract?.length) {
            window.location.href = "/admin/login"
        } else {
            const request = {
                noContract,
                setupDayDispo: this.state.listOfDay
            }
            this.calendarController.getFreePlanning(request).then((data: any) => {
                this.manageData(data, isInitial);
            }).catch(error => {
                console.log(error)
            })
        }
    }

    manageData = (data, isInitial: boolean) => {
        this.setState({rawData: data, manualSearch: !isInitial})
        const tooLate: boolean = this.setAppointment(data);
        if (!tooLate) {
            this.setFreeItems(data);
        }
        if (data.plannedTime?.length) {
            this.manageStarDateCalendar(data.plannedIntervention)
        }
        this.setState({
            nbOfPlanif: data.nbOfPlanif,
            // responseErrorText: data.responseErrorText,
            mail: data.mail,
            appointmentString: data.plannedInterventionString
        })
    }

    manageStarDateCalendar = (plannedIntervention) => {
        const dateStringSplitted = plannedIntervention.split('/');
        const startDateCalendar = dateStringSplitted[1] + '-' + dateStringSplitted[0] + '-20' + dateStringSplitted[2];
        this.setState({startDateCalendar})
        return startDateCalendar;
    }

    setFreeItems = data => {
        let groupedList = ListUtil.groupBy(data.planningAvailability, 'day');
        let listFreeAppointment = [];
        if (data.nbOfPlanif >= 2) {
            this.setState({listFreeAppointment, responseErrorText: 'Vous avez déplacé à deux reprises votre rendez-vous d’entretien annuel et ne pouvez donc plus accéder au service. <br>Pour reporter à nouveau votre rendez-vous, contactez nos services par email à l’adresse <a href="mailto:appareil.individuel@gie-sa.fr">appareil.individuel@gie-sa.fr</a> ou par téléphone au <a href="tel:+33388325352">03 88 32 53 52</a>.'})
            return;
        }
        Object.keys(groupedList).forEach((key) => {
            let date: Date = new Date();
            let dateStringSplitted = groupedList[key][0].day.split('/');
            date.setFullYear(Number("20" + dateStringSplitted[2]), Number(dateStringSplitted[1]) - 1, Number(dateStringSplitted[0]))
            if (groupedList[key].length === 2) {
                date.setHours(12, 0, 0, 0)
            } else if (groupedList[key][0].ok === 'AM-OK') {
                date.setHours(9, 0, 0, 0)
            } else if (groupedList[key][0].ok === 'PM-OK') {
                date.setHours(14, 0, 0, 0)
            }
            listFreeAppointment.push(date);
        });
        if (!listFreeAppointment?.length) {
            this.setState({responseErrorText: 'Aucun créneau disponible'})
        }
        this.setState({listFreeAppointment})
    }

    setAppointment = data => {
        let appointment: Date = DateUtil.convertDateNavisionToDate(data.plannedIntervention);
        const tooLate: boolean = this.itsTooLate(data);
        let timeStringSplitted = data.plannedTime.split(':');
        appointment.setHours(timeStringSplitted[0], 0, 0, 0);
        this.setState({listAppointment: [appointment]});
        return tooLate;
    }

    dateIsInList = (date, list) => {
        let isInList = null;
        let currentDay = new Date(date);
        list.forEach((appointment) => {
            if (appointment.getMonth() === currentDay.getMonth() &&
                appointment.getFullYear() === currentDay.getFullYear()
                && appointment.getDate() === currentDay.getDate()) {
                isInList = appointment;
            }
        })
        return isInList;
    }

    _renderDay = (date, isSmallCalendar) => {
        let isAppointment = this.dateIsInList(date, this.state.listAppointment);
        let isFree = this.dateIsInList(date, this.state.listFreeAppointment);

        if (isAppointment != null) {
            return this._renderAppointment(isAppointment, isSmallCalendar)
        } else if (isFree != null) {
            return this._renderFreeTime(isFree, isSmallCalendar)
        }
    };

    getFontSizeRenderAppointment = () => {
        let fontSize: string;
        if (isTablet) {
            fontSize = '5px';
        } else if (this.isMobile()) {
            fontSize = '4px';
        } else {
            fontSize = '12px';
        }
        return fontSize;
    }

    getMarginTopRenderAppointment = () => {
        let marginTop: string;
        if (this.isMobile()) {
            marginTop = '-20%';
        } else if (isTablet) {
            marginTop = '20%';
        } else {
            marginTop = '0%';
        }
        return marginTop;
    }

    getMarginTopRenderAppointmentIsSmallCalendar = (appointment) => {
        return this.getDayMoment(appointment) === 'Matin' ? '-100%' : '-50%';
    }

    displayAppointmentDesktop = (appointment) => {
        return (
            <div style={{marginLeft: '5%', width: '90%', marginTop: '1%', zIndex: this.state.open ? -1 : 1}}>
                <Button
                    style={{
                        width: '100%', backgroundColor: '#009550'
                        , height: '55%', marginTop: '45%',
                        zIndex: this.state.open ? -1 : 1
                    }}> <GridContainer direction="column"
                                       justifyContent="flex-start"
                                       alignItems="flex-start"
                >
                    <GridItem>
                        <div>
                                <span style={{
                                    marginLeft: '0%',
                                    fontSize: this.getFontSizeRenderAppointment(),
                                    color: 'white',
                                    fontFamily: 'Hind Vadodara',
                                    marginTop: '0%',
                                    fontWeight: 'bold'
                                }}>
                                    Votre rdv
                                    </span>
                        </div>
                        <div style={{marginTop: this.getMarginTopRenderAppointment()}}>
                                <span style={{
                                    marginLeft: '0%',
                                    fontSize: this.getFontSizeRenderAppointment(),
                                    color: 'white',
                                    fontFamily: 'Hind Vadodara',
                                    marginTop: this.isMobile() ? '0%' : '-10%',
                                    fontWeight: 'bold'
                                }}>
                                    {this.state.rawData.plannedTimeIsMorning === true ? this.state.rawData.morning : this.state.rawData.afternoon}
                                </span>
                        </div>
                    </GridItem>
                </GridContainer>
                </Button>
            </div>
        )
    }

    displayAppointmentMobile = (appointment) => {
        return (
            <div style={{
                marginTop: this.getMarginTopRenderAppointmentIsSmallCalendar(appointment),
                zIndex: this.zIndexRenderFreeItem(),
                height: '50%',
                cursor: 'pointer',
                backgroundColor: '#009550'
            }}>
                <Button style={{marginTop: this.getMarginTopButton(), marginLeft: '-30%'}}>
                    <span style={{
                        color: 'white',
                        marginLeft: isTablet ? '70%' : '-18%',
                        marginTop: isTablet ? '-20%' : '20%',
                        fontSize: '7px'
                    }}>rdv</span>
                </Button>
            </div>
        )
    }

    _renderAppointment = (appointment, isSmallCalendar) => {
        if (!isSmallCalendar) {
            return (this.displayAppointmentDesktop(appointment));
        } else {
            return (this.displayAppointmentMobile(appointment));
        }
    }

    getMarginTopButton = () => {
        return isTablet ? '20%' : '-30%'
    }

    getMarginTop = (free) => {
        let marginTop = '45%';
        if (free.getHours() >= 12) {
            marginTop = '80%';
        }

        return marginTop;
    }

    itsTooLate = data => {
        let appointment: Date = DateUtil.convertDateNavisionToDate(data.plannedIntervention)
        const diffTime = appointment.getTime() - new Date().getTime();
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        if (diffDays < 7) {
            this.setState({itsTooLate: true, diffDays})
            return true;
        }
        return false;
    }

    getDayMoment = (free) => {
        let moment = 'Matin';
        if (free.getHours() > 12) {
            moment = 'après-midi';
        } else if (free.getHours() === 12) {
            moment = 'Journée';
        }
        return moment;
    }

    getMarginTopRenderFreeTime = () => {
        return isTablet ? '40%' : '0%';
    }

    getSpanFontSize = () => {
        let fontSize;
        if (isTablet) {
            fontSize = '5px';
        } else if (this.isMobile()) {
            fontSize = '4px';
        } else {
            fontSize = '9px';
        }
        return fontSize;
    }

    displayFreeItemDesktop = (free) => {
        return (
            <div>
                {this.getDayMoment(free) === 'Journée' ? (
                    <div style={{
                        height: '100%',
                        marginTop: '40%',
                        zIndex: this.state.open ? 0 : 1,
                        marginLeft: '3%',
                        width: '90%'
                    }}>
                        <Button onClick={() => this.openModal(new Date(free.setHours(9, 0, 0, 0)), false)}
                                disabled={this.disableSelection}
                                style={{
                                    backgroundColor: '#253A9F',
                                    listStyleType: 'none', height: '25%', marginLeft: '5%', width: '90%', marginTop: 0
                                }}>
                            <span style={{
                                marginLeft: '10%',
                                fontFamily: 'Hind Vadodara',
                                fontSize: this.getSpanFontSize(),
                                color: 'white',
                                marginTop: '2.5%',
                                fontWeight: 'bold'
                            }}> {this.state.rawData.morning}</span>
                        </Button>
                        <Button onClick={() => this.openModal(new Date(free.setHours(14, 0, 0, 0)), false)}
                                disabled={this.disableSelection}
                                style={{
                                    backgroundColor: '#253A9F',
                                    listStyleType: 'none',
                                    height: '25%',
                                    marginLeft: '5%',
                                    width: '90%',
                                    marginTop: this.isMobile() ? '1%' : '10%'
                                }}>
                            <span style={{
                                marginLeft: '5%',
                                fontFamily: 'Hind Vadodara',
                                fontSize: this.getSpanFontSize(),
                                color: 'white',
                                marginTop: '2.5%',
                                fontWeight: 'bold'
                            }}> {this.state.rawData.afternoon} </span>
                        </Button>
                    </div>
                ) : (<div style={{
                    height: '100%',
                    zIndex: this.state.open ? 0 : 1,
                    marginTop: '1%',
                    marginLeft: '3%',
                    width: '90%'
                }}>
                    <Button onClick={() => this.openModal(free, false)}
                            disabled={this.disableSelection}
                            style={{
                                backgroundColor: '#253A9F',
                                listStyleType: 'none',
                                height: '25%',
                                width: '90%',
                                marginLeft: '5%',
                                marginTop: this.getMarginTop(free)
                            }}>
                        <span style={{
                            marginLeft: this.displayMarginLeft(free),
                            fontWeight: 'bold',
                            fontSize: this.getSpanFontSize(),
                            color: 'white',
                            marginTop: '2.5%'
                        }}> {this.displaySpanRenderFreeTime(free)} </span>
                    </Button>
                </div>)}
            </div>
        )
    }

    displayMarginLeft = (free) => {
        return this.getDayMoment(free) === 'Matin' ? '10%' : '5%';
    }

    displayFreeItemMobile = (free) => {
        return (
            <div style={{marginTop: this.getMarginTopRenderFreeTime(), zIndex: this.zIndexRenderFreeItem()}}>
                {this.getDayMoment(free) === 'Journée' ? (
                    <div style={{width: '100%', marginTop: '0%', zIndex: 10}}>
                        <div onClick={() => this.openModal(new Date(free.setHours(9, 0, 0, 0)), false)}
                             style={{
                                 backgroundColor: '#253A9F',
                                 listStyleType: 'none',
                                 height: isTablet ? '10%' : '10px',
                                 width: '100%',
                                 marginTop: isTablet ? 0 : '5px',
                                 borderRadius: '10%',
                                 zIndex: 10
                             }}>
                            <span style={{
                                marginLeft: '30%',
                                fontSize: '2px',
                                color: 'white',
                                marginTop: '20%'
                            }}>{this.state.rawData.morning} </span>
                        </div>
                        <div onClick={() => this.openModal(new Date(free.setHours(14, 0, 0, 0)), false)}
                             style={{
                                 backgroundColor: '#253A9F',
                                 listStyleType: 'none',
                                 height: isTablet ? '10%' : '10px',
                                 width: '100%',
                                 marginTop: '-0%',
                                 borderRadius: '10%'
                             }}>
                            <span style={{
                                marginLeft: '30%',
                                fontSize: isTablet ? '5px' : '2px',
                                color: 'white',
                                marginTop: '20%'
                            }}>{this.state.rawData.afternoon}</span>
                        </div>
                    </div>) : (

                    <div onClick={() => this.openModal(free, false)}
                         style={{
                             backgroundColor: '#253A9F',
                             listStyleType: 'none',
                             height: this.getHeightRenderFreeTime(),
                             width: '100%',
                             marginTop: '-0%',
                             borderRadius: '10%'
                         }}>
                        <span style={{
                            marginLeft: '30%',
                            fontSize: '2px',
                            color: 'white',
                            marginTop: '10%'
                        }}>{this.displaySpanRenderFreeTime(free)}</span>
                    </div>
                )}
            </div>
        )
    }

    _renderFreeTime = (free, isSmallCalendar) => {
        if (!isSmallCalendar) {
            return (this.displayFreeItemDesktop(free));
        } else {
            return (this.displayFreeItemMobile(free));
        }
    }

    zIndexRenderFreeItem = (): number => {
        return this.state.open || this.state.error ? -1 : 10;
    }

    displaySpanRenderFreeTime = (free) => {
        return this.getDayMoment(free) === 'Matin' ? this.state.rawData.morning : this.state.rawData.afternoon;
    }

    getHeightRenderFreeTime = () => {
        return isTablet ? '50%' : '10px';
    }

    openModal = (date, isCancel) => {
        if (this.disableSelection) {
            return;
        }
        if (this.state.nbOfPlanif < 2) {
            this.setState({open: true, date, isCancel});
        } else {
            this.setState({error: true, errorText: 'Vous avez déplacé à deux reprises votre rendez-vous d’entretien annuel et ne pouvez donc plus accéder au service. Pour reporter à nouveau votre rendez-vous, contactez nos services par email à l’adresse <a href="mailto:appareil.individuel@gie-sa.fr">appareil.individuel@gie-sa.fr</a> ou par téléphone au <a href="tel:+33388325352">03 88 32 53 52</a>.'});
        }
    }

    savePlanning = (data, noContract, email, mobile, userNo, accept) => {
        this.disableSelection = true;
        const date: Date = new Date(data)

        let year = String(date.getFullYear());
        year = year.substring(year.length - 2, year.length);
        let month = date.getMonth() + 1 >= 10 ? Number(date.getMonth() + 1) : '0' + Number(date.getMonth() + 1)
        let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
        let dateString = day + '/' + month + '/' + year;

        let time = date.getHours() > 12 ? 'PM-OK' : 'AM-OK';
        let affectedRessourceList = this.state.rawData.planningAvailability.filter(x => x.day === dateString && x.ok === time);
        let affectedResource;
        if (affectedRessourceList.length > 0) {
            affectedResource = affectedRessourceList[0].affectedRessource
        }
        const request = {
            noContract,
            affectedResource,
            meetingDate: dateString + ' ' + time,
            email,
            mobile,
            userNo,
            accept
        }
        this.calendarController.savePlanning(request).then(response => {
            // @ts-ignore
            if (response.error != null && response.error === true) {
                // @ts-ignore
                this.setState({error: true, errorText: response.text})
            }
            this.getData(false)
            this.disableSelection = false;
        }).catch(err => {
            console.log(err)
            this.disableSelection = false;
        })
    }

    clickOk = (data, email, mobile, userNo, accept) => {
        const noContract = userInformations.getNoContract()
        if (!noContract?.length) {
            window.location.href = "/admin/login"
        } else {
            this.savePlanning(data, noContract, email, mobile, userNo, accept)
        }
    }

    closeModal() {
        this.setState({open: false});
        this.getData(false);
    }

    checkADay = (event, index) => {
        let listOfDay = this.state.listOfDay;
        listOfDay[index] = listOfDay[index] === 1 ? 0 : 1;
        this.setState({listOfDay})
    }

    isMobile = () => {
        let isMobile = false;
        if (window.screen.width <= Responsive.getMaxMobile(true)) {
            isMobile = true
        }
        return isMobile;
    }

    render() {
        return (
            <div className="content">
                <MediaQuery minWidth={Responsive.getMinTablet()}>
                    <GIEContainer imgUrl={imgUrl}>
                        <TitleCalendar appointmentString={this.state.appointmentString}/>
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <CardWithTitleCalendar
                                freeItem={this.state.rawData != null && this.state.rawData.planningAvailability != null
                                && this.state.rawData.planningAvailability.length > 0 ?
                                    this.state.rawData.planningAvailability[0].day : null} width="70vw"
                                appointment={this.state.rawData ? this.state.rawData.plannedIntervention : null}
                                itsTooLate={this.state.itsTooLate}
                                diffDays={this.state.diffDays}
                                appointmentString={this.state.appointmentString}
                                nbOfPlanif={this.state.nbOfPlanif}
                                errorText={this.state.responseErrorText}/>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <GIECard width="70vw" height="100%">
                                <div style={{padding: '30px'}}>
                                    <span
                                        style={customStyles.titleSearch}>Veuillez nous communiquer vos disponibilités.</span>
                                    <div style={{display: 'flex'}}>
                                        <GridContainer direction="column"
                                                       justifyContent="flex-start"
                                                       alignItems="flex-start"
                                                       style={{paddingLeft: '20px'}}>
                                            <GridItem>
                                                <GIECheckBox checked={this.state.listOfDay[0] === 1}
                                                             emit={(event) => this.checkADay(event, 0)}
                                                             label="Lundi matin"/>
                                            </GridItem>
                                            <GridItem>
                                                <GIECheckBox checked={this.state.listOfDay[1] === 1}
                                                             emit={(event) => this.checkADay(event, 1)}
                                                             label="Lundi après-midi"/>
                                            </GridItem>
                                        </GridContainer>
                                        <GridContainer direction="column"
                                                       justifyContent="flex-start"
                                                       alignItems="flex-start">
                                            <GridItem>
                                                <GIECheckBox checked={this.state.listOfDay[2] === 1}
                                                             emit={(event) => this.checkADay(event, 2)}
                                                             label="Mardi matin"/>
                                            </GridItem>
                                            <GridItem>
                                                <GIECheckBox checked={this.state.listOfDay[3] === 1}
                                                             emit={(event) => this.checkADay(event, 3)}
                                                             label="Mardi après-midi"/>
                                            </GridItem>
                                        </GridContainer>
                                        <GridContainer direction="column"
                                                       justifyContent="flex-start"
                                                       alignItems="flex-start">
                                            <GridItem>
                                                <GIECheckBox checked={this.state.listOfDay[4] === 1}
                                                             emit={(event) => this.checkADay(event, 4)}
                                                             label="Mercredi matin"/>
                                            </GridItem>
                                            <GridItem>
                                                <GIECheckBox checked={this.state.listOfDay[5] === 1}
                                                             emit={(event) => this.checkADay(event, 5)}
                                                             label="Mercredi après-midi"/>
                                            </GridItem>
                                        </GridContainer>
                                        <GridContainer direction="column"
                                                       justifyContent="flex-start"
                                                       alignItems="flex-start">
                                            <GridItem>
                                                <GIECheckBox checked={this.state.listOfDay[6] === 1}
                                                             emit={(event) => this.checkADay(event, 6)}
                                                             label="Jeudi matin"/>
                                            </GridItem>
                                            <GridItem>
                                                <GIECheckBox checked={this.state.listOfDay[7] === 1}
                                                             emit={(event) => this.checkADay(event, 7)}
                                                             label="Jeudi après-midi"/>
                                            </GridItem>
                                        </GridContainer>
                                        <GridContainer direction="column"
                                                       justifyContent="flex-start"
                                                       alignItems="flex-start">
                                            <GridItem>
                                                <GIECheckBox checked={this.state.listOfDay[8] === 1}
                                                             emit={(event) => this.checkADay(event, 8)}
                                                             label="Vendredi matin"/>
                                            </GridItem>
                                            <GridItem>
                                                <GIECheckBox checked={this.state.listOfDay[9] === 1}
                                                             emit={(event) => this.checkADay(event, 9)}
                                                             label="Vendredi après-midi"/>
                                            </GridItem>
                                        </GridContainer>

                                    </div>
                                    <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                                        {this.state.listFreeAppointment.length === 0 && this.state.manualSearch === true ? (
                                            <span style={customStyles.text}>Aucun créneau disponible</span>) : (
                                            <div style={{width: isIE ? '90%' : '100%'}}></div>)}
                                        {this.state.itsTooLate ? null : (<GieButton disabled={this.state.itsTooLate}
                                                                                    onClick={() => this.getData(false)}
                                                                                    green={true}
                                                                                    style={{padding: '12px 25px'}}>
                                            <span style={{
                                                ...customStyles.greenButton,
                                                fontSize: '16px'
                                            }}>Rechercher</span></GieButton>)}
                                    </div>
                                </div>

                            </GIECard>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <GIECard width="70vw" height="100%">
                                <GridContainer direction="row"
                                               justifyContent="flex-start"
                                               alignItems="center"
                                               style={{paddingBottom: '40px'}}>
                                    <GridItem>
                                        <div style={{marginLeft: '4.5vw'}}>
                                            <CalendarMonthView
                                                renderDay={this._renderDay}
                                                style={{backgroundColor: 'transparent'}}
                                                dayTextStyle={{backgroundColor: 'transparent'}}
                                                activeTextStyle={{color: 'green'}}
                                                rdv={this.state.startDateCalendar}
                                            />
                                        </div>
                                    </GridItem>
                                </GridContainer>
                            </GIECard>
                        </div>
                        <PopUpPerso mail={this.state.mail} closeModal={this.closeModal} cancel={this.state.isCancel}
                                    confirmAppointment={this.clickOk} data={this.state.date} open={this.state.open}/>
                        <PopUpError open={this.state.error} closeModal={() => this.setState({error: false})}
                                    text={this.state.errorText}/>
                    </GIEContainer>
                </MediaQuery>
                <MediaQuery maxWidth={Responsive.getMaxMobile()}>
                    <GIEContainer imgUrl={imgUrl}>
                        <TitleCalendar appointment={this.state.appointmentString}/>
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <CardWithTitleCalendar
                                freeItem={this.state.rawData != null && this.state.rawData.planningAvailability != null
                                && this.state.rawData.planningAvailability.length > 0 ?
                                    this.state.rawData.planningAvailability[0].day : null} width="70vw"
                                appointment={this.state.rawData ? this.state.rawData.plannedIntervention : null}
                                itsTooLate={this.state.itsTooLate}
                                diffDays={this.state.diffDays}
                                appointmentString={this.state.appointmentString}
                                nbOfPlanif={this.state.nbOfPlanif}/>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <GIECard width="100vw" height="100%">
                                <div style={{padding: '15px'}}>
                                    <span
                                        style={customStyles.titleSearch}>Veuillez nous communiquer vos disponibilités.</span>
                                    <div style={{display: 'flex', flexDirection: 'column'}}>
                                        <GridContainer direction="row"
                                                       justifyContent="flex-start"
                                                       alignItems="flex-start"
                                                       style={{paddingLeft: '20px'}}>
                                            <GridItem xs={6}>
                                                <GIECheckBox checked={this.state.listOfDay[0] === 1}
                                                             emit={(event) => this.checkADay(event, 0)}
                                                             label="Lundi matin"/>
                                            </GridItem>
                                            <GridItem xs={6}>
                                                <GIECheckBox checked={this.state.listOfDay[1] === 1}
                                                             emit={(event) => this.checkADay(event, 1)}
                                                             label="Lundi après-midi"/>
                                            </GridItem>
                                        </GridContainer>
                                        <GridContainer direction="row"
                                                       justifyContent="flex-start"
                                                       alignItems="flex-start"
                                                       style={{paddingLeft: '20px'}}>
                                            <GridItem xs={6}>
                                                <GIECheckBox checked={this.state.listOfDay[2] === 1}
                                                             emit={(event) => this.checkADay(event, 2)}
                                                             label="Mardi matin"/>
                                            </GridItem>
                                            <GridItem xs={6}>
                                                <GIECheckBox checked={this.state.listOfDay[3] === 1}
                                                             emit={(event) => this.checkADay(event, 3)}
                                                             label="Mardi après-midi"/>
                                            </GridItem>
                                        </GridContainer>
                                        <GridContainer direction="row"
                                                       justifyContent="flex-start"
                                                       alignItems="flex-start"
                                                       style={{paddingLeft: '20px'}}>
                                            <GridItem xs={6}>
                                                <GIECheckBox checked={this.state.listOfDay[4] === 1}
                                                             emit={(event) => this.checkADay(event, 4)}
                                                             label="Mercredi matin"/>
                                            </GridItem>
                                            <GridItem xs={6}>
                                                <GIECheckBox checked={this.state.listOfDay[5] === 1}
                                                             emit={(event) => this.checkADay(event, 5)}
                                                             label="Mercredi après-midi"/>
                                            </GridItem>
                                        </GridContainer>
                                        <GridContainer direction="row"
                                                       justifyContent="flex-start"
                                                       alignItems="flex-start"
                                                       style={{paddingLeft: '20px'}}>
                                            <GridItem xs={6}>
                                                <GIECheckBox checked={this.state.listOfDay[6] === 1}
                                                             emit={(event) => this.checkADay(event, 6)}
                                                             label="Jeudi matin"/>
                                            </GridItem>
                                            <GridItem xs={6}>
                                                <GIECheckBox checked={this.state.listOfDay[7] === 1}
                                                             emit={(event) => this.checkADay(event, 7)}
                                                             label="Jeudi après-midi"/>
                                            </GridItem>
                                        </GridContainer>
                                        <GridContainer direction="row"
                                                       justifyContent="flex-start"
                                                       alignItems="flex-start"
                                                       style={{paddingLeft: '20px'}}>
                                            <GridItem xs={6}>
                                                <GIECheckBox checked={this.state.listOfDay[8] === 1}
                                                             emit={(event) => this.checkADay(event, 8)}
                                                             label="Vendredi matin"/>
                                            </GridItem>
                                            <GridItem xs={6}>
                                                <GIECheckBox checked={this.state.listOfDay[9] === 1}
                                                             emit={(event) => this.checkADay(event, 9)}
                                                             label="Vendredi après-midi"/>
                                            </GridItem>
                                        </GridContainer>

                                    </div>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        width: '70%',
                                        margin: 'auto'
                                    }}>
                                        {this.state.listFreeAppointment.length === 0 && this.state.manualSearch === true ? (
                                            <span style={{
                                                ...customStyles.text,
                                                marginBottom: '10px',
                                                justifyContent: 'center',
                                                fontSize: '13px'
                                            }}>Aucun créneau disponible</span>) : null}
                                        {this.state.nbOfPlanif < 2 ? (
                                            <GieButton onClick={() => this.getData(false)} green={true} style={{
                                                padding: '12px 25px',
                                                width: '100px',
                                                marginLeft: '25%'
                                            }}><span style={{
                                                ...customStyles.greenButton,
                                                fontSize: '12px'
                                            }}>Rechercher</span></GieButton>) : null}
                                    </div>
                                </div>

                            </GIECard>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <GIECard width="100vw" height="100%">
                                <GridContainer direction="row"
                                               justifyContent="flex-start"
                                               alignItems="center"
                                               style={{paddingBottom: '40px'}}>
                                    <GridItem>
                                        <div style={{width: '100vw', marginLeft: '15%'}}>
                                            <CalendarMonthView
                                                renderDay={this._renderDay}
                                                style={{backgroundColor: 'transparent'}}
                                                dayTextStyle={{backgroundColor: 'transparent'}}
                                                activeTextStyle={{color: 'green'}}
                                                rdv={this.state.startDateCalendar}
                                                width="100vw"
                                            />
                                        </div>
                                    </GridItem>
                                </GridContainer>
                            </GIECard>
                        </div>
                        <PopUpPerso mail={this.state.mail} closeModal={this.closeModal} cancel={this.state.isCancel}
                                    confirmAppointment={this.clickOk} data={this.state.date} open={this.state.open}/>
                        <PopUpError open={this.state.error} closeModal={() => this.setState({error: false})}
                                    text={this.state.errorText}/>
                    </GIEContainer>
                </MediaQuery>
            </div>
        );
    }
}
