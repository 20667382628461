// import React...
import React from 'react';
import MediaQuery from 'react-responsive';
import {Responsive} from '../../util/responsive';
import List from '@material-ui/core/List';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Card from "../Card/Card";
import GIEIcon from '../GIEIcon/GIEIcon';
import DownloadController from '../../controller/download.controller';
import file from '../../assets/img/icons/file.svg';
import download from '../../assets/img/icons/download.svg';
import NoticeForList from './NoticeForList';

const ua = window.navigator.userAgent;
const isIE = /MSIE|Trident/.test(ua);

const customStyles = {
    title: {
        marginLeft: '25vw',
        color: '#253A9F',
        fontWeight: 'bold',
        fontFamily: 'Hind Vadodara',


    } as React.CSSProperties,
    list: {
        width: '60vw',
        backgroundColor: 'white',
        color: '#253A9F',
        fontWeight: 'bold',
        fontFamily: 'Hind Vadodara',
    },
    text: {
        color: '#253A9F',
        borderRadius: '2px',
        fontSize: '14px',
        fontWeight: 'bold',
        textTransform: 'none',
        fontFamily: 'Hind Vadodara',
        textAlign: 'left',
    } as React.CSSProperties,
    buttonText: {
        color: 'white',
        textTransform: 'none',
        fontSize: 'bold',
        fontFamily: 'Hind Vadodara',
    } as React.CSSProperties,
};
export default class GIEList extends React.Component<any, any> {
    downloadController: DownloadController = new DownloadController();

    constructor(props) {
        super(props);
        this.state = {
            dense: true,
            secondary: false,
            data: [],
            notice: '',
        }
    }

    componentDidMount = () => {
        this.displayNotice();
    }

    getTypeOfWorkOrder = (workOrderType) => {
        let workOrder = '';
        switch (workOrderType) {
            case 'invoice': {
                workOrder = 'Facture'
                break;
            }
            case 'contract': {
                workOrder = 'Contrat'
                break;
            }

        }
        return workOrder;
    }

    getLibelle = (item) => {
        let type = '';

        switch (this.props.type) {
            case 'invoice': {
                type = (item.number.substring(0, 1) === 'A') ? 'Avoir' : 'Facture';
                break;
            }
            case 'quotation': {
                type = 'Devis'
                break;
            }
            case 'workOrder': {
                type = "Intervention"
                break;
            }
            case 'outstanding': {
                type = 'Encours';
                break;
            }
        }
        type = type + ' n° - ' + item.number + ' - ' + this.getTitle(item) + ' - ' + item.date
        if (this.props.type !== 'workOrder') {
            type = type + ' - Montant : ' + item.amount + '€ '

        } else {
            type = type + this.getTypeOfWorkOrder(item.type)
        }
        return type;
    }

    getTitle = (item) => {
        let title = item.title;
        if (this.props.type === 'invoice' && title.length === 0) {
            title = 'CONTRAT'

        }
        return title;
    }

    downloadIe = data => {
        const newBlob = new Blob([data], {type: "application/pdf"})
        if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
            (window.navigator as any).msSaveOrOpenBlob(newBlob);
            return false;
        }
    }

    downloadChrome = (item, data) => {
        const fileName = item.number + '.pdf'
        const linkSource = `data:application/pdf;base64,${data}`;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    download(item: any) {
        if (item.visible === true) {
            const request = {
                documentNo: item.number,
                custommerNo: item.customerNo,
                docType: item.downloadType,
            }
            this.downloadController.downloadDoc(request).then((data: string) => {
                if (isIE) {
                    this.downloadIe(data);
                } else {
                    this.downloadChrome(item, data)
                }
            }).catch(err => {
                console.log(err)
            })
        } else {
            console.log('no document to download')
        }
    }

    sign = () => {
        console.log('sign')
    }

    displayZeroDocumentText = (): string => {
        let text = 'Vous n’avez pas ';
        switch (this.props.type) {
            case 'invoice': {
                text = text + 'de facture  à consulter.'
                break;
            }
            case 'quotation': {
                text = text + ' de devis à consulter '
                break;
            }
            case 'workOrder': {
                text = text + "d'intervention  à consulter."
                break;
            }
            case 'outstanding': {
                text = "Vous n’avez pas de solde à régulariser"
                break;
            }
        }
        return text;
    }

    displayNotice = (): void => {
        let text = 'Seuls les  documents marqués d’une icône sont téléchargeables ';
        this.setState({notice: text})
    }

    displayCursor = (item) => {
        return item.visible ? 'pointer' : ''
    }

    marginTopDiv = () => {
        return isIE ? '6px' : ''
    }

    displayOverFlow = () => {
        return this.props.data.length < 10 ? 'none' : 'auto';
    }

    displayMarginTop = () => {
        return isIE ? '6px' : '';
    }

    render() {
        return (
            <div className="content">
                <MediaQuery minWidth={Responsive.getMinDesktop()}>
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item>
                            <NoticeForList outstanding={this.props.type === 'outstanding'} text={this.state.notice}
                                           width="60vw"/>
                            <Card style={customStyles.list}>
                                {this.props.data.length === 0 ? (<div
                                        style={{height: '70px', marginTop: '35px', textAlign: 'center', fontSize: '18px'}}>
                                        <span style={{
                                            textAlign: 'center',
                                            fontSize: '18px',
                                        }}>{this.displayZeroDocumentText()}</span></div>) :
                                    (<List style={{maxHeight: 700, overflow: this.displayOverFlow()}}
                                           dense={this.state.dense}>
                                        {this.props.data.map((item, index) => (
                                            <ListItem style={{
                                                display: 'flex',
                                                height: '50px',
                                                cursor: this.displayCursor(item),
                                            }} onClick={() => this.download(item)} key={item._id}>
                                                {item.visible === true ? (
                                                    <div style={{
                                                        height: '100%',
                                                        display: 'flex',
                                                        marginTop: this.marginTopDiv(),
                                                    }}>
                                                        <Tooltip title="Télécharger">
                                                            <Icon style={{
                                                                margin: 'auto',
                                                                marginTop: '0px',
                                                                marginLeft: '14px',
                                                                height: '100%',
                                                                width: '20px',
                                                            }}>
                                                                <img style={{height: '20px'}} alt="icon" src={file}/>
                                                            </Icon>
                                                        </Tooltip>
                                                        <div style={{width: '50px'}}>
                                                            <Tooltip title="Télécharger">
                                                                <Icon style={{
                                                                    margin: 'auto',
                                                                    marginTop: '0px',
                                                                    marginLeft: '14px',
                                                                    height: '100%',
                                                                    width: '20px',
                                                                }}>
                                                                    <img style={{height: '20px'}} alt="icon"
                                                                         src={download}/>
                                                                </Icon>
                                                            </Tooltip>
                                                        </div>
                                                    </div>) : (<div style={{width: '85px'}}></div>)}
                                                <div style={{marginTop: '2px', cursor: this.displayCursor(item)}}>
                                                    <span style={{...customStyles.text, marginLeft: '20px'}}>
                                                        {this.getLibelle(item)}
                                                    </span>
                                                </div>
                                                <ListItemSecondaryAction style={{height: '100%'}}>
                                                    {item.signed === false ? (
                                                        <Tooltip title="Signer votre document"
                                                                 aria-label="Signer votre document">
                                                            <IconButton onClick={this.sign} edge="end"
                                                                        aria-label="voir la facture">
                                                                <GIEIcon margin={true} height={'3vw'} width={'1.8vw'}
                                                                         icon="pen"/>
                                                            </IconButton>
                                                        </Tooltip>) : null}
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        ))}
                                    </List>)}
                            </Card>
                        </Grid>
                    </Grid>
                </MediaQuery>
                <MediaQuery minWidth={Responsive.getMinTablet()} maxWidth={Responsive.getMaxTablet()}>
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item>
                            <NoticeForList text={this.state.notice} width="90vw"/>
                            <Card style={{...customStyles.list, width: '90vw'}}>
                                {this.props.data.length === 0 ? (
                                    <div style={{
                                        height: '70px',
                                        marginTop: '35px',
                                        textAlign: 'center',
                                        fontSize: '18px',
                                    }}><span style={{textAlign: 'center', fontSize: '18px'}}>
                                        {this.displayZeroDocumentText()}
                                    </span>
                                    </div>) : (
                                    <List style={{maxHeight: 700, overflow: this.displayOverFlow()}}
                                          dense={this.state.dense}>
                                        {this.props.data.map((item, index) => (
                                            <ListItem style={{display: 'flex'}} onClick={() => this.download(item)}
                                                      key={item._id}>
                                                {item.visible === true ? (
                                                    <div style={{
                                                        height: '100%',
                                                        display: 'flex',
                                                        marginTop: this.displayMarginTop(),
                                                    }}>
                                                        <Tooltip title="Télécharger">
                                                            <Icon style={{
                                                                margin: 'auto',
                                                                marginTop: '0px',
                                                                marginLeft: '14px',
                                                                height: '100%',
                                                                width: '20px',
                                                            }}>
                                                                <img style={{height: '20px'}} alt="icon" src={file}/>
                                                            </Icon>
                                                        </Tooltip>
                                                        <div style={{width: '60px'}}>
                                                            <Tooltip title="Télécharger">
                                                                <Icon style={{
                                                                    margin: 'auto',
                                                                    marginTop: '0px',
                                                                    marginLeft: '14px',
                                                                    height: '100%',
                                                                    width: '20px',
                                                                }}>
                                                                    <img style={{height: '20px'}} alt="icon"
                                                                         src={download}/>
                                                                </Icon>
                                                            </Tooltip>
                                                        </div>
                                                    </div>) : (<div style={{width: '10px'}}></div>)}
                                                <div style={{cursor: this.displayCursor(item)}}
                                                     onClick={() => this.download(item)}>
                                                    <ListItemText style={{...customStyles.text, marginLeft: '-10px'}}
                                                                  primary={this.getLibelle(item)}
                                                    />
                                                </div>
                                                <ListItemSecondaryAction>
                                                    {item.signed === false ? (
                                                        <Tooltip title="Signer votre document"
                                                                 aria-label="Signer votre document">
                                                            <IconButton onClick={this.sign} edge="end"
                                                                        aria-label="voir la facture">
                                                                <GIEIcon margin={true} height={'2vw'} width={'1.8vw'}
                                                                         icon="pen"/>
                                                            </IconButton>
                                                        </Tooltip>) : null}
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        ))}
                                    </List>)}
                            </Card>
                        </Grid>
                    </Grid>
                </MediaQuery>
                <MediaQuery maxWidth={Responsive.getMaxMobile()}>
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item>
                            <NoticeForList isMobile text={this.state.notice} width="85vw"/>
                            <Card style={{...customStyles.list, width: '85vw'}}>
                                {this.props.data.length === 0 ? (
                                    <span style={{textAlign: 'center'}}>{this.displayZeroDocumentText()}</span>) : (
                                    <List style={{maxHeight: 1000, overflow: this.displayOverFlow()}}
                                          dense={this.state.dense}>
                                        {this.props.data.map((item, index) => (
                                            <ListItem style={{display: 'flex'}} key={item._id}>
                                                {item.visible === true ? (
                                                    <div style={{
                                                        height: '100%',
                                                        display: 'flex',
                                                        marginTop: this.displayMarginTop(),
                                                    }}>
                                                        <Tooltip title="Télécharger">
                                                            <Icon style={{
                                                                margin: 'auto',
                                                                marginTop: '0px',
                                                                marginLeft: '14px',
                                                                height: '100%',
                                                                width: '10px',
                                                            }}>

                                                                <img style={{height: '20px'}} alt="icon" src={file}/>
                                                            </Icon>
                                                        </Tooltip>
                                                        <div style={{width: '70px'}}>
                                                            <Tooltip title="Télécharger">
                                                                <Icon style={{
                                                                    margin: 'auto',
                                                                    marginTop: '0px',
                                                                    marginLeft: '14px',
                                                                    marginRight: '14px',
                                                                    height: '100%',
                                                                    width: '10px',
                                                                }}>
                                                                    <img style={{height: '20px'}} alt="icon2"
                                                                         src={download}/>
                                                                </Icon>
                                                            </Tooltip>
                                                        </div>
                                                    </div>) : (<div style={{width: isIE ? '200px' : '180px'}}></div>)}
                                                <div style={{
                                                    marginTop: '2px',
                                                    marginLeft: isIE ? '20px' : '',
                                                    cursor: this.displayCursor(item),
                                                }} onClick={() => this.download(item)}>
                                                    <span style={{
                                                        ...customStyles.text,
                                                        fontSize: '10px',
                                                        marginLeft: '0',
                                                    }}>
                                                        {this.getLibelle(item)}
                                                    </span>
                                                </div>
                                                <ListItemSecondaryAction style={{height: '100%'}}>
                                                    {item.signed === false ? (
                                                        <Tooltip title="Signer votre document"
                                                                 aria-label="Signer votre document">
                                                            <IconButton onClick={this.sign} edge="end"
                                                                        aria-label="voir la facture">
                                                                <GIEIcon margin={true} height={'2vw'} width={'1.8vw'}
                                                                         icon="pen"/>
                                                            </IconButton>
                                                        </Tooltip>) : null}
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        ))}
                                    </List>)}
                            </Card>
                        </Grid>
                    </Grid>
                </MediaQuery>
            </div>
        );
    }
}
