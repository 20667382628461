import React from "react";


function GieCard({ ...props }) {
    const {
        children,
        big,
        height,
        width,
        ...style
        
      } = props;
  return (
    <div style={{ backgroundColor:"white",...style,marginTop:'2.5%',
                  height:height==null?'30vh':height,
                  width:width==null?'16vw':width,
                  maxWidth:'100vw'}} >
      {children}
    </div>
  );
}

export default (GieCard);
