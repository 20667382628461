import React from 'react';
import MediaQuery from 'react-responsive';
import {Responsive} from '../../util/responsive';
import userInformations from '../../userInformation/user_informations';

const customStyles = {
    content: {
        margin: '10vh 120px 100px 120px'
    },
    text: {
        fontFamily: 'Hind Vadodara',
        color: '#253A9F',
        borderRadius: '2px',
        fontWeight: 'bold',
        fontSize: '14px',
        lineHeight: '21px',
        display: 'flex',
        alignItems: 'center',
        letterSpacing: '0.04em',
        textTransform: 'uppercase'
    } as React.CSSProperties,
    bigText: {
        fontFamily: 'Hind Vadodara',
        color: '#202956',
        borderRadius: '2px',
        fontWeight: 'bold',
        fontSize: '42px',
        lineHeight: '120%'
    } as React.CSSProperties
};

export default class TitleHome extends React.Component<any, any> {

    constructor(props) {
        super(props);
        this.state = {
            name: userInformations.getUserName()
        }
    }

    render() {
        return (
            <>
                <MediaQuery minWidth={Responsive.getMinDesktop()}>
                    <div style={{margin: '10vh 120px 100px 120px'}}>
                        <div><span style={customStyles.text}>Mon espace personnel</span></div>
                        <div style={{marginTop: '10px'}}><span
                            style={customStyles.bigText}>Bonjour {this.state.name}</span></div>
                        <div><span style={customStyles.bigText}>Bienvenue sur votre espace personnel</span></div>
                    </div>
                </MediaQuery>
                <MediaQuery minWidth={Responsive.getMinTablet()} maxWidth={Responsive.getMaxTablet()}>
                    <div style={{margin: '5vh 60px 60px 60px'}}>
                        <div><span style={customStyles.text}>Mon espace personnel</span></div>
                        <div style={{marginTop: '10px'}}><span
                            style={{...customStyles.bigText, fontSize: '34px'}}>Bonjour {this.state.name}</span></div>
                        <div><span style={{...customStyles.bigText, fontSize: '34px'}}>Bienvenue sur votre espace personnel</span>
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={Responsive.getMaxMobile()}>
                    <div style={{margin: '4vh 25px 70px 25px'}}>
                        <div><span style={customStyles.text}>Mon espace personnel</span></div>
                        <div style={{marginTop: '10px'}}><span
                            style={{...customStyles.bigText, fontSize: '26px'}}>Bonjour {this.state.name}</span></div>
                        <div><span style={{...customStyles.bigText, fontSize: '26px'}}>Bienvenue sur votre espace personnel</span>
                        </div>
                    </div>
                </MediaQuery>
            </>
        );
    }
}
