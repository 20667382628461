/*eslint-disable*/
import React from "react";
import MediaQuery from 'react-responsive'
// used for making the prop types of this component
import GridItem from "../Grid/GridItem.jsx";
import GridContainer from "../Grid/GridContainer.jsx";
import {Responsive} from '../../util/responsive';
import qualifications from '../../assets/img/qualifications.svg'
import pg from '../../assets/img/pg.svg'
import effigazIe from '../../assets/img/effigazIe.svg'
import effigaz from '../../assets/img/effigaz.svg'
import horaires from '../../assets/img/horaires.svg';
import pyramides from '../../assets/img/pyramide.svg';
import find from '../../assets/img/find.svg';

const ua = window.navigator.userAgent;
const isIE = /MSIE|Trident/.test(ua);
const customStyles = {
    main: {
        padding: '40px 0px'
    },
    content: {
        paddingLeft: '100%',
        paddingTop: '1%'
    },
    text: {
        color: 'white',
        fontSize: '16px',
        fontFamily: 'Hind Vadodara'
    },
    minText: {
        color: 'white',
        fontSize: '5vw',
        fontFamily: 'Hind Vadodara'
    },
    bigText: {
        letterSpacing: '0.04em',
        color: 'white',
        fontFamily: 'Hind Vadodara',
        alignItems: 'center',
        textAlign: 'center',
        fontWeight: 'bold',
        lineHeight: '21px'
    }

};

class FooterMiddle extends React.Component {
    render() {
        return (
            <div>
                <MediaQuery minWidth={Responsive.getMinDesktop()}>
                    <div style={customStyles.main}>
                        <GridContainer
                            direction="row"
                            justifyContent="flex-start"
                            spacing={3}>
                            <GridItem xs={4}>
                                <div>
                                    {/*<span style={{...customStyles.bigText,fontSize :'1.1vw'}}>NOS QUALIFICATIONS</span>*/}
                                    <img src={qualifications}/>
                                </div>
                                <div style={{display: 'flex', marginTop: '20px'}}>
                                    <div style={{display: 'flex'}}>
                                        <img src={pyramides}/>
                                        <img style={{marginLeft: '24px'}} src={pg}/>
                                        <img style={{marginLeft: '24px'}} src={isIE ? effigazIe : effigaz}/>
                                    </div>
                                </div>
                            </GridItem>
                            <GridItem xs={4} style={customStyles.content}>
                                <div>
                                    {/* <span style={{...customStyles.bigText,fontSize :window.innerWidth>576?'1.3vw':'2vw'}}>
                                    NOUS TROUVER
                                </span>*/}
                                    <img src={find}/>
                                </div>
                                <div style={{marginTop: '10px'}}>
                                <span style={customStyles.text}>
                                    14 Place des Halles
                                </span>
                                </div>
                                <div>
                                    <span style={customStyles.text}>
                                        67000 Strasbourg
                                    </span>
                                </div>
                            </GridItem>
                            <GridItem xs={4}>
                                <div>
                                    {/*<span style={{...customStyles.bigText,fontSize :window.innerWidth>576?'1.3vw':'2vw'}}>
                                    NOUS HORAIRES
                                </span>*/}
                                    <img src={horaires}/>
                                </div>
                                <div style={{marginTop: '10px'}}>
                                <span style={customStyles.text}>
                                    Lundi - vendredi
                                </span>
                                </div>
                                <div>
                                <span style={customStyles.text}>
                                    7h30 à 12h / 13h30 à 17h</span>
                                </div>
                            </GridItem>
                        </GridContainer>
                    </div>
                </ MediaQuery>
                <MediaQuery minWidth={Responsive.getMinTablet()} maxWidth={Responsive.getMaxTablet()}>
                    <div style={customStyles.main}>
                        <GridContainer
                            direction="row"
                            justifyContent="flex-start"
                            spacing={3}>
                            <GridItem xs={4}>
                                <div>
                                    {/*<span style={{...customStyles.bigText,fontSize :'1.1vw'}}>NOS QUALIFICATIONS</span>*/}
                                    <img src={qualifications}/>
                                </div>
                                <div style={{display: 'flex', marginTop: '20px'}}>
                                    <div style={{display: 'flex'}}>
                                        <img src={pyramides}/>
                                        <img style={{marginLeft: '24px'}} src={pg}/>
                                        <img style={{marginLeft: '24px'}} src={effigaz}/>
                                    </div>
                                </div>
                            </GridItem>
                            <GridItem xs={4} style={customStyles.content}>
                                <div>
                                    {/* <span style={{...customStyles.bigText,fontSize :window.innerWidth>576?'1.3vw':'2vw'}}>
                                    NOUS TROUVER
                                </span>*/}
                                    <img src={find}/>
                                </div>
                                <div style={{marginTop: '10px'}}>
                                <span style={customStyles.text}>
                                    14 Place des Halles
                                </span>
                                </div>
                                <div>
                                <span style={customStyles.text}>
                                    67000 Strasbourg
                                </span>
                                </div>
                            </GridItem>
                            <GridItem xs={4}>
                                <div>
                                    {/*<span style={{...customStyles.bigText,fontSize :window.innerWidth>576?'1.3vw':'2vw'}}>
                                    NOUS HORAIRES
                                </span>*/}
                                    <img src={horaires}/>
                                </div>
                                <div style={{marginTop: '10px'}}>
                                <span style={customStyles.text}>
                                    Lundi - vendredi
                                </span>
                                </div>
                                <div>
                                <span style={customStyles.text}>
                                    7h30 à 12h / 13h30 à 17h
                                </span>
                                </div>
                            </GridItem>
                        </GridContainer>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={Responsive.getMaxMobile()}>
                    <div style={{...customStyles.main}}>
                        <GridContainer
                            direction="column"
                            justifyContent="flex-start"
                            spacing={3}>
                            <GridItem xs={9}>
                                <div>
                                    {/* <span style={{...customStyles.bigText,fontSize :window.innerWidth>576?'1.3vw':'2vw'}}>
                                    NOUS TROUVER
                                </span>*/}
                                    <img src={find}/>
                                </div>
                                <div style={{marginTop: '10px'}}>
                                <span style={customStyles.text}>
                                    14 Place des Halles
                                </span>
                                </div>
                                <div>
                                <span style={{...customStyles.text, marginTop: '100px'}}>
                                    67000 Strasbourg
                                </span>
                                </div>
                            </GridItem>
                            <GridItem xs={9} style={{marginTop: '100px'}}>
                                <div>
                                    {/*<span style={{...customStyles.bigText,fontSize :window.innerWidth>576?'1.3vw':'2vw'}}>
                                    NOUS HORAIRES
                                </span>*/}
                                    <img src={horaires}/>
                                </div>
                                <div style={{marginTop: '10px'}}>
                                <span style={customStyles.text}>
                                    Lundi - vendredi
                                </span>
                                </div>
                                <div style={{marginTop: '10px'}}>
                                <span style={customStyles.text}>
                                    7h30 à 12h / 13h30 à 17h
                                </span>
                                </div>
                            </GridItem>
                            <GridItem xs={9} style={{marginTop: '100px'}}>
                                <div>
                                    {/*<span style={{...customStyles.bigText,fontSize :'1.1vw'}}>NOS QUALIFICATIONS</span>*/}
                                    <img src={qualifications}/>
                                </div>
                                <div style={{display: 'flex', marginTop: '50px'}}>
                                    <div style={{display: 'flex'}}>
                                        <img src={pyramides}/>
                                        <img style={{marginLeft: '24px'}} src={pg}/>
                                        <img style={{marginLeft: '24px'}} src={effigaz}/>
                                    </div>
                                </div>
                            </GridItem>
                            <GridItem>
                                <div style={{marginTop: '150px'}}>
                                <span style={customStyles.text}>
                                    L'énergie est notre avenir, <br/> économisons-la !
                                </span>
                                </div>
                            </GridItem>
                        </GridContainer>
                    </div>
                </MediaQuery>
            </div>
        );
    }
}

export default FooterMiddle;
