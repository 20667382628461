import React from 'react';
import ReactGA from 'react-ga';
import imgUrl from '../../assets/img/pen.png';
import GIEContainer from '../../components/GIEContainer/GIEContainer';
import GIEList from '../../components/GIEList/GIEList';
import TitleScreenList from '../../components/TitleListeScreen/TitleListScreen';
import BillController from '../../controller/bill.controller';
import {DateUtil} from '../../util/date-util';
import {ListUtil} from '../../util/list-util';
import userInformations from '../../userInformation/user_informations';

export default class MyBill extends React.Component<any, any> {
    billController: BillController = new BillController();

    constructor(props) {
        super(props);
        this.state = {
            data: []
        }
    }

    componentDidMount() {
        this.getInvoiceList();
        ReactGA.pageview(window.location.pathname + window.location.search);
    }

    getInvoiceList = () => {
        const noContract = userInformations.getNoContract();
        if (!noContract?.length) {
            window.location.href = "/admin/login"
        } else {
            this.billController.getBill().then(data => {
                this.manageData(data);
            }).catch(err => {
                console.log(err)
            })
        }
    }

    manageData = data => {
        data = ListUtil.sortByFieldDesc(data, 'postingDate')
        const invoiceList = data.map(line => {
            return {
                _id: line._id,
                number: line.no ? line.no : '',
                title: line.description ? line.description : '',
                date: line.postingDate ? DateUtil.convertDateInString(new Date(line.postingDate)) : '',
                amount: line.amountIncludingVAT ? line.amountIncludingVAT : '',
                customerNo: line.billToCustomerNo,
                paid: true,
                visible: line.pdfExist,
                downloadType: line.downloadType
            }
        })
        this.setState({data: invoiceList})
    }

    render() {
        return (
            <div className="content">
                <GIEContainer height="180vh" imgUrl={imgUrl}>
                    <TitleScreenList miniTilte="Mes Documents" href="mes-factures" title="Mes factures"/>
                    <GIEList data={this.state.data} type={"invoice"}/>
                </GIEContainer>
            </div>
        );
    }
}
