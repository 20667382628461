import React from "react";
import MediaQuery from 'react-responsive';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MaterialIcon from 'material-icons-react';
import Menu from '@material-ui/core/Menu';
import Icon from '@material-ui/core/Icon';
import MenuItem from '@material-ui/core/MenuItem';
import GIEButton from '../GIEButton/GieButton';
import GIEIcon from '../../components/GIEIcon/GIEIcon';
import {Responsive} from '../../util/responsive';
import HeaderMobile from './HeaderMobile';
import greyWarning from '../../assets/img/icons/greyWarning.svg';
import logo from "../../assets/img/GIE2.svg";
import {HeaderUtil} from '../../util/header-util';
import './Header.css';
import userInformations from '../../userInformation/user_informations';

const ua = window.navigator.userAgent;
const isIE = /MSIE|Trident/.test(ua);

const customStyles = HeaderUtil.getStyleHeader();

class Header extends React.Component<any, any> {
    userNo: string;

    constructor(props) {
        super(props)
        this.state = {
            openMyAccount: false,
            anchorEl: null
        }

        this.userNo = userInformations.getUserNo();
    }

    goService = () => {
        window.location.href = "/admin/services"
    }

    goGie = () => {
        window.location.href = "/admin/accueil"
    }

    openAccount = (event) => {
        this.setState({openMyAccount: !this.state.openMyAccount, anchorEl: event.currentTarget});
    }

    handleClose = (event) => {
        this.setState({openMyAccount: false});
        if (typeof event === 'string') {
            window.location.href = event
        }
    }

    render() {
        const {openMyAccount, anchorEl} = this.state;
        return (
            <div>
                <MediaQuery minWidth={Responsive.getMinDesktop()}>
                    <div style={{...customStyles.header, width: '100vw'}}>
                        <div style={customStyles.content}>
                            <div style={customStyles.image} onClick={this.goGie}>
                                <img alt="/admin/accueil" src={logo}/>
                            </div>
                            <div style={{...customStyles.buttonDiv, marginLeft: '1vw'}}>
                                {this.userNo && <>
                                    <div style={customStyles.emergencyDiv}>
                                        <Icon style={{height: '34px', marginBottom: isIE ? '-8px' : '1px'}}>
                                            <img style={{height: '16px', width: '14px'}} alt="icon" src={greyWarning}/>

                                        </Icon>
                                        <a style={{color: '#626886'}} href="/admin/formulaire-d-urgence">
                                            SIGNALER UNE PANNE
                                        </a>
                                    </div>
                                    <GIEButton onClick={this.goService} aria-controls="service-menu"
                                               aria-haspopup="true"
                                               style={{...customStyles.buttonGreen}} green={true}>
                                    <span style={{
                                        ...customStyles.buttonText,
                                        marginTop: '-0vh'
                                    }}>{'Services en ligne'}</span>
                                        <MaterialIcon size="24px" color="white" icon="expand_more"/>
                                    </GIEButton>
                                </>}
                                {!this.userNo && <a className="item" href="replanification">
                                        <ListItemIcon
                                            style={{display: 'flex', alignContent: 'center', justifyContent: 'center'}}>
                                            <GIEIcon icon="logout" header width={'20px'} height={"100%"}/>
                                        </ListItemIcon>
                                        <span>Déconnexion</span>
                                    </a>}
                                {this.userNo && <><GIEButton aria-controls="simple-menu"
                                                             aria-haspopup="true" onClick={this.openAccount}
                                                             style={{...customStyles.buttonBlue}}>
                                    <span style={{...customStyles.buttonText, marginTop: 'Ovh'}}>{'Mon compte'} </span>
                                    <MaterialIcon size={'20vh'} color="white" icon="expand_more"/>
                                </GIEButton>
                                    <Menu
                                        id="simple-menu"
                                        keepMounted
                                        open={openMyAccount}
                                        onClose={this.handleClose}
                                        anchorEl={anchorEl}
                                        style={{marginTop: '66px'}}>
                                        <MenuItem style={{...customStyles.menuContent}}>
                                            <ListItemIcon
                                                style={{
                                                    display: 'flex',
                                                    alignContent: 'center',
                                                    justifyContent: 'center'
                                                }}>
                                                <GIEIcon icon="account" header width={'20px'} height={"100%"}/>
                                            </ListItemIcon>
                                            <a className="item" href="mes-informations">Mes informations</a>
                                        </MenuItem>
                                        <MenuItem style={{...customStyles.menuContent}}>
                                            <ListItemIcon
                                                style={{
                                                    display: 'flex',
                                                    alignContent: 'center',
                                                    justifyContent: 'center'
                                                }}>
                                                <GIEIcon icon="folder" header width={'20px'} height={"100%"}/>
                                            </ListItemIcon>
                                            <a className="item" href="documents">Mes documents</a>
                                        </MenuItem>
                                        <MenuItem style={{...customStyles.menuContent}}>
                                            <ListItemIcon
                                                style={{
                                                    display: 'flex',
                                                    alignContent: 'center',
                                                    justifyContent: 'center'
                                                }}>
                                                <GIEIcon icon="logout" header width={'20px'} height={"100%"}/>
                                            </ListItemIcon>
                                            <a className="item" href="login">Déconnexion</a>
                                        </MenuItem>
                                    </Menu></>}
                            </div>
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery minWidth={Responsive.getMinTablet()} maxWidth={Responsive.getMaxTablet()}>
                    {/*  <HeaderTablet/>*/}
                    <HeaderMobile/>
                </MediaQuery>
                <MediaQuery maxWidth={Responsive.getMaxMobile()}>
                    <HeaderMobile/>
                </MediaQuery>
            </div>
        );
    }
}

export default Header;
