/* istanbul ignore file */
export default class DataDefault {

    getGearSelect = () => {
        return [
            { label: 'Une chaudière gaz ', value: 'gas' },
            { label: 'Une chaudière fioul', value: 'fuel' },
            { label: 'Une Pompe à Chaleur ', value: 'heat-pump' },
            { label: 'Une production d’eau chaude solaire', value: 'solar' },
            { label: 'Une climatisation', value: 'air-contionning' }
        ]
    }
}