import React from 'react';
import MediaQuery from 'react-responsive';
import {Responsive} from '../../util/responsive';
import GieCard from '../../components/GIECard/GieCard';
import GIEIcon from '../../components/GIEIcon/GIEIcon';
import MaterialIcon from 'material-icons-react';
import GridItem from "../../components/Grid/GridItem.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import './Home.css';
import GIEIconTablet from '../../components/GIEIcon/GIEIconTablet';

const customStyles = {
    content: {
        marginTop: '-1vh'
    },
    firstTitle: {
        color: '#253A9F',
        borderRadius: '2px',
        fontSize: '1.1rem',
        fontWeight: 600,
        textTransform: 'uppercase',
        fontFamily: 'Hind Vadodara',
        textAlign: 'center',
        marginTop: '10vh'
    } as React.CSSProperties,
    firstTitleRdv: {
        color: '#253A9F',
        borderRadius: '2px',
        fontSize: '1.8rem',
        fontWeight: 600,
        textTransform: 'uppercase',
        fontFamily: 'Hind Vadodara',
        textAlign: 'center',
        marginLeft: '1vw'
    } as React.CSSProperties,
    secondTitle: {
        color: '#202956',
        fontSize: '1.8rem',
        fontWeight: 600,
        fontFamily: 'Hind Vadodara',
    } as React.CSSProperties,
    secondTitleRdv: {
        color: '#202956',
        fontWeight: 600,
        fontSize: '1.8rem',
        marginLeft: '0',
        fontFamily: 'Hind Vadodara',
    } as React.CSSProperties,
    fourTitle: {
        marginTop: '1vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    } as React.CSSProperties,
    fourTitleBill: {
        display: 'flex',
        marginTop: '-0vw',
        marginBottom: '-1vw'
    } as React.CSSProperties
};

export default class CardHome extends React.Component<any, any> {

    constructor(props) {
        super(props);
        this.state = {
            isGreenAnchor: false
        }
    }

    redirect = (endUrl: string) => {
        if (this.props.hideLink !== true) {
            window.location.href = endUrl;
        }

    }

    mouseOver = (isGreen) => {
        this.setState({ isGreenAnchor: isGreen })
    }

    render() {
        return (
            <>
                <MediaQuery minWidth={Responsive.getMinDesktop()}>
                    <GieCard width={'23vw'} height={'23vw'} >
                        <div className='test' onMouseEnter={() => this.mouseOver(true)} onMouseLeave={() => this.mouseOver(false)} onClick={() => this.redirect(this.props.url)}
                        style={{ display: 'block', cursor: this.props.hideLink !== true?'pointer':'', width: "100%", height: "100%", marginLeft: '15px', padding: '10% 0' }}>
                            <GridContainer direction="column"
                                alignItems="center"
                                justifyContent="flex-start"
                                spacing={2}
                                style={{ width: "100%", height: "100%" }}>
                                <GridItem style={{ height: '30%', marginBottom: '5%' }}>
                                    <GIEIcon isMobile={false} circle={true} icon={this.props.icon} />
                                </GridItem>
                                <GridItem style={{ justifyContent: 'center', textOverflow: 'ellipsis', width: '90%', flexGrow: 1 }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div style={{ margin: 'auto', textAlign: 'center' }}>
                                            <span style={customStyles.firstTitle}>{this.props.firstTitle}</span>
                                        </div>
                                        <div style={{ margin: 'auto', textAlign: 'center' }}>
                                            <span style={this.props.rdv !== true ? customStyles.secondTitle : customStyles.secondTitleRdv}>{this.props.secondTitle}</span>
                                        </div>
                                    </div>
                                </GridItem>

                                {this.props.hideLink === true ? (<GridItem></GridItem>) : (
                                        <GridItem >
                                            <div style={customStyles.fourTitle}>
                                                <a style={{ color: this.state.isGreenAnchor ? '#009550' : '#9297B0', fontFamily: 'Hind Vadodara', fontWeight: 'bold', marginLeft: '1vw', fontSize: window.innerWidth < 576 ? '3vw' : '1vw' }} href={this.props.url}  > {this.props.link}</a>
                                                <div style={{ display: 'flex' }}>
                                                    <MaterialIcon size={15} color='#9297B0' icon="keyboard_arrow_right" />
                                                </div>
                                            </div>
                                        </GridItem>)}
                            </GridContainer>
                        </div>
                    </GieCard>
                </MediaQuery>
                <MediaQuery minWidth={Responsive.getMinTablet()} maxWidth={Responsive.getMaxTablet()}>
                    <GieCard width={'100%'} height={'250px'}>
                        <div onClick={() => this.redirect(this.props.url)} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', width: "100%", height: "100%", padding: '5% 10%' }}>
                            <div>
                                <GIEIconTablet isMobile={true} circle={true} icon={this.props.icon} />
                            </div>
                            <div style={{flexGrow: 1, padding: '0 10px'}}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div style={{ textAlign: 'center' }}>
                                        <span style={{ ...customStyles.firstTitle, fontSize: '14px' }}>{this.props.firstTitle}</span>
                                    </div>
                                    <div style={{ textAlign: 'center' }}>
                                        <span style={{ ...customStyles.secondTitle, fontSize: '24px' }}>{this.props.secondTitle}</span>
                                    </div>
                                </div>

                                {this.props.hideLink === true ? (<GridItem></GridItem>) :
                                    (<div style={customStyles.fourTitle}>
                                        <a style={{ fontFamily: 'Hind Vadodara', fontWeight: 'bold', fontSize: '16px'}} href={this.props.url}> {this.props.link}</a>
                                        <div style={{ display: 'flex' }}>
                                            <MaterialIcon size={15} color='#9297B0' icon="keyboard_arrow_right" />
                                        </div>
                                    </div>)}
                            </div>
                        </div>
                    </GieCard>
                </MediaQuery>
                <MediaQuery maxWidth={Responsive.getMaxMobile()}>
                    <GieCard width={'75vw'} height={'100%'}>
                        <div className='test' onClick={() => this.redirect(this.props.url)} style={{ display: 'block', cursor: 'pointer', width: "100%", height: "100%", minHeight: '70vw', marginLeft: '15px', padding: '10% 0 5%' }}>
                            <GridContainer direction="column"
                                alignItems="center"
                                justifyContent="flex-start"
                                spacing={2}
                                style={{ width: "100%", height: "100%" }}>
                                <GridItem style={{ height: '30%', marginBottom: '5%' }}>
                                    <GIEIcon isMobile={true} circle={true} icon={this.props.icon} />
                                </GridItem>
                                <GridItem style={{ justifyContent: 'center', height: '50%', marginBottom: '10%', flexGrow: 1 }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', justifyItems: 'center', alignItems: 'center' }}>
                                        <div>
                                            <span style={{ ...customStyles.firstTitle, fontSize: '14px' }}>{this.props.firstTitle}</span>
                                        </div>
                                        <div style={{ textAlign: 'center' }}>
                                            <span style={{ ...customStyles.secondTitle, fontSize: '20px' }}>{this.props.secondTitle}</span>
                                        </div>
                                    </div>
                                </GridItem>

                                {this.props.hideLink === true ? (<GridItem></GridItem>) :
                                    (<GridItem>
                                        <div style={customStyles.fourTitle}>
                                            <a style={{ fontFamily: 'Hind Vadodara', fontWeight: 'bold', textAlign: 'center' }} href={this.props.url}  > {this.props.link}</a>
                                            <div style={{ display: 'flex' }}>
                                                <MaterialIcon size={15} color='#9297B0' icon="keyboard_arrow_right" />
                                            </div>
                                        </div>
                                    </GridItem>)}
                            </GridContainer>
                        </div>
                    </GieCard>
                </MediaQuery>
            </>
        );
    }
}
