/* istanbul ignore file */
import GenericController from './generic.controller';

export default class CalendarController extends GenericController {
    getFreePlanning = (request) => {
        return this.requestPostBack('/appointment/get-calendar', request).then(data => {
            return data
        }).catch(err => {
            console.log(err);
            throw err
        })
    }

    savePlanning = (request) => {
        return this.requestPostBack('/appointment/save-appointment', request).then(data => {
            return data
        }).catch(err => {
            console.log(err);
            throw err
        })
    }
}
