import React from "react";
// used for making the prop types of this component

import MaterialIcon from 'material-icons-react';

import GIEButton from '../GIEButton/GieButton';
import {SideBarStyleUtils} from './SideBarStyleUtils';
import './Header.css';

const customStyles = SideBarStyleUtils.getStyle();

class GIESideBarContent extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            openMyAccount: false
        }
    }

    redirect = (endUrl: string) => {
        window.location.href = '/admin/' + endUrl
    }

    goFAQ = () => {
        window.location.href = "https://gie-sa.fr/faq/"
    }

    goService = () => {
        window.location.href = "/admin/services"
    }

    openAccount = (event) => {
        this.setState({openMyAccount: !this.state.openMyAccount});
    }

    close = () => {
        this.props.close()
    }

    render() {
        return (
            <div style={customStyles.sideBar}>
                {this.state.openMyAccount === false ?
                    (<div>
                        <div onClick={this.close}
                             style={{paddingTop: '25px', paddingLeft: '25px', marginBottom: '44px'}}>
                            <MaterialIcon size={20} color="#202956" icon="clear"/>
                        </div>

                        <div style={{...customStyles.buttonDiv}}>
                            <GIEButton onClick={this.goService} style={{
                                ...customStyles.buttonGreen
                            }} green={true}>
                                <span style={{
                                    ...customStyles.buttonText,
                                    marginRight: '3px'
                                }}>{'Services en ligne'}</span>
                                <MaterialIcon size={'20vh'} color="white" icon="keyboard_arrow_right"/>
                            </GIEButton>

                            <div>
                                <ul className="nav-gie" style={{margin: 'auto'}}>
                                    <li>
                                        <a style={{border: 'none'}} href={"https://gie-sa.fr/notre-entreprise/"}>
                                            Notre Entreprise
                                        </a>
                                    </li>
                                    <li>
                                        <a href={"https://gie-sa.fr/chauffage/"}>Chauffage</a>
                                    </li>
                                    <li>
                                        <a href={"https://gie-sa.fr/climatisation/"}>Climatisation</a>
                                    </li>
                                    <li>
                                        <a href={"https://gie-sa.fr/energies-renouvelables/"}>Énergies Renouvelables</a>
                                    </li>
                                </ul>
                            </div>

                            <GIEButton onClick={this.goFAQ} style={{...customStyles.buttonQuestion}}>
                                <span style={{
                                    ...customStyles.buttonText,
                                    color: '#009550',
                                    marginTop: 'Ovh'
                                }}>{'Une question ?'} </span>
                            </GIEButton>
                        </div>
                    </div>) : (null)}
            </div>
        )
    }
}

export default GIESideBarContent;
