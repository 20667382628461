import React from "react";

// used for making the prop types of this component
import Sidebar from "react-sidebar";
import IconButton from '@material-ui/core/IconButton';
import GIEIcon from '../../components/GIEIcon/GIEIcon';
import GIESideBarContent from './GIESideBarContent';
import {HeaderUtil} from '../../util/header-util';

import './Header.css';
import imageGIE2 from '../../assets/img/GIE2.jpg';

const customStyles = HeaderUtil.getStyle();

class GIEHeaderMobile extends React.Component<any, any> {

    constructor(props) {
        super(props);
        this.state = {
            open: false
        }
        this.openSideBar = this.openSideBar.bind(this)
    }


    openSideBar = () => {
        this.setState({open: !this.state.open})
    }

    render() {
        return (
            <div style={{...customStyles.header, width: '100vw', maxWidth: '100%', position: 'relative'}}>
                <div style={customStyles.content}>
                    <div style={customStyles.image}>
                        <img alt="https://gie-sa.fr/" src={imageGIE2}/>
                    </div>
                </div>
                <Sidebar
                    sidebar={<GIESideBarContent close={this.openSideBar}/>}
                    styles={{ sidebar: { background: 'white', position: 'fixed' } }}
                    open={this.state.open}>
                </Sidebar>
                <div style={customStyles.hamburger}>
                    <IconButton onClick={this.openSideBar}>
                        <GIEIcon icon="menu" width={'12px'}/>
                    </IconButton>
                </div>
            </div>);
    }
}


export default GIEHeaderMobile;
