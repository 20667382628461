import React from 'react';
import Modal from 'react-modal';
import Button from '@material-ui/core/Button';
import CardHeader from "../../components/Card/CardHeader";
import Card from "../../components/Card/Card.jsx";
import {DateUtil} from '../../util/date-util';
import {Responsive} from '../../util/responsive';
import GIETextField from '../../components/GIETextField/GieTextField';
import {FormValidator} from '../../util/form-validator';
import {PopUpUtil} from '../../util/pop-up-util';
import GIECheckBox from '../../components/GIECheckBox/GieCheckBox';
import userInformations from '../../userInformation/user_informations';

const customStyles = PopUpUtil.getStyle()

export default class PopUpCalendar extends React.Component<any, any> {
    formValidator: FormValidator = new FormValidator();
    userNo: string;
    disableBtn: boolean = false;

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            date: '',
            momentInday: 'matin',
            formControls: {
                email: {
                    value: '',
                    error: false,
                    errorMessage: ''
                },
                mobile: {
                    value: '',
                    error: false,
                    errorMessage: ''
                },
                accept: {
                    value: false,
                    error: false,
                    errorMessage: ''
                }
            }
        }
        this.userNo = userInformations.getUserNo();
        this.closeModal = this.closeModal.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps !== this.props) {
            this.setState({
                open: this.props.open,
                date: this.props.data != null ? DateUtil.convertDateInStringAllInFrench(this.props.data) : ''
            });
            if (this.props.mail != null) {
                let formControls = this.state.formControls;
                formControls.email.value = this.props.mail;
                this.setState({formControls});
            }
        }
    }

    changeHandler = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        const error = this.formValidator.returnError(value, name);

        this.setState({
            formControls: {
                ...this.state.formControls,
                [name]: {
                    ...this.state.formControls[name],
                    value,
                    error: error.error,
                    errorMessage: error.errorMessage
                }
            }
        })
        if (this.state.formControls.mobile.value.length === 0) {
            let formControls = this.state.formControls;
            formControls.mobile.error = false;
            this.setState(formControls)
        }
    }

    checkValidForm = () => {
        let formControls = this.state.formControls;
        let isError = false;
        Object.keys(formControls).forEach(name => {
            if (this.state.formControls.mobile.value.length === 0 && name === 'mobile') {
                console.log('no mobile phone!');
            } else if (!this.state.formControls.accept.value && name === 'accept') {
                console.log('not accepted !');
            } else {
                const error = this.formValidator.returnError(formControls[name].value, name);
                formControls[name].error = error.error;
                formControls[name].errorMessage = error.errorMessage;
                if (error.error === true) {
                    isError = true
                }
            }
        });
        this.setState(formControls)
        return isError;
    }

    closeModal() {
        this.props.closeModal();
    }

    confirm = (e) => {
        this.disableBtn = true;
        if (this.checkValidForm() === false) {
            this.closeModal();
            this.props.confirmAppointment(this.props.data, this.state.formControls.email.value, this.state.formControls.mobile.value, this.userNo, this.state.formControls.accept.value);
        }
        this.disableBtn = false;
    }

    getDayMoment = () => {
        const date = new Date(this.props.data)
        return date.getHours() > 12 ? 'après-midi' : 'matin'
    }

    getStyleModal = () => {
        let style: any;
        if (window.screen.width >= Responsive.getMinDesktop(true)) {
            style = PopUpUtil.getStyle()
        } else if (window.screen.width <= Responsive.getMaxMobile(true)) {
            style = PopUpUtil.getStyleMobile()
        } else {
            style = PopUpUtil.getStyleTablet();
        }
        return style;
    }

    checkCGV = (event) => {
        let formControls = this.state.formControls;
        formControls.accept.value = !formControls.accept.value
        if (formControls.accept.value) {
            formControls.accept.error = false;
            formControls.accept.errorMessage = '';
        }
        this.setState({formControls})
    }

    render() {
        return (
            <div>
                <Modal
                    appElement={document.getElementById("root")}
                    isOpen={this.state.open}
                    onRequestClose={this.closeModal}
                    style={this.getStyleModal()}
                    onClose={this.closeModal}>
                    <Card style={{
                        width: '100%',
                        height: '100%',
                        maxHeight: '500px',
                        overflowY: 'auto',
                        marginTop: 0,
                        marginBottom: 0,
                        zIndex: 9999
                    }}>
                        <CardHeader color="giePrimary">
                            <h4>Modifier votre rendez-vous</h4>
                        </CardHeader>
                        <div style={{marginTop: '20px', padding: '5px', textAlign: 'center'}}>
                            <span
                                style={customStyles.text}>Souhaitez-vous reporter votre rendez-vous d’entretien au {this.state.date} {this.getDayMoment()} ?</span>
                        </div>
                        <div style={{padding: '5px', marginLeft: '10%', marginRight: '10%'}}>
                            <GIETextField label="Votre email" required
                                          type="email"
                                          autofocus={true}
                                          onChange={this.changeHandler}
                                          name="email" value={this.state.formControls.email.value}
                                          helperText={this.state.formControls.email.errorMessage}
                                          error={this.state.formControls.email.error}/>
                            <GIETextField label="Votre numéro de mobile"
                                          onChange={this.changeHandler}
                                          name="mobile" value={this.state.formControls.mobile.value}
                                          helperText={this.state.formControls.mobile.errorMessage}
                                          error={this.state.formControls.mobile.error}/>
                            <GIECheckBox name="accept"
                                         checked={this.state.formControls.accept.value === true}
                                         errorText={this.state.formControls.accept.errorMessage}
                                         error={this.state.formControls.accept.error}
                                         emit={this.checkCGV}
                                         label="J’accepte que mes données soient utilisées dans le cadre de la mise à jour de mes coordonnées."/>
                        </div>
                        <div style={{marginBottom: '20px', textAlign: 'center'}}>
                            <Button onClick={this.confirm} variant="contained" color="primary" disabled={this.disableBtn}
                                    component="span"><span style={{fontWeight: 'bold'}}>Confirmer</span></Button>
                        </div>
                    </Card>
                </Modal>
            </div>
        );
    }
}
