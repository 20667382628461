import React from 'react';
import MediaQuery from 'react-responsive';
import ReactGA from 'react-ga';
import {Responsive} from '../../util/responsive';
import GIEContainer from '../../components/GIEContainer/GIEContainer';
import imgUrl from '../../assets/img/kitchen.jpg';
import TitleHomeCompoent from './TitleHome';

import CardHome from './CardHome';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import MiniCardHome from './MiniCardHome';
import ResiliationdHome from './ResiliationHome';
import HomeController from '../../controller/home.controller';
import userInformations from '../../userInformation/user_informations';
import './Home.css';

export default class Home extends React.Component<any, any> {
    homeController: HomeController = new HomeController();

    constructor(props) {
        super(props);
        this.state = {
            contractNumber: "",
            price: 0,
            rdv: '',
            outstanding: 0,
            isCollective: false,
            windowHeight: 0,
            windowWidth: 0
        }
    }

    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search);
        const noContract = userInformations.getNoContract();
        if (noContract == null || noContract.length === 0) {
            window.location.href = "/admin/login"
        } else {
            this.getData()
        }
    }

    getData = () => {
        this.homeController.getHomeInformations().then((data: any) => {
            this.manageData(data)
        }).catch(err => {
            console.log(err)
        })
    }

    manageData = data => {
        this.setState({
            contractNumber: data.contractNumber,
            isCollective: data.isCollective,
            outstanding: data.outstanding,
            price: data.price
        })
        data.rdv === "Aucune Intervention en cours " ? this.setState({rdv: 'Aucun rendez-vous planifié '}) : this.setState({rdv: data.rdv})
    }

    render() {
        return (<div className="content">
                <MediaQuery minWidth={Responsive.getMinDesktop()}>
                    <GIEContainer imgUrl={imgUrl}>
                        <TitleHomeCompoent/>
                        <GridContainer
                            direction="row"
                            justifyContent="center"
                            alignItems="center">
                            <GridItem xs={3}>
                                <CardHome firstTitle="Votre contrat d'entretien"
                                          secondTitle={this.state.contractNumber}
                                          icon="contract"
                                          link="Consulter mes informations"
                                          url="/admin/mes-informations"/>
                            </GridItem>
                            <GridItem xs={3}>
                                <CardHome firstTitle="Votre prochain rendez-vous"
                                          secondTitle={this.state.isCollective ? "Vous ne pouvez pas voir le prochain rendez-vous car vous avez un contrat collectif" : this.state.rdv}
                                          icon="calendar"
                                          link="Modifier mon rendez-vous"
                                          url="/admin/rendez-vous"
                                          rdv={true}
                                          hideLink={this.state.isCollective || this.state.rdv === "Aucun entretien planifié"}/>
                            </GridItem>
                            <GridItem xs={3}>
                                <CardHome firstTitle="Votre solde"
                                          secondTitle={Number(this.state.outstanding) > 0 ? '' + this.state.outstanding + '€ à payer ' : '' + this.state.outstanding + '€ '}
                                          icon="euro"
                                          url="/admin/en-cours"
                                          link="Consulter votre relevé de situation"/>
                            </GridItem>
                        </GridContainer>
                        <GridContainer direction={"row"}
                                       justifyContent="center"
                                       alignItems="center"
                                       style={{padding: '10px 0'}}>
                            <GridItem xs={3}>
                                <MiniCardHome url="/admin/formulaire-d-urgence" title="Signaler une panne"
                                              icon="warning"/>
                            </GridItem>
                            <GridItem xs={3}>
                                <MiniCardHome url="/admin/equipement" tools={true} secondLine=" un équipement"
                                              firstLine="Installer ou remplacer" title=" " icon="tools"/>
                            </GridItem>
                            <GridItem xs={3}>
                                <MiniCardHome url="/admin/documents" title="Mes documents" icon="folder"/>
                            </GridItem>
                        </GridContainer>
                        <ResiliationdHome/>
                    </GIEContainer>
                </MediaQuery>
                <MediaQuery minWidth={Responsive.getMinTablet()} maxWidth={Responsive.getMaxTablet()}>
                    <GIEContainer imgUrl={imgUrl}>
                        <TitleHomeCompoent/>
                        <div style={{margin: '0 100px'}}>
                            <div>
                                <CardHome firstTitle="Votre contrat d'entretien"
                                          secondTitle={this.state.contractNumber}
                                          icon="contract"
                                          link="Consulter mes informations"
                                          url="/admin/mes-informations"/>
                                <CardHome firstTitle="Votre prochain rendez-vous"
                                          secondTitle={this.state.isCollective ? "Vous ne pouvez pas voir le prochain rendez-vous car vous avez un contrat collectif" : this.state.rdv}
                                          icon="calendar"
                                          link="Modifier mon rendez-vous"
                                          url="/admin/rendez-vous"
                                          hideLink={this.state.isCollective || this.state.rdv === "Aucune intervention planifiée"}
                                          rdv={true}/>
                                <CardHome firstTitle="Votre solde"
                                          secondTitle={Number(this.state.outstanding) > 0 ? '' + this.state.outstanding + '€ à payer' : '' + this.state.outstanding + '€'}
                                          icon="euro"
                                          url="/admin/en-cours"
                                          link="Consulter votre relevé de situation"/>
                            </div>
                            <div style={{marginTop: '2rem'}}>
                                <GridContainer direction="row"
                                               alignItems="center">
                                    <GridItem xs={4}>
                                        <MiniCardHome url="/admin/formulaire-d-urgence" title="Signaler une panne"
                                                      icon="warning"/>
                                    </GridItem>
                                    <GridItem xs={4}>
                                        <MiniCardHome url="/admin/equipement" tools={true} secondLine="équipement"
                                                      firstLine="Installer ou remplacer un " title=" " icon="tools"/>
                                    </GridItem>
                                    <GridItem xs={4}>
                                        <MiniCardHome url="/admin/documents" title="Mes documents" icon="folder"/>
                                    </GridItem>
                                </GridContainer>
                            </div>
                        </div>
                        <ResiliationdHome/>
                    </GIEContainer>
                </MediaQuery>
                <MediaQuery maxWidth={Responsive.getMaxMobile()}>
                    <GIEContainer imgUrl={imgUrl}>
                        <TitleHomeCompoent/>
                        <GridContainer
                            direction="row"
                            justifyContent="center"
                            alignItems="center">
                            <GridItem>
                                <CardHome firstTitle="Votre contrat d'entretien"
                                          secondTitle={this.state.contractNumber}
                                          icon="contract"
                                          link="Consulter mes informations"
                                          url="/admin/mes-informations"/>
                            </GridItem>
                            <GridItem>
                                <CardHome firstTitle="Votre prochain rendez-vous"
                                          secondTitle={this.state.isCollective ? "Vous ne pouvez pas voir le prochain rendez-vous car vous avez un contrat collectif" : this.state.rdv}
                                          icon="calendar"
                                          link="Modifier mon rendez-vous"
                                          hideLink={this.state.isCollective || this.state.rdv === "Aucune intervention planifiée"}
                                          url="/admin/rendez-vous"
                                          rdv={true}/>
                            </GridItem>
                            <GridItem>
                                <CardHome firstTitle="Votre solde"
                                          secondTitle={Number(this.state.outstanding) > 0 ? '' + this.state.outstanding + '€  à payer' : '' + this.state.outstanding + '€'}
                                          icon="euro"
                                          url="/admin/en-cours"
                                          link="Consulter votre relevé de  situation"/>
                            </GridItem>
                        </GridContainer>
                        <div style={{height: '0px'}}></div>
                        <GridContainer direction="column"
                                       justifyContent="center"
                                       alignItems="center">
                            <GridItem>
                                <MiniCardHome url="/admin/formulaire-d-urgence" title="Signaler une panne"
                                              icon="warning"/>
                            </GridItem>
                            <GridItem>
                                <MiniCardHome url="/admin/equipement" tools={true} secondLine=""
                                              firstLine="Installer ou remplacer un équipement" title=" " icon="tools"/>
                            </GridItem>
                            <GridItem>
                                <MiniCardHome url="/admin/documents" title="Mes documents" icon="folder"/>
                            </GridItem>
                        </GridContainer>
                        <ResiliationdHome/>
                    </GIEContainer>
                </MediaQuery>
            </div>);
    }
}
