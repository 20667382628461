import React from 'react';
import MediaQuery from 'react-responsive';
import {Responsive} from '../../util/responsive';
import './Home.css';

const customStyles = {
    resiliationDiv: {
        display: 'flex',
        justifyContent: 'center',
        margin: 'auto',
        marginTop: '40px'


    },
    resiliationSpan: {
        color: '#626886',
        fontFamily: 'Hind Vadodara'
    },
    link: {
        color: '#9297B0',
        "&:hover": {
            background: "#efefef"
        }
    }
};
export default class ResiliationHome extends React.Component<any, any> {

    render() {
        return (
            <div>
                <MediaQuery minWidth={Responsive.getMinDesktop()}>
                    <div style={customStyles.resiliationDiv}>
                        <span style={customStyles.resiliationSpan}>Vous souhaitez résilier votre contrat ?</span>
                        <a style={{
                            fontFamily: 'Hind Vadodara',
                            textDecorationLine: 'underline',
                            color: '#253A9F',
                            marginLeft: '0.3vw'
                        }}
                           href="https://gie-sa.fr/services-en-ligne/je-resilie-mon-contrat/">Cliquez ici</a>
                    </div>
                </MediaQuery>
                <MediaQuery minWidth={Responsive.getMinTablet()} maxWidth={Responsive.getMaxTablet()}>
                    <div style={{...customStyles.resiliationDiv, fontSize: '18px'}}>
                        <span style={customStyles.resiliationSpan}>Vous souhaitez résilier votre contrat ?</span>
                        <a style={{
                            fontFamily: 'Hind Vadodara',
                            textDecorationLine: 'underline',
                            color: '#253A9F',
                            marginLeft: '0.3vw'
                        }}
                           href="https://gie-sa.fr/services-en-ligne/je-resilie-mon-contrat/">Cliquez ici</a>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={Responsive.getMaxMobile()}>
                    <div style={{
                        ...customStyles.resiliationDiv,
                        flexDirection: 'column',
                        alignItems: 'center',
                        fontSize: '16px'
                    }}>
                        <span style={customStyles.resiliationSpan}>Vous souhaitez résilier votre contrat ?</span>
                        <a style={{
                            fontFamily: 'Hind Vadodara',
                            textDecorationLine: 'underline',
                            color: '#253A9F',
                            marginLeft: '0.3vw'
                        }}
                           href="https://gie-sa.fr/services-en-ligne/je-resilie-mon-contrat/">Cliquez ici</a>
                    </div>
                </MediaQuery>
            </div>
        );
    }
}
