import React from 'react';

const customStyles = {
    content: {
        width: '50vw',
        backgroundColor: 'white',
        marginBottom: '20px',
        padding: '20px'
    },
    span: {
        fontfamily: 'Hind Vadodara',
        fontStyle: 'normal',
        textAlign: 'justify'

    } as React.CSSProperties
}
export default class BankTitleCard extends React.Component<any, any> {

    render() {
        return (
            <div>
                {this.props.step === 2 ? null : (
                    <div style={{ ...customStyles.content, width: this.props.width }}>
                        <div style={{ display: 'flex', color: '#253A9F' }}>
                            <span style={{ ...customStyles.span, fontWeight: 600 }}>
                                Le prélèvement automatique sera effectué une fois par an et correspondra au montant total annuel du contrat d’entretien.
                        </span>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
