import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import {FormControlLabel} from '@material-ui/core';
import MediaQuery from 'react-responsive';
import {Responsive} from '../../util/responsive';


class GIECheckBox extends React.Component<any, any> {
    state = {
        checked: true
    };

    handleChange = event => {
        this.props.emit(event)
    };

    download = (ev) => {
        ev.preventDefault()
        if (this.props.download) {
            this.props.download();
        }
    }

    render() {
        return (
            <div>
                <MediaQuery minWidth={Responsive.getMinDesktop()}>
                    <div style={{backgroundColor: this.props.error ? '#fff0f0' : 'white'}}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    id="checkbox"
                                    name={this.props.name}
                                    checked={this.props.checked}
                                    onChange={(event) => this.handleChange(event)}
                                    style={{
                                        color: "#253A9F"
                                    }}
                                />
                            }
                            label={<span style={{
                                color: this.props.isGrey ? "#626886" : '#202956',
                                fontSize: this.props.isGrey ? '12px' : ''
                            }}>{this.props.label}
                                <a href="!#" onClick={this.download}>{this.props.pdf}</a>
                        </span>}
                        />
                        <div>
                            {this.props.error ? (<div style={{marginLeft: '3.5%'}}>
                                <span style={{color: '#D11149', fontSize: '8px'}}>{this.props.errorText}</span>
                            </div>) : null}
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery minWidth={Responsive.getMinTablet()} maxWidth={Responsive.getMaxTablet()}>
                    <div style={{backgroundColor: this.props.error ? '#fff0f0' : 'white'}}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    id="checkbox"
                                    name={this.props.name}
                                    checked={this.props.checked}
                                    onChange={(event) => this.handleChange(event)}
                                    style={{
                                        color: "#253A9F"
                                    }}
                                />
                            }
                            label={<span style={{color: '#202956', fontSize: '10px'}}>
                                {this.props.label}
                                <a href="!#" onClick={this.download}>{this.props.pdf}</a>
                        </span>}
                        />
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={Responsive.getMaxMobile()}>
                    <div style={{backgroundColor: this.props.error ? '#fff0f0' : 'white'}}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    id="checkbox"
                                    name={this.props.name}
                                    checked={this.props.checked}
                                    onChange={(event) => this.handleChange(event)}
                                    style={{
                                        color: "#253A9F"
                                    }}
                                />
                            }
                            label={<span style={{fontSize: '9px', color: '#202956'}}>
                                {this.props.label}
                                <a href="!#" onClick={this.download}>{this.props.pdf}</a>
                        </span>}
                        />
                    </div>
                </MediaQuery>
            </div>
        );
    }
}

export default (GIECheckBox);
