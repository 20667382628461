export class MyInformationsUtil {

    static getStyle = () => {
        return {
            text: {
                color: '#202956',
                borderRadius: '2px',
                marginLeft: '-0vw',
                fontFamily: 'Hind Vadodara',
                fontSize: '1vw',
            } as React.CSSProperties,
            firstText: {
                color: '#253A9F',
                borderRadius: '2px',
                fontSize: '1.1vw',
                fontWeight: 600,
                textTransform: 'uppercase',
                fontFamily: 'Hind Vadodara',
                textAlign: 'center',
                // marginTop:'10vh'
            } as React.CSSProperties,
            secondText: {
                color: '#202956',
                fontSize: '1.8vw',
                fontWeight: 600,
                fontFamily: 'Hind Vadodara',
                textAlign: 'right',
            } as React.CSSProperties,
        };
    }

}
