import React from 'react';
import ReactGA from 'react-ga';
import GIECard from '../../components/GIECard/GieCard';
import GridItem from "../../components/Grid/GridItem.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";

const customStyles = {
    content: {
        backgroundColor: '#202956',
        height: '100%',
        minHeight: '100vh',
        display: 'flex',
        paddingBottom: '10%'
    },
    title: {
        fontFamily: 'Hind Vadodara',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '28px',
        lineHeight: '135%',
        color: '#202956',
        textAlign: 'center'
    } as React.CSSProperties
};

export default class NotFound extends React.Component<any, any> {

    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }

    render() {
        return (
            <div className="content">
                <div style={customStyles.content}>
                    <div style={{margin: 'auto', marginTop: '10%', position: "relative"}}>
                        <GIECard width="410px" height="100%">
                            <div style={{width: '100%', minHeight: '30vh', padding: '20% 10% 5%'}}>
                                <GridContainer direction="row"
                                               justifyContent="flex-start"
                                               alignItems="flex-start">
                                    <GridItem xs={12}>
                                        <div>
                                            <h2 style={customStyles.title}>404 erreur </h2>
                                        </div>
                                    </GridItem>
                                </GridContainer>
                            </div>
                        </GIECard>
                    </div>
                </div>
            </div>
        );
    }
}
