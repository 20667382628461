/* istanbul ignore file */

import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import GIECard from '../../components/GIECard/GieCard';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import GIEButton from '../../components/GIEButton/GieButton';

const customStyles = {
    content: {
        marginTop: '0vh',
        marginBottom: '10vh',
        display: 'flex',
        marginLeft: '5vw'
    },
    card: {
        // width:'50vw',
        padding: 10,
        display: 'flex',
        marginLeft: '1vh',
        marginRight: '1vh',
        border: '2px solid #9297b0',
        //height:'35vh',
        marginBottom: '5vh'
    },
    cardMobile: {
        //width:'70vw',
        padding: 10,
        display: 'flex',
        marginLeft: '1vh',
        marginRight: '1vh',
        border: '2px solid #9297b0',
        //height:'35vh',
        marginBottom: '5vh'
    },

    radioLabel: {
        marginLeft: '15vw',
        color: '#253A9F',
        fontWeight: 'bold'
    } as React.CSSProperties,
    span: {
        color: '#9297b0',
    } as React.CSSProperties,
    spanChecked: {
        color: 'white',
        marginTop: '-0vh'
    } as React.CSSProperties,
    li: {
        listStyleType: 'none',
        display: 'flex',
        marginTop: '2.5vh'
    } as React.CSSProperties,
    title: {
        fontFamily: 'Hind Vadodara',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '20px',
        lineHeight: '130%',
    } as React.CSSProperties,
    subTitle: {
        fontFamily: 'Hind Vadodara',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '135%',
    } as React.CSSProperties,
    previous: {
        color: '#009550',
        borderRadius: '2px',
        bacgroundColor: 'white'
    },
    spanGreen: {
        color: '#009550',
        textTransform: 'none',
        fontSize: '16px',
        fontFamily: 'Hind Vadodara'

    } as React.CSSProperties,
    greenButton: {
        color: 'white',
        textTransform: 'none',
        fontFamily: 'Hind Vadodara'
    } as React.CSSProperties
};

export default class Offer extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            value: '',
            securityColor: '#009550',
            comfortColor: '#009550',
            tranquillityColor: '#009550'
        }
    }
    handleChange = (event) => {
        this.setState({ value: event.target.value })
    }
    check = (value) => {
        switch (value) {
            case 'security': {
                this.setState({
                    securityColor: 'white',
                    comfortColor: '#009550',
                    tranquillityColor: '#009550'
                })
                break;
            }
            case 'comfort': {
                this.setState({
                    securityColor: '#009550',
                    comfortColor: 'white',
                    tranquillityColor: '#009550'
                })
                break;
            }
            case 'tranquility': {
                this.setState({
                    securityColor: '#009550',
                    comfortColor: '#009550',
                    tranquillityColor: 'white'
                })
                break;
            }
            default: {
                break;
            }
        }
        this.setState({ value })
    }
    next = () => {
        if (this.state.value.length > 0) {
            this.props.next(this.state.value);
        }
    }

    getColor=(type:string)=>{
        return this.state.value===type? 'white' : '#253A9F'
    }
    getSpan=(type:string)=>{
       return  this.state.value ===type ? customStyles.spanChecked : customStyles.span
    }

    render() {
        return (
            <div >
                    <GIECard width={this.props.width} height={'100%'}>
                        <GridContainer direction={"column"}
                            justifyContent="center"
                            alignItems="stretch"
                            style={{ padding: '40px 40px' }}
                        >
                            <GridItem >
                                <div onClick={() => this.check('security')} style={{ ...customStyles.card, padding: '20px 30px', flexDirection: this.props.width === '100vw' ? 'column' : 'row', backgroundColor: this.state.value === 'security' ? '#009550' : 'white' }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                                        <Checkbox
                                            icon={<CircleUnchecked />}
                                            checkedIcon={<CircleCheckedFilled style={{ fill: '#74e9a1' }} />}
                                            onChange={() => this.check('security')}
                                            checked={this.state.value === 'security'}
                                        />

                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <span style={{ ...customStyles.title, color:this.getColor('security' )}}>Offre sécurité</span>
                                        </div>
                                    </div>
                                    <ul>
                                        <li style={customStyles.li}><KeyboardArrowRightIcon style={{ fill: this.state.securityColor }} /><span style={this.getSpan( 'security')}>Une visite d’entretien </span></li>
                                        <li style={customStyles.li}><KeyboardArrowRightIcon style={{ fill: this.state.securityColor }} /><span  style={this.getSpan( 'security')}>Réglage de l’appareil  </span></li>
                                        <li style={customStyles.li}><KeyboardArrowRightIcon style={{ fill: this.state.securityColor }} /><span  style={this.getSpan( 'security')}>Dépannage 7j/7 de 7h30 à 20h et réparations facturés en sus </span></li>
                                    </ul>
                                </div>
                            </GridItem>
                            <GridItem >
                                <div onClick={() => this.check('comfort')} style={{ ...customStyles.card, padding: '20px 30px', flexDirection: this.props.width === '100vw' ? 'column' : 'row', backgroundColor: this.state.value === 'comfort' ? '#009550' : 'white' }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                                        <Checkbox
                                            icon={<CircleUnchecked />}
                                            checkedIcon={<CircleCheckedFilled style={{ fill: '#74e9a1' }} />}
                                            onChange={() => this.check('comfort')}
                                            checked={this.state.value === 'comfort'}
                                        />

                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <span style={{ ...customStyles.title, color:this.getColor('comfort' )  }}>Offre confort</span>
                                        </div>
                                    </div>
                                    <ul>
                                        <li style={customStyles.li}><KeyboardArrowRightIcon style={{ fill: this.state.comfortColor }} /><span style={this.getSpan( 'comfort')}>Une visite d’entretien </span></li>
                                        <li style={customStyles.li}><KeyboardArrowRightIcon style={{ fill: this.state.comfortColor }} /><span style={this.getSpan( 'comfort')}>Réglage de l’appareil  </span></li>
                                        <li style={customStyles.li}><KeyboardArrowRightIcon style={{ fill: this.state.comfortColor }} /><span style={this.getSpan( 'comfort')}>Accès gratuit au dépannage 7j/7 de 7h30 à 20h </span></li>
                                        <li style={customStyles.li} ><KeyboardArrowRightIcon style={{ fill: this.state.comfortColor }} /><span style={this.getSpan( 'comfort')}>Réparations facturées en sus </span></li>
                                    </ul>
                                </div>
                            </GridItem>
                            <GridItem >
                                <div onClick={() => this.check('tranquility')} style={{ ...customStyles.card, padding: '20px 30px', flexDirection: this.props.width === '100vw' ? 'column' : 'row', backgroundColor: this.state.value === 'tranquility' ? '#009550' : 'white' }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                                        <Checkbox
                                            icon={<CircleUnchecked />}
                                            checkedIcon={<CircleCheckedFilled style={{ fill: '#74e9a1' }} />}
                                            onChange={() => this.check('tranquility')}
                                            checked={this.state.value === 'tranquility'}
                                        />

                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <span style={{ ...customStyles.title, color:this.getColor('tranquility' )}}>Offre tranquillité</span>
                                        </div>
                                    </div>
                                    <ul>
                                        <li style={customStyles.li}><KeyboardArrowRightIcon style={{ fill: this.state.tranquillityColor }} /><span style={this.getSpan( 'tranquility')}>Une visite d’entretien </span></li>
                                        <li style={customStyles.li}><KeyboardArrowRightIcon style={{ fill: this.state.tranquillityColor }} /><span  style={this.getSpan( 'tranquility')}>Réglage de l’appareil  </span></li>
                                        <li style={customStyles.li}><KeyboardArrowRightIcon style={{ fill: this.state.tranquillityColor }} /><span  style={this.getSpan( 'tranquility')}>Accès gratuit au dépannage 7j/7 de 7h30 à 20h </span></li>
                                        <li style={customStyles.li} ><KeyboardArrowRightIcon style={{ fill: this.state.tranquillityColor }} /><span style={this.getSpan( 'tranquility')}>Remplacement gratuit des pièces nécessaires au bon fonctionnement de l’appareil</span></li>
                                        <li style={customStyles.li} ><span style={this.state.value === 'tranquility' ? customStyles.spanChecked : customStyles.span}>* Cette offre est réservée aux chaudières de moins de deux ans et limitée à 5 ans de contrat.</span></li>

                                    </ul>
                                </div>
                            </GridItem>
                        </GridContainer>
                        <GridContainer direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            style={{ paddingBottom: '20px' }}
                        >
                            <GridItem>
                                <div style={{ marginLeft: '40px' }}>
                                    <GIEButton style={{ border: '1px solid #009550', padding: '12px 20px' }} onClick={this.props.previous}><span style={customStyles.spanGreen}>Précédent</span> </GIEButton>
                                </div>
                            </GridItem>
                            <GridItem>
                                <div style={{ marginRight: '40px' }}>
                                    <GIEButton onClick={this.next} green={true} style={{ padding: '12px 30px' }}><span style={{ ...customStyles.greenButton, fontSize: '16px' }}>Suivant</span></GIEButton>
                                </div>
                            </GridItem>
                        </GridContainer>
                    </GIECard>


            </div>
        );
    }
}
