import React from 'react';
import ReactGA from 'react-ga';
import GIECard from '../../components/GIECard/GieCard';
import GridItem from "../../components/Grid/GridItem.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import LoginController from '../../controller/login.controller';
import GIEIcon from '../../components/GIEIcon/GIEIcon';

const customStyles = {
    content: {
        backgroundColor: '#202956',
        height: '100%',
        minHeight: '100vh',
        display: 'flex',
        paddingBottom: '10%'
    },
    title: {
        fontFamily: 'Hind Vadodara',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '28px',
        lineHeight: '135%',
        color: '#202956',
        textAlign: 'center'
    } as React.CSSProperties,
    login: {
        fontFamily: 'Hind Vadodara',
        fontStyle: 'normal',
        fontSize: '18px',
        lineHeight: '140%',
        color: '#253A9F'

    } as React.CSSProperties,
    loginMobile: {
        fontFamily: 'Hind Vadodara',
        fontStyle: 'normal',
        fontSize: '18px',
        lineHeight: '140%',
        color: '#253A9F',
        marginLeft: '20%'

    } as React.CSSProperties,
    loginTablet: {
        fontFamily: 'Hind Vadodara',
        fontStyle: 'normal',
        fontSize: '24px',
        lineHeight: '140%',
        color: '#253A9F',
        marginLeft: '32%'

    } as React.CSSProperties,
    text: {
        fontFamily: 'Hind Vadodara',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '135%',
        color: '#202956'
    }
};


export default class VerifyEMail extends React.Component<any, any> {
    loginController: LoginController = new LoginController();

    constructor(props) {
        super(props);
        this.state = {
            verify: false
        }
    }

    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search);

        const token = window.location.href.split('/')[window.location.href.split('/').length - 1]
        const noContract = window.location.href.split('/')[window.location.href.split('/').length - 2]
        const request = {
            noContract,
            token
        }
        this.loginController.verifyMail(request).then(data => {
            this.setState({verify: true})
        }).catch(err => {
            console.log(err)
        })
    }

    render() {
        return (
            <div className="content">
                <div style={customStyles.content}>
                    <div style={{margin: 'auto', marginTop: '10%', position: "relative"}}>
                        <GIECard width="410px" height="100%">
                            <div style={{width: '100%', minHeight: '30vh', padding: '10% 10% 5%'}}>
                                <GridContainer direction="row"
                                               justifyContent="flex-start"
                                               alignItems="flex-start">
                                    <GridItem xs={12}>
                                        <div>
                                            <h2 style={customStyles.title}>Votre compte est activé </h2>
                                        </div>
                                    </GridItem>
                                    {this.state.verify === false ? (<GridItem xs={12}>
                                        <div style={{display: 'flex'}}>
                                            <span style={{margin: 'auto'}}> Votre email n'est pas vérifié</span>
                                        </div>
                                    </GridItem>) : (
                                        <GridItem xs={12}>
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center'
                                            }}>
                                                <GIEIcon circle height={'15vw'} icon="mail"/>

                                                <span style={{...customStyles.text, marginTop: '10%'}}><a
                                                    style={customStyles.login}
                                                    href="/admin/login">Se Connecter</a></span>
                                            </div>
                                        </GridItem>
                                    )}
                                </GridContainer>
                            </div>
                        </GIECard>
                    </div>
                </div>
            </div>
        );
    }
}
