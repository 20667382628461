import AuthGuard from './AuthGuard';
import userInformations from '../userInformation/user_informations';
import {Route} from 'react-router-dom';

const RootGuard = ({ role, ...rest }) => {
    const userRole = userInformations.getRole();
    if (role?.length && userRole) {
        return <AuthGuard role={role} userRole={userRole}>
            <Route {...rest}/>
        </AuthGuard>;
    } else {
        return <Route {...rest}/>
    }
};

export default RootGuard;
