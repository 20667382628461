import React from 'react';

const customStyles = {
    content: {
        width: '50vw',
        backgroundColor: 'white',
        marginBottom: '20px',
        padding: '20px'
    },
    span: {
        fontfamily: 'Hind Vadodara',
        fontStyle: 'normal',
        textAlign: 'justify'

    } as React.CSSProperties
}

export default class CardWitTitleForm extends React.Component<any, any> {

    render() {
        return (
            <div>
                {this.props.step === 2 ? null : (
                    <div style={{...customStyles.content, width: this.props.width}}>
                        <div style={{display: 'flex', color: '#253A9F'}}>
                        <span style={{...customStyles.span, fontWeight: 600}}>
                            Grâce à votre contrat d’entretien, vous bénéficiez d’un accès prioritaire au dépannage pour toujours plus de confort et de sérénité, et ce, toute l’année.
                        </span>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
