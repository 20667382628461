import React from 'react';
import ReactGA from 'react-ga';

import GIECard from '../../components/GIECard/GieCard';
import GridItem from "../../components/Grid/GridItem.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import GIETextField from '../../components/GIETextField/GieTextField';
import GIEButton from '../../components/GIEButton/GieButton';
import {FormValidator} from '../../util/form-validator';
import LoginController from '../../controller/login.controller';
import {StringUtil} from '../../util/string-util';
import userInformations from '../../userInformation/user_informations';

const customStyles = {
    content: {
        backgroundColor: '#202956',
        height: '100%',
        minHeight: '100vh',
        display: 'flex',
        paddingBottom: '10%'
    },
    title: {
        fontFamily: 'Hind Vadodara',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '28px',
        lineHeight: '135%',
        color: '#202956',
        textAlign: 'center'
    } as React.CSSProperties,
    info: {
        fontFamily: 'Hind Vadodara',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '16px',
        marginBottom: '16px'
    } as React.CSSProperties,
    greenButton: {
        color: 'white',
        textTransform: 'none',
        fontSize: 'bold',
        fontFamily: 'Hind Vadodara'
    } as React.CSSProperties
};


export default class LoginTenant extends React.Component<any, any> {
    formValidator: FormValidator = new FormValidator();
    loginController: LoginController = new LoginController();

    constructor(props) {
        super(props);
        this.state = {
            letterRef: {
                value: '',
                error: false,
                errorMessage: ''
            }
        }
        userInformations.removeToken();
    }

    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }

    changeHandler = (event) => {
        const name = event.target.name;
        let value = event.target.value;
        if (name === 'identifier') {
            value = StringUtil.displayCorrectContractNumber(value)
        }
        const error = this.formValidator.returnError(value, name);
        this.setState({
            ...this.state,
            [name]: {
                ...this.state[name],
                value,
                error: error.error,
                errorMessage: error.errorMessage
            }
        });
    }

    checkValidForm = () => {
        let formControls = this.state;
        let isError = false;
        Object.keys(formControls).forEach(name => {
            const error = this.formValidator.returnError(formControls[name].value, name)
            formControls[name].error = error.error;
            formControls[name].errorMessage = error.errorMessage;

            if (error.error === true) {
                isError = true
            }
        });
        this.setState(formControls)
        return isError;
    }

    authenticateTenant = () => {
        if (this.checkValidForm() === false) {
            const request = {
                letterRef: this.state.letterRef.value
            }
            this.loginController.loginTenant(request).then((user: any) => {
                userInformations.setRole('tenant');
                userInformations.setNoContract(user.contract)
                userInformations.setToken(user.access_token)
                window.location.href = "/admin/rendez-vous";
            }).catch(err => {
                this.manageError(err)
            })
        }
    }

    manageError = (err) => {
        const formControls = this.state;
        if (err && err.response && err.response.status === 401) {
            formControls.letterRef.error = true;
            formControls.letterRef.errorMessage = 'Mauvaise référence courrier ou pas d’intervention prévue';
        } else if (err && err.response && err.response.status === 403) {
            formControls.letterRef.error = true;
            formControls.letterRef.errorMessage = "Impossible de modifier cette intervention";
        } else {
            formControls.letterRef.error = true;
            formControls.letterRef.errorMessage = "Une erreur s'est produite";
        }

        this.setState(formControls);
    }

    render() {
        return (
            <div className="content">
                <div style={customStyles.content}>
                    <div style={{margin: 'auto', marginTop: '10%', position: 'relative'}}>
                        <form>
                            <GIECard width="410px" height="100%">
                                <div style={{width: '100%', padding: '10% 10% 5%'}}>
                                    <GridContainer direction="row"
                                                   justifyContent="flex-start"
                                                   alignItems="flex-start">
                                        <GridItem xs={12}>
                                            <h2 style={customStyles.title}>Je modifie mon rendez-vous d’entretien
                                                annuel</h2>
                                        </GridItem>
                                        <GridItem xs={12}>
                                            <div style={customStyles.info}>Ce service est proposé aux occupants d’un
                                                logement sous contrat d’entretien individuel
                                            </div>
                                        </GridItem>
                                        <GridItem xs={12}>
                                            <GIETextField label="Référence de votre intervention"
                                                          required
                                                          autocomplete="off"
                                                          type="text"
                                                          name="letterRef"
                                                          onChange={e => this.changeHandler(e)}
                                                          value={this.state.letterRef.value}
                                                          helperText={this.state.letterRef.errorMessage}
                                                          error={this.state.letterRef.error}
                                                          infoText={"La référence de votre intervention se trouve sur votre courrier de visite annuelle d’entretien (exemple : II000000000)"}/>
                                        </GridItem>
                                        <GridItem xs={12}>
                                            <div style={{marginTop: '4vh', marginLeft: '100px'}}>
                                                <GIEButton onClick={this.authenticateTenant} green={true}
                                                           style={{height: '50px', width: '116px'}}>
                                                    <span style={customStyles.greenButton}>Connexion</span>
                                                </GIEButton>
                                            </div>
                                        </GridItem>
                                    </GridContainer>
                                </div>
                            </GIECard>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}
