import React from 'react';

const customStyles = {
    content: {
        width: '50vw',
        backgroundColor: 'white',
        marginBottom: '20px',
        padding: '20px',
        textAlign: 'center' as 'center'
    },
    span: {
        fontfamily: 'Hind Vadodara',
        fontStyle: 'normal',
        textAlign: 'justify',
    } as React.CSSProperties
}
export default class NoticeForList extends React.Component<any, any> {

    render() {
        return (
            <div>
                {this.props.outstanding !== true ? (
                    <div style={{ ...customStyles.content, width: this.props.width }}>
                        <div style={{ display: 'flex', color: '#253A9F' }}>
                            <span style={{ ...customStyles.span, fontWeight: 600, marginLeft: this.props.isMobile ? '10%' : '25%' }}>
                                {this.props.text}
                            </span>
                        </div>
                    </div> ) : ( null )}
                {this.props.outstanding === true ? (
                    <div style={{ ...customStyles.content, width: this.props.width }}>
                        <div style={{ display: 'flex', color: '#253A9F' }}>
                            <span style={{ ...customStyles.span, fontWeight: 600, marginLeft: '10%', marginRight: '10%' }}>
                                Le temps de traitement des paiements est de 1 à 7 jours. Si vous avez réalisé un paiement au cours de cet intervalle, merci de ne pas tenir compte du montant affiché.
                        </span>
                        </div>
                    </div> ) : ( null )}

            </div>
        );
    }
}
