import React from 'react';
import MediaQuery from 'react-responsive';
import {Responsive} from '../../util/responsive';

const customStyles = {
    content: {
        width: '60vw',
        height: '100%',
        backgroundColor: 'white',
        marginBottom: '-2.2vh',
        padding: '25px'
    },
    span: {
        marginLeft: '1vw',
        fontfamily: 'Hind Vadodara',
        fontStyle: 'normal'
    }
}

export default class CoordinateHeaderForm extends React.Component<any, any> {

    render() {
        return (
            <>
                <MediaQuery minWidth={Responsive.getMinDesktop()}>
                    <div style={customStyles.content}>
                        <div style={{display: 'flex', color: '#9297b0'}}>
                            <span style={{...customStyles.span, fontWeight: this.props.step === 0 ? 600 : 500}}>1- Vos coordonnées</span>
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery minWidth={Responsive.getMinTablet()} maxWidth={Responsive.getMaxTablet()}>
                    <div style={{...customStyles.content, width: '86vw'}}>
                        <div style={{display: 'flex', color: '#9297b0'}}>
                            <span style={{...customStyles.span, fontWeight: this.props.step === 0 ? 600 : 500}}>1- Vos coordonnées</span>
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={Responsive.getMaxMobile()}>
                    <div style={{...customStyles.content, width: '100vw'}}>
                        <div style={{display: 'flex', color: '#9297b0'}}>
                            <span style={{...customStyles.span, fontWeight: this.props.step === 0 ? 600 : 500}}>1- Vos coordonnées</span>
                        </div>
                    </div>
                </MediaQuery>
            </>
        );
    }
}
