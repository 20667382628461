/* istanbul ignore file */
import GenericController from './generic.controller';
import {Quotation} from '../../typing';

export default class QuotationController extends GenericController {

    getQuotation = (): Promise<Quotation[]> => {
        return this.requestGetBack('quotation/quotation-list').then(data => {
            return data
        }).catch(err => {
            console.log(err);
            throw err
        })
    }
}
