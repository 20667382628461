/* istanbul ignore file */
import GenericController from './generic.controller';

export default class LoginController extends GenericController {

    login = (request) => {
        return new Promise((resolve, reject) => {
            this.requestPostBack('account/login', request).then(data => {
                resolve(data)
            }).catch(err => {
                console.log(err);
                reject(err)
            })
        })
    }

    loginTenant = (request) => {
        return new Promise((resolve, reject) => {
            this.requestPostBack('account/tenant/login', request).then(data => {
                resolve(data)
            }).catch(err => {
                console.log(err);
                reject(err)
            })
        })
    }

    verifyMail = (request) => {
        return new Promise((resolve, reject) => {
            this.requestPostBack('/account/verify', request).then(data => {
                resolve(data)
            }).catch((err) => {
                console.log(err)
                reject(err)
            })
        })
    }

    createAccount = (request) => {
        return new Promise((resolve, reject) => {
            this.requestPostBack('/account/save-account', request).then(data => {
                resolve(data)
            }).catch(err => {
                console.log(err)
                reject(err)
            })
        })
    }

    sendTokenToRegeneratePassword = (request) => {
        return new Promise((resolve, reject) => {
            this.requestPostBack('/account/regenerate-token', request).then(data => {
                resolve(data)
            }).catch(err => {
                console.log(err)
                reject(err)
            })
        })
    }

    changePassword = (request) => {
        return new Promise((resolve, reject) => {
            this.requestPostBack('/account/change-password', request).then(data => {
                resolve(data)
            }).catch(err => {
                console.log(err)
                reject(err)
            })
        })
    }
}
