import React from 'react';
import MediaQuery from 'react-responsive';
import {Responsive} from '../../util/responsive';
import GieCard from '../../components/GIECard/GieCard';
import GIEIcon from '../../components/GIEIcon/GIEIcon';
import MaterialIcon from 'material-icons-react';
import GridItem from "../../components/Grid/GridItem.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import './Documents.css';
import GIEIconTablet from '../../components/GIEIcon/GIEIconTablet';

const ua = window.navigator.userAgent;
const isIE = /MSIE|Trident/.test(ua);

const customStyles = {
    content: {
        marginTop: '-1vh'
    },
    firstTitle: {
        color: '#253A9F',
        borderRadius: '2px',
        fontSize: '20px',
        fontWeight: 600,
        fontFamily: 'Hind Vadodara',
        fontStyle: 'normal',
        textAlign: 'center',
        lineHeight: '130%',
        whiteSpace: 'pre-wrap',
        wordBreak: 'keep-all'
        //lineBreak:''
    } as React.CSSProperties,
    fourTitle: {
        display: 'flex',
        alignItems: 'center',
        whiteSpace: 'pre-wrap',
        textAlign: 'center'

    } as React.CSSProperties
};

export default class CardDocument extends React.Component<any, any> {

    constructor(props) {
        super(props);
        this.state = {
            isGreenAnchor: false
        }
    }

    redirect = (endUrl: string) => {
        if (this.props.external === true) {
            window.open(endUrl);
        } else {
            window.location.href = endUrl;
        }
    }

    mouseOver = (isGreen) => {
        this.setState({isGreenAnchor: isGreen})
    }

    render() {
        return (
            <>
                <MediaQuery minWidth={Responsive.getMinDesktop()}>
                    <GieCard width="19vw" height="19vw">
                        <div className="test" onMouseEnter={() => this.mouseOver(true)}
                             onMouseLeave={() => this.mouseOver(false)} onClick={() => this.redirect(this.props.url)}
                             style={{
                                 display: 'block',
                                 cursor: 'pointer',
                                 width: "100%",
                                 height: "100%",
                                 marginLeft: '15px',
                                 paddingTop: '10%',
                                 paddingBottom: '5%'
                             }}>
                            <GridContainer
                                justifyContent="flex-start"
                                alignItems="center"
                                direction="column"
                                style={{width: "100%", height: "100%"}}>
                                <GridItem>
                                    <GIEIcon isMobile={false} circle={true} clasName="icon" icon={this.props.icon}
                                             height={'15vw'}/>
                                </GridItem>
                                <GridItem style={{height: '55%'}}>
                                    {this.props.breakline === true ? (
                                            <div>
                                                <h4 style={{...customStyles.firstTitle, marginTop: '20px'}}>Installer ou
                                                    remplacer </h4>
                                                <br/>
                                                <h4 style={{...customStyles.firstTitle, marginTop: '-35px'}}>un
                                                    équipement</h4>
                                            </div>) :
                                        (<div style={{width: '200px', wordWrap: 'break-word'}}>
                                            <h4 style={{
                                                ...customStyles.firstTitle,
                                                marginTop: '20px'
                                            }}>{this.props.title}</h4>
                                        </div>)}
                                </GridItem>
                                <GridItem>
                                    <div style={{...customStyles.fourTitle}}>
                                        <a style={{
                                            color: this.state.isGreenAnchor ? '#009550' : '#9297B0',
                                            fontFamily: 'Hind Vadodara',
                                            fontWeight: 'bold',
                                            marginLeft: '1vw',
                                            fontSize: '1vw'
                                        }}
                                           href={this.props.url}>{this.props.linkText ? this.props.linkText : 'Accéder au service'}</a>
                                        <div style={{display: 'flex', marginTop: isIE ? '4px' : ''}}>
                                            <MaterialIcon size={20} color="#9297B0" icon="keyboard_arrow_right"/>
                                        </div>
                                    </div>
                                </GridItem>
                            </GridContainer>
                        </div>
                    </GieCard>
                </MediaQuery>
                <MediaQuery minWidth={Responsive.getMinTablet()} maxWidth={Responsive.getMaxTablet()}>
                    <GieCard width="35vw" height="35vw">
                        <div onClick={() => this.redirect(this.props.url)} style={{
                            display: 'block',
                            cursor: 'pointer',
                            width: "100%",
                            height: "100%",
                            marginLeft: '15px',
                            paddingTop: '10%',
                            paddingBottom: '5%'
                        }}>
                            <GridContainer
                                justifyContent="flex-start"
                                alignItems="center"
                                direction="column"
                                style={{width: "100%", height: "100%"}}>
                                <GridItem>
                                    <GIEIconTablet isMobile={true} circle={true} clasName="icon" icon={this.props.icon}
                                                   height={'15vw'} width={'7vw'}/>
                                </GridItem>
                                <GridItem style={{height: '39%'}}>
                                    {this.props.breakline === true ? (
                                            <div>
                                                <h4 style={{...customStyles.firstTitle, marginTop: '20px'}}>installer ou
                                                    remplacer </h4>
                                                <br/>
                                                <h4 style={{...customStyles.firstTitle, marginTop: '-35px'}}>un
                                                    équipement</h4>
                                            </div>) :
                                        (<div>
                                            <h4 style={{
                                                ...customStyles.firstTitle,
                                                marginTop: '20px'
                                            }}>{this.props.title}</h4>
                                        </div>)}
                                </GridItem>
                                <GridItem>
                                    <div style={{...customStyles.fourTitle}}>
                                        <a style={{
                                            fontFamily: 'Hind Vadodara',
                                            fontWeight: 'bold',
                                            marginLeft: '1vw',
                                            fontSize: '16px'
                                        }} href={this.props.url}>Accéder au service</a>
                                        <div style={{display: 'flex'}}>
                                            <MaterialIcon size={20} color="#9297B0" icon="keyboard_arrow_right"/>
                                        </div>
                                    </div>
                                </GridItem>
                            </GridContainer>
                        </div>
                    </GieCard>
                </MediaQuery>
                <MediaQuery maxWidth={Responsive.getMaxMobile()}>
                    <GieCard width="60vw" height="60vw">
                        <div className="test" onClick={() => this.redirect(this.props.url)} style={{
                            display: 'block',
                            cursor: 'pointer',
                            width: "100%",
                            height: "100%",
                            marginLeft: '15px',
                            paddingTop: '10%',
                            paddingBottom: '5%'
                        }}>
                            <GridContainer
                                justifyContent="flex-start"
                                alignItems="center"
                                direction="column"
                                style={{width: "100%", height: "100%"}}>
                                <GridItem>
                                    <GIEIcon isMobile={true} circle={true} className="icon" icon={this.props.icon}/>
                                </GridItem>
                                <GridItem style={{height: '55%'}}>
                                    {this.props.breakline === true ? (
                                            <div>
                                                <h4 style={{...customStyles.firstTitle, marginTop: '7%'}}>installer ou
                                                    remplacer </h4>
                                                <br/>
                                                <h4 style={{...customStyles.firstTitle, marginTop: '-35px'}}>un
                                                    équipement</h4>
                                            </div>) :
                                        (<div>
                                            <h4 style={{
                                                ...customStyles.firstTitle,
                                                marginTop: '7%'
                                            }}>{this.props.title}</h4>
                                        </div>)}
                                </GridItem>
                                <GridItem>
                                    <div style={{...customStyles.fourTitle}}>
                                        <a style={{
                                            fontFamily: 'Hind Vadodara',
                                            fontWeight: 'bold',
                                            marginLeft: '1vw',
                                            fontSize: '16px'
                                        }} href={this.props.url}>Accéder au service</a>
                                        <div style={{display: 'flex'}}>
                                            <MaterialIcon size={20} color="#9297B0" icon="keyboard_arrow_right"/>
                                        </div>
                                    </div>
                                </GridItem>
                            </GridContainer>
                        </div>
                    </GieCard>
                </MediaQuery>
            </>
        );
    }
}
