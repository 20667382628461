import React from 'react';
import MediaQuery from 'react-responsive';
import {Responsive} from '../../util/responsive';
import './informations.css';
import {TitleUtil} from '../../util/title-util';

const customStyles = TitleUtil.getStyle();
export default class TitleInformations extends React.Component<any, any> {

    render() {
        return (
            <>
                <MediaQuery minWidth={Responsive.getMinDesktop()}>
                    <div style={customStyles.content}>
                        <div style={{marginLeft: '-85px'}}>
                            <span><a style={{fontWeight: 550}} href="accueil">Accueil</a><span
                                style={{color: '#009550', fontWeight: 'bold'}}> | </span><a
                                style={customStyles.boldLink} href="mes-informations">Mes informations</a></span>
                        </div>
                        <div style={{marginTop: '90px'}}><span style={customStyles.text}>MES INFORMATIONS</span></div>
                        <div style={{marginTop: '0'}}><span style={customStyles.bigText}>Les informations liées à votre compte</span>
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery minWidth={Responsive.getMinTablet()} maxWidth={Responsive.getMaxTablet()}>
                    <div style={{...customStyles.content, marginBottom: '60px', padding: '0 75px'}}>
                        <div style={{marginLeft: '-60px'}}>
                            <span><a style={{fontWeight: 550}} href="accueil">Accueil</a><span
                                style={{color: '#009550', fontWeight: 'bold'}}> | </span><a
                                style={customStyles.boldLink} href="mes-informations">Mes informations</a></span>
                        </div>
                        <div style={{marginTop: '80px'}}><span style={customStyles.text}>MES INFORMATIONS</span></div>
                        <div style={{marginTop: '0'}}><span style={{...customStyles.bigText, fontSize: '34px'}}>Les informations liées à votre compte</span>
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={Responsive.getMaxMobile()}>
                    <div style={{...customStyles.content, marginBottom: '70px', padding: '0 25px'}}>
                        <div>
                            <span><a style={{fontWeight: 550}} href="accueil">Accueil</a><span
                                style={{color: '#009550', fontWeight: 'bold'}}> | </span><a
                                style={customStyles.boldLink} href="mes-informations">Mes informations</a></span>
                        </div>
                        <div style={{marginTop: '60px'}}><span style={customStyles.text}>MES INFORMATIONS</span></div>
                        <div style={{marginTop: '0'}}><span style={{...customStyles.bigText, fontSize: '26px'}}>Les informations liées à votre compte</span>
                        </div>
                    </div>
                </MediaQuery>
            </>
        );
    }
}
