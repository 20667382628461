import { useEffect } from 'react'
import userInformations from '../userInformation/user_informations';
import jwtDecode from 'jwt-decode';

const AuthGuard = ({ children, role, userRole }) => {
    useEffect(() => {
        const token = userInformations.getToken();
        let expired = false;
        if (token) {
            const decoded: any = jwtDecode(token);
            expired = new Date() >= new Date(decoded.exp * 1000);
        }
        if (!role.includes(userRole) || !token || expired) {
            window.location.href = '/admin/login';
        }
    }, [role, userRole])
    return children
}

export default AuthGuard;
