import React from 'react';
import styled from 'styled-components';
import MediaQuery from 'react-responsive';
import {Responsive} from '../../util/responsive';
import './GIEContainer.css';

const BackGroundImage = styled.div({
    color: 'grey'
});

const ua = window.navigator.userAgent;
const isIE = /MSIE|Trident/.test(ua);
export default class GIEContainer extends React.Component<any, any> {
    props: any;

    constructor(props) {
        super(props);
        this.state = {
            width: "Contrat Tranquilité",
            height: "150vh",
            windowHeight: 0,
            windowWidth: 0
        }
    }

    handleResize = () => {
        this.setState({
            windowHeight: window.innerHeight,
            windowWidth: window.innerWidth
        });
    }

    componentDidMount() {
        this.handleResize();
        window.addEventListener('resize', this.handleResize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize)
    }

    render() {
        const {children} = this.props;
        return (
            <div>
                <MediaQuery minWidth={Responsive.getMinDesktop()}>
                    <div style={{backgroundColor: '#faf8f6'}}>
                        <BackGroundImage className={isIE ? 'imageIE' : 'image'} style={{
                            marginTop: isIE ? '90px' : '',
                            position: 'absolute',
                            minHeight: this.props.form ? '90vh' : '80vh',
                            width: "100vw",
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            backgroundImage: 'url(' + this.props.imgUrl + ')'
                        }}/>
                        <div style={{position: 'relative', top: '0', width: '100%', padding: '30px 0'}}>{children}</div>
                    </div>
                </MediaQuery>
                <MediaQuery minWidth={Responsive.getMinTablet()} maxWidth={Responsive.getMaxTablet()}>
                    <div style={{backgroundColor: '#faf8f6'}}>
                        <BackGroundImage className="image" style={{
                            position: 'absolute',
                            minHeight: this.props.form ? '90vh' : '80vh',
                            width: "100vw",
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            backgroundImage: 'url(' + this.props.imgUrl + ')'
                        }}/>
                        <div style={{position: 'relative', top: '0', width: '100%', padding: '30px 0'}}>{children}</div>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={Responsive.getMaxMobile()}>
                    <div style={{backgroundColor: '#faf8f6'}}>
                        <BackGroundImage className="image" style={{
                            position: 'absolute',
                            minHeight: this.props.form ? '400px' : '100vh',
                            width: "100vw",
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            backgroundImage: 'url(' + this.props.imgUrl + ')'
                        }}/>
                        <div style={{position: 'relative', top: '0', width: '100%', padding: '30px 0'}}>{children}</div>
                    </div>
                </MediaQuery>
            </div>
        );
    }
}
