
import React from "react";

// used for making the prop types of this component
import Sidebar from "react-sidebar";
import IconButton from '@material-ui/core/IconButton';
import GIEIcon from '../../components/GIEIcon/GIEIcon';
import SideBarContent from './SideBarContent';
import { HeaderUtil } from '../../util/header-util';
import imageGIE2 from '../../assets/img/GIE2.jpg';

import './Header.css';
const ua = window.navigator.userAgent;
const isIE = /MSIE|Trident/.test(ua);

const customStyles = HeaderUtil.getStyle();

class HeaderMobile extends React.Component<any,any> {

    constructor(props) {
        super(props);
        this.state = {
                open:false
        }
        this.openSideBar=this.openSideBar.bind(this)
      }


    openSideBar=()=>{
        this.setState({open:!this.state.open})
    }

    goGie=()=>{
        window.location.href="https://gie-sa.fr/"
    }

  render() {
    return (
      <div   style={{...customStyles.header,width:'100vw',maxWidth: '100%',  position: 'relative'}}>
               <div style={customStyles.content}>
                    <div style={customStyles.image} onClick={this.goGie}>
                        <img alt='https://gie-sa.fr/' src={ imageGIE2 } />
                    </div>

                </div>

            <div style={{height:'100vh',marginLeft:isIE?'-100%':'', position:'absolute'}}>
              {this.state.open===true?(  <Sidebar
                    sidebar={<SideBarContent close={this.openSideBar}/>}
                    open={this.state.open}
                    styles={{root: {position: 'static'}}}>
                    <></>
                </Sidebar>):(null)}
            </div>

            <div style={customStyles.hamburger}>
                <IconButton  onClick={this.openSideBar} >
                    <GIEIcon   icon='menu' width={'12px'}/>
                </IconButton>
            </div>
      </div>
    );
  }
}


export default HeaderMobile;
