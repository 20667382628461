// import React...
import React from 'react';
import MediaQuery from 'react-responsive';
import {Responsive} from '../../util/responsive';
import GieCard from '../../components/GIECard/GieCard';
import GIEIcon from '../../components/GIEIcon/GIEIcon';
import GridItem from "../../components/Grid/GridItem.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import GIEIconTablet from '../../components/GIEIcon/GIEIconTablet';

const customStyles = {
    text: {
        color: '#202956',
        borderRadius: '2px',
        marginTop: '20px',
        fontFamily: 'Hind Vadodara',
        fontSize: '1vw',
        textAlign: 'center'
    } as React.CSSProperties,
    firstText: {
        color: '#253A9F',
        borderRadius: '2px',
        fontSize: '1.1vw',
        fontWeight: 600,
        textTransform: 'uppercase',
        fontFamily: 'Hind Vadodara',
        textAlign: 'center'
    } as React.CSSProperties,
    secondText: {
        color: '#202956',
        fontSize: '1.8vw',
        fontWeight: 600,
        fontFamily: 'Hind Vadodara',
        textAlign: 'right'
    } as React.CSSProperties
};

export default class CardBank extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            iban: null
        }
    }

    redirect = (url) => {
        window.location.href = url
    }

    render() {
        return (
            <>
                <MediaQuery minWidth={Responsive.getMinDesktop()}>
                    <GieCard width="23vw" height="23vw">
                        <div style={{
                            display: 'block',
                            width: "100%",
                            height: "100%",
                            marginLeft: '15px',
                            padding: '10% 0 5%'
                        }}>
                            <GridContainer direction="column"
                                           alignItems="center"
                                           spacing={5}
                                           style={{width: "100%", height: "100%"}}>
                                <GridItem style={{height: '30%', marginBottom: '5%'}}>
                                    <GIEIcon isMobile={false} circle={true} clasName="icon" icon={'euro'}
                                             height={'15vw'}/>
                                </GridItem>
                                <GridItem style={{justifyContent: 'center', height: '60%'}}>
                                    <div style={{display: 'flex', flexDirection: 'column'}}>
                                        <div style={{margin: 'auto', marginLeft: '0%', width: '100%'}}>
                                            <span style={customStyles.firstText}>Vos coordonnées bancaires</span>
                                        </div>
                                        <div style={{display: 'flex', margin: 'auto', width: '180px'}}>
                                            {this.props.bankInformations == null ? (
                                                <span style={customStyles.text}>
                                                Vous n’avez pas de coordonnées bancaires renseignées.
                                        </span>) : (
                                                <span style={customStyles.text}>
                                                {this.props.bankInformations.iban}
                                            </span>
                                            )}
                                        </div>
                                    </div>
                                </GridItem>
                            </GridContainer>
                        </div>
                    </GieCard>
                </MediaQuery>
                <MediaQuery minWidth={Responsive.getMinTablet()} maxWidth={Responsive.getMaxTablet()}>
                    <GieCard width="75vw" height="23vw">
                        <div style={{display: 'flex', width: "100%", height: "100%", padding: '5% 10%'}}>
                            <GridContainer direction="column"
                                           alignItems="flex-start"
                                           justifyContent="center"
                                           spacing={2}
                                           style={{width: "100%", height: "100%"}}>
                                <GridItem>
                                    <GIEIconTablet isMobile={true} circle={true} clasName="icon" icon={'euro'}
                                                   height={'15vw'} width={'7vw'}/>
                                </GridItem>
                                <GridItem style={{justifyContent: 'flex-start', marginLeft: '-45px'}}>
                                    <div style={{marginLeft: '20%'}}>
                                        <div>
                                            <span style={{...customStyles.firstText, fontSize: '14px'}}>Vos coordonnées bancaires</span>
                                        </div>
                                        <div>
                                            {this.props.bankInformations == null ? (
                                                <span style={{...customStyles.text, fontSize: '16px'}}>
                                                Vous n’avez pas de coordonnées bancaires renseignées.
                                        </span>) : (
                                                <span style={{...customStyles.text, fontSize: '16px'}}>
                                                {this.props.bankInformations.iban}
                                            </span>
                                            )}
                                        </div>
                                    </div>
                                </GridItem>
                            </GridContainer>
                        </div>
                    </GieCard>
                </MediaQuery>
                <MediaQuery maxWidth={Responsive.getMaxMobile()}>
                    <GieCard width="75vw" height="100%">
                        <div style={{
                            display: 'block',
                            width: "100%",
                            minHeight: "60vw",
                            marginLeft: '15px',
                            padding: '10% 0'
                        }}>
                            <GridContainer direction="column"
                                           alignItems="center"
                                           justifyContent="flex-start"
                                           spacing={2}
                                           style={{width: "100%", height: "100%"}}>
                                <GridItem style={{height: '30%', marginBottom: '5%'}}>
                                    <GIEIcon isMobile={true} circle={true} clasName="icon" marginLeft="15px"
                                             marginTop="10px" icon={'euro'} height={'11vw'} width={'10vw'}/>
                                </GridItem>
                                <GridItem style={{justifyContent: 'center', height: '50%'}}>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyItems: 'center',
                                        alignItems: 'center'
                                    }}>
                                        <div style={{textAlign: 'center'}}>
                                            <span style={{...customStyles.firstText, fontSize: '14px'}}>Vos coordonnées bancaires</span>
                                        </div>
                                        <div style={{display: 'flex'}}>
                                            {this.props.bankInformations == null ? (
                                                <span style={{...customStyles.text, fontSize: '16px'}}>
                                                Vous n’avez pas de coordonnées bancaires renseignées.
                                        </span>) : (
                                                <span style={{...customStyles.text, fontSize: '16px'}}>
                                                {this.props.bankInformations.iban}
                                            </span>
                                            )}
                                        </div>
                                    </div>
                                </GridItem>
                            </GridContainer>
                        </div>
                    </GieCard>
                </MediaQuery>
            </>
        );
    }
}
