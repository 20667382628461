/* istanbul ignore file */
import GenericController from './generic.controller';

export default class OutstandingController extends GenericController {
    getOutstandingList = () => {
        return new Promise((resolve, reject) => {
            this.requestGetBack('outstanding/get-outstanding').then(data => {
                resolve(data)
            }).catch(err => {
                console.log(err);
                reject(err)
            })
        })
    }
}
