import React from "react";
import Icon from '@material-ui/core/Icon';
import {IconUtil} from '../../util/icon-util';

import './BigIcon.css';

const ua = window.navigator.userAgent;
const isIE = /MSIE|Trident/.test(ua);

const customStyles = {
    circle: {
        display: 'flex',
        borderRadius: '50%',
        width: '60px',
        height: '60px',
        border: '0.5px dashed #009550',
        marginTop: 0,
    },
    circleMobile: {
        display: 'flex',
        borderRadius: '50%',
        width: '5rem',
        height: '5rem',
        border: '0.5px dashed #009550',
    },
};

class GIEIconTablet extends React.Component<any, any> {

    render() {
        return (
            <div>
                <div style={this.props.circle === true ? customStyles.circleMobile : null}>
                    <Icon style={{margin: 'auto', height: isIE ? '38%' : '50%', width: '2rem'}}>
                        <img style={{marginLeft: isIE ? '0px' : '', height: isIE ? '80%' : '100%'}} alt="icon"
                             src={IconUtil.getIcon(this.props.icon)}/>
                    </Icon>
                </div>
            </div>
        )
    }
}

export default GIEIconTablet;
