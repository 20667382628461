/* istanbul ignore file */
import GenericController from './generic.controller';
import {WebEventRequest} from '../../typing';

export default class EmergencyController extends GenericController {

    sendEmergency = (request: WebEventRequest) => {
        return new Promise((resolve, reject) => {
            this.requestPostBack('emergency/signal-emergency', request).then(data => {
                resolve(data)
            }).catch(err => {
                console.log(err);
                reject(err)
            })
        })
    }
}
