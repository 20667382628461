/*eslint-disable*/
import React from "react";
import MediaQuery from 'react-responsive'
// used for making the prop types of this component
import GridItem from "../Grid/GridItem.jsx";
import GridContainer from "../Grid/GridContainer.jsx";
import {Responsive} from '../../util/responsive';

const customStyles = {
    text: {
        color: 'white',
        fontSize: '14px',
        fontFamily: 'Hind Vadodara'
    },
    main: {
        paddingTop: '40px'
    },
    mainMobile: {
        paddingTop: '35px'
    },
    minText: {
        color: 'white',
        fontSize: '2vw',
        fontFamily: 'Hind Vadodara'
    },
    minTextMobile: {
        color: 'white',
        fontSize: '2.5vh',
        fontFamily: 'Hind Vadodara'
    }
}

class FooterDown extends React.Component {

    render() {
        return (
            <div>
                {/* same in desktop and tablet*/}
                <MediaQuery minWidth={Responsive.getMinTablet()}>
                    <div id="sonarqubeFix" style={customStyles.main}>
                        <GridContainer
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            spacing={3}>
                            <GridItem xs={6}>
                                <div>
                                    <a style={customStyles.text} href="https://gie-sa.fr/mentions-legales/">Mentions
                                        légales</a>
                                    <a style={{...customStyles.text, marginLeft: '10px'}}
                                       href="https://gie-sa.fr/politique-de-confidentialite/">Confidentialité</a>
                                </div>
                            </GridItem>
                            <GridItem xs={6}>
                                <div style={{display: 'flex', justifyContent: "flex-end"}}>
                                    <span style={customStyles.text}>Tous droits réservés GIE 2020</span>
                                </div>
                            </GridItem>
                        </GridContainer>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={Responsive.getMaxMobile()}>
                    <div style={{marginTop: '50px', width: '400px'}}>
                        <GridContainer
                            direction="column"
                            alignItems="flex-start"
                            justifyContent="center"
                            spacing={10}>
                            <GridItem xs={6}>
                                <div>
                                    <a style={customStyles.text} href="https://gie-sa.fr/mentions-legales/">Mentions
                                        légales</a>
                                    <a style={{...customStyles.text, marginLeft: '10px'}}
                                       href="https://gie-sa.fr/politique-de-confidentialite/">Confidentialité</a>
                                </div>
                            </GridItem>
                            <GridItem xs={6}>
                                <div style={{display: 'flex', marginTop: '100px', justifyContent: "flex-end"}}>
                                    <span style={customStyles.text}>Tous droits réservés GIE 2020</span>
                                </div>
                            </GridItem>
                        </GridContainer>
                    </div>
                </MediaQuery>
            </div>
        );
    }
}

export default FooterDown;
