import React from 'react';
import GieTextField from '../../components/GIETextField/GieTextField';
import {ValidatorForm} from 'react-material-ui-form-validator';
import GridItem from "../../components/Grid/GridItem.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import {FormValidator} from '../../util/form-validator'
import GieCard from '../../components/GIECard/GieCard';
import GIEButton from '../../components/GIEButton/GieButton';
import GIECheckBox from '../../components/GIECheckBox/GieCheckBox';

const customStyles = {
    content: {
        padding: ' 15px 40px'
    },
    inputLabel: {
        color: '#253A9F',
        fontDamily: 'Hind Vadodara'
    },
    textValidator: {width: '100%', marginTop: 10},
    textValidatorMultiLine: {width: '100%', marginTop: 10, height: 50},
    text: {
        fontFamily: 'Hind Vadodara',
        color: '#253A9F',
        borderRadius: '2px',
        fontWeight: 'bold',
        fontSize: '14px',
        lineHeight: '21px',
        display: 'flex',
        alignItems: 'center',
        letterSpacing: '0.04em',
        textTransform: 'uppercase'

    } as React.CSSProperties,
    spanGreen: {
        color: '#009550',
        textTransform: 'none',
        fontFamily: 'Hind Vadodara',
        fontSize: '16px'

    } as React.CSSProperties,
    spanChecked: {
        color: 'white',
        marginTop: '-0vh'
    } as React.CSSProperties,
    greenButton: {
        color: 'white',
        textTransform: 'none',
        fontFamily: 'Hind Vadodara',
        fontSize: '16px'
    } as React.CSSProperties
}

export default class BankFormDetail extends React.Component<any, any> {
    formValidator: FormValidator = new FormValidator();

    constructor(props: any) {
        super(props);
        this.state = {
            isValid: true,
            formControls: {
                iban: {
                    value: '',
                    error: false,
                    errorMessage: ''
                },
                bic: {
                    value: '',
                    error: false,
                    errorMessage: ''
                },
                confidentiality: {
                    value: false,
                    error: false,
                    errorMessage: ''
                }
            }
        }
    }

    checkValidForm = () => {
        let formControls = this.state.formControls;
        let isError = false;
        Object.keys(formControls).forEach(name => {
            const error = this.formValidator.returnError(formControls[name].value, name)
            formControls[name].error = error.error;
            formControls[name].errorMessage = error.errorMessage;
            if (error.error === true) {
                isError = true
            }
        });
        this.setState(formControls)
        return isError;
    }

    emitToParent = () => {
        setTimeout(() => {
            if (this.checkValidForm() === false) {
                this.props.emit(this.state.formControls)
            }
        }, 500);
    }

    change = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        const error = this.formValidator.returnError(value.split(' ').join(''), name);
        this.setState({
            formControls: {
                ...this.state.formControls,
                [name]: {
                    ...this.state.formControls[name],
                    value: value.split(' ').join(''),
                    error: error.error,
                    errorMessage: error.errorMessage
                }
            }
        });
    }

    checkCGV = (event) => {
        let formControls = this.state.formControls;
        formControls.confidentiality.value = !formControls.confidentiality.value
        this.setState({formControls})
    }

    render() {
        return (
            <div>
                <GieCard width={this.props.width} height="100%">
                    <div>
                        <ValidatorForm
                            ref="form"
                            instantValidate={true}
                            onSubmit={ev => console.log(ev)}
                            onError={errors => console.log(errors)}>
                            <GridContainer direction="row"
                                           justifyContent="center"
                                           alignItems="center"
                                           style={{...customStyles.content}}>
                                <GridItem xs={12}>
                                    <GieTextField label="Votre IBAN" required
                                                  onChange={this.change}
                                                  name="iban" value={this.state.formControls.iban.value}
                                                  style={customStyles.textValidator}
                                                  helperText={this.state.formControls.iban.errorMessage}
                                                  error={this.state.formControls.iban.error}/>
                                </GridItem>
                            </GridContainer>
                            <GridContainer direction="row"
                                           justifyContent="flex-start"
                                           alignItems="center"
                                           style={customStyles.content}>
                                <GridItem xs={12}>
                                    <GieTextField label="Votre BIC (8 caractères)" required
                                                  onChange={this.change}
                                                  name="bic" value={this.state.formControls.bic.value}
                                                  style={customStyles.textValidator}
                                                  helperText={this.state.formControls.bic.errorMessage}
                                                  error={this.state.formControls.bic.error}/>
                                </GridItem>
                            </GridContainer>
                            <GridContainer direction="row"
                                           justifyContent="flex-start"
                                           alignItems="flex-start"
                                           style={customStyles.content}>
                                <GridItem xs={12}>
                                    <div style={{marginTop: '10px', marginBottom: '20px'}}>
                                        <GIECheckBox isGrey
                                                     checked={this.state.formControls.confidentiality.value === true}
                                                     name="confidentiality"
                                                     errorText={this.state.formControls.confidentiality.errorMessage}
                                                     error={this.state.formControls.confidentiality.error}
                                                     emit={(event) => this.checkCGV(event)}
                                                     label="En cochant cette case, j’accepte que mes données personnelles
                                                            soient utilisées par GIE à des fins de traitement de ma demande"/>
                                    </div>
                                </GridItem>
                            </GridContainer>
                            <GridContainer direction="row"
                                           justifyContent="space-between"
                                           alignItems="center"
                                           style={{padding: '30px 0'}}>
                                <GridItem>
                                    <div style={{marginLeft: '40px'}}>
                                        <GIEButton style={{border: '1px solid #009550', padding: '12px 20px'}}
                                                   onClick={this.props.previous}><span
                                            style={customStyles.spanGreen}>Précédent</span> </GIEButton>
                                    </div>
                                </GridItem>
                                <GridItem>
                                    <div style={{marginRight: '40px'}}>
                                        <GIEButton onClick={this.emitToParent} green={true}
                                                   style={{padding: '12px 30px'}}><span style={{
                                            ...customStyles.greenButton,
                                            fontSize: '16px'
                                        }}>Suivant</span></GIEButton>
                                    </div>
                                </GridItem>
                            </GridContainer>
                        </ValidatorForm>
                    </div>
                </GieCard>
            </div>
        );
    }
}
