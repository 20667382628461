import userInformations from '../userInformation/user_informations';

export default class ConfigUrl {

    getBaseBackUrl = (): string => {
        return process.env.REACT_APP_BACKEND_URL + '/api/'
    }

    getBaseBackUrlStatic = (): string => {
        return process.env.REACT_APP_BACKEND_URL;

    }

    getBackHeader = (): any => {
        return {'Authorization': 'Bearer ' + userInformations.getToken()};
    }
}
