export class FormValidator {

    checkError = (value): boolean => {
        if (value === true) {
            return false;
        }
        return !value?.length;
    }

    validateEmail = (email: string): boolean => {
        const regex = /\S+@\S+\.\S+/;
        return regex.test(email);
    }

    validateIban = (email: string): boolean => {
        const regex = new RegExp("[a-zA-Z]{2}[0-9]{2}[a-zA-Z0-9]{4}[0-9]{7}([a-zA-Z0-9]?){0,16}");
        return regex.test(email);
    }

    validateBic = (email: string): boolean => {
        const regex = new RegExp('^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$');
        return regex.test(email);
    }

    validateMobilePhone = (mobile: string): boolean => {
        const regex = new RegExp('^((06)|(07))[0-9]{8}$', 'i');
        return regex.test(mobile);
    }

    validatePassword = (password: string): boolean => {
        const regex = new RegExp('^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+!*=<>()-/€]).*$');
        return regex.test(password);
    }

    returnError = (value: string, name: string) => {
        let error = false;
        let errorMessage = '';
        if (this.checkError(value)) {
            error = true;
            errorMessage = 'Ce champ ne doit pas être vide';
        }
        if (name === 'email' && !this.validateEmail(value)) {
            errorMessage = 'Email non valide';
            error = true;
        }
        if (name === 'iban' && !this.validateIban(value)) {
            errorMessage = 'IBAN non valide';
            error = true;
        }
        if (name === 'bic' && !this.validateBic(value)) {
            errorMessage = 'BIC non valide';
            error = true;
        }
        if (name === 'createPassword' && !this.validatePassword(value)) {
            errorMessage = 'Le mot de passe doit contenir 8 caractères, au moins un chiffre et une lettre, une minuscule, une majuscule et un caractère spécial (Les caractères spéciaux acceptés sont : @, #, $, %, ^, &, +, !, *, =, <, >, (, ), -, /, €).';
            error = true;
        }
        if (name === 'mobile' && !this.validateMobilePhone(value)) {
            errorMessage = 'Entrez un numéro de 10 chiffre commençant par 06 ou 07';
            error = true;
        }
        if (name === 'confidentiality' && (!value || value === '')) {
            errorMessage = 'Veuillez valider la clause de confidentialité';
            error = true;
        }
        return {error, errorMessage}
    }
}
