export class HeaderUtil {

    static getStyleHeader = () => {
        return {
            header: {
                top: '0',
                left: '0px',
                display: 'flex',
                background: 'white',
                position: 'sticky',
                zIndex: 11,
            } as React.CSSProperties,
            content: {
                position: 'relative',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                cursor: 'pointer',
                width: '100vw',
                padding: '16px 30px',
                paddingRight: '40px',
            } as React.CSSProperties,
            text: {
                color: 'white',
                fontSize: '7px',
            } as React.CSSProperties,
            bigText: {
                color: 'white',
                fontSize: '10px',
            } as React.CSSProperties,
            title: {
                height: '10vh',
                display: 'flex',
                width: '100vw',
                position: 'absolute',
                marginLeft: '40vw',
            } as React.CSSProperties,
            buttonDiv: {
                display: 'flex',
            } as React.CSSProperties,
            buttonBlue: {
                marginLeft: '20px',
                borderRadius: '1px',
                backgroundColor: '#202956',
                padding: '12px 20px',
                paddingRight: '10px',
            } as React.CSSProperties,
            buttonGreen: {
                margin: '5px 0',
                borderRadius: '2px',
                padding: '12px 10px 12px 20px',
                color: 'white'
            } as React.CSSProperties,
            buttonText: {
                color: 'white',
                fontFamily: 'Hind Vadodara',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: '14px',
                lineHeight: '29px',
                display: 'flex',
                alignItems: 'center',
                textTransform: 'none',
            } as React.CSSProperties,
            buttonGreenBorder: {
                margin: '5px 0',
                borderRadius: '2px',
                padding: '12px 20px',
                color: 'white',
                border: '1px solid #009550'
            } as React.CSSProperties,
            buttonTextBorder: {
                color: '#009550',
                fontFamily: 'Hind Vadodara',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: '16px',
                lineHeight: '29px',
                display: 'flex',
                alignItems: 'center',
                textTransform: 'none',
            } as React.CSSProperties,
            emergencyDiv: {
                display: 'flex',
                alignItems: 'center',
                marginRight: '30px',
                color: '#626886',
                fontFamily: 'Hind Vadodara',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: "14px",
                lineHeight: "21px",
                /* identical to box height */
                letterSpacing: '0.05em',
                textTransform: 'uppercase',
            } as React.CSSProperties,
            image: {
                width: '190px',
                height: '100%',
                display: 'flex',
            } as React.CSSProperties,
            itemBlue: {
                fontFamily: 'Hind Vadodara',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: '16px',
                lineHeight: '143%',
                marginTop: '-8vh',
                /* identical to box height, or 23px */
                display: 'flex',
                alignItems: 'center',
                color: '#253A9F',
            } as React.CSSProperties,
            menuContent: {
                marginTop: '20.5px',
                marginBottom: '20.5px',
            },
        };
    }

    static getStyle = () => {
        return {
            header: {
                top: '0',
                left: '0',
                background: 'white',
                display: 'flex',
                cursor: 'pointer',
            } as React.CSSProperties,
            content: {
                position: 'relative',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                cursor: 'pointer',
                width: '100vw',
                padding: '13px',
            } as React.CSSProperties,
            image: {
                width: '123px',
                height: '100%',
            } as React.CSSProperties,
            hamburger: {
                position: 'absolute',
                right: '0px',
                top: '0',
            } as React.CSSProperties,
        };
    }
}
