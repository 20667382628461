// import React...
import React from 'react';
import MediaQuery from 'react-responsive';
import ReactGA from 'react-ga';
import {Responsive} from '../../util/responsive';
import imgUrl from '../../assets/img/computer.jpg';
import GIEContainer from '../../components/GIEContainer/GIEContainer';
import MyCoordinateFormDetail from '../../components/form/MyCoordinateFormDetail';
import MessageSuccess from '../../components/form/MessageSuccess';
import CoordinatesHeaderForm from './CoordinateHeaderForm';
import TitleScreen from '../../components/TitleScreen/TitleScreen';
import InformationsController from '../../controller/informations.controller';
import {WebEventUtil} from '../../util/web-event-util';
import {UpdateUserInformationRequest, RawUpdateUserInformationRequest, WebEventRequest} from '../../../typing';
import WebEventController from '../../controller/web-event.controller';
import CardWitTitleCoordinateForm from './CardWitTitleCoordinateForm';

export default class MyCoordinateForm extends React.Component<any, any> {
    informationsController: InformationsController = new InformationsController();
    webEventController: WebEventController = new WebEventController();

    constructor(props: any) {
        super(props);
        this.state = {
            coordinateSend: false
        }
    }

    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }

    changeForm = (form) => {
        let coordinate: any = {};
        Object.keys(form).forEach((name, key) => {
            coordinate[name] = form[name].value
        });
        const noContract = coordinate.noContract;
        if (!noContract?.length) {
            window.location.href = "/admin/login"
        } else {
            this.pushData(coordinate, noContract)
        }
    }

    pushData = (coordinate, noContract) => {
        const requestWebEvent: WebEventRequest = {
            noContrat: noContract,
            eventType: "3",
            eventCode: 'CHGADR',
            description: 'Mettre à jour mes coordonnées',
            confirm: '0',
            mail_object: WebEventUtil.getBodyOfEmail(coordinate),
            noClient: null,
            codeSite: null
        }

        const updateUserInformationRequest: UpdateUserInformationRequest = {
            lastName: coordinate.lastName || '',
            email: coordinate.email || '',
            phoneNumber: coordinate.phoneNumber || '',
            adresse: coordinate.adresse || '',
            adresse2: '',
            zipCode: coordinate.zipCode || '',
            city: coordinate.city || '',
            noClient: coordinate.noClient || '',
            noContract: coordinate.noContract || '',
            phoneMobile: coordinate.phoneMobile || '',
            phoneHome: coordinate.phoneHome || '',
            phoneOffice: coordinate.phoneOffice || '',
            shipToCode: null,
            name2: '',
            country: 'FR'
        }

        const request: RawUpdateUserInformationRequest = {
            updateUserInformationRequest: updateUserInformationRequest,
            webEventRequest: requestWebEvent
        }
        // this.webEventController.inserWebEvent(requestWebEvent).then((data)=>{
        //      this.setState({coordinateSend:true})
        // }).catch(err=>{
        //     console.log(err)
        // })
        this.informationsController.changeUserInformations(request).then(data => {
            this.setState({coordinateSend: true})
        }).catch(err => {
            console.log(err)
        })
    }

    render() {
        return (
            <div className="content">
                <GIEContainer height="250vh" formMargin={'-150vh'} form imgUrl={imgUrl}>
                    <TitleScreen hello={true} isForm title="Mettre à jour mes coordonnées" href="coordonées"/>
                    {this.state.coordinateSend === true ? (<MessageSuccess/>) :
                        (
                            <>
                                <MediaQuery minWidth={Responsive.getMinDesktop()}>
                                    <div style={{marginLeft: '20vw'}}>
                                        <CardWitTitleCoordinateForm width="60vw"></CardWitTitleCoordinateForm>
                                        <CoordinatesHeaderForm step={0}/>
                                        <MyCoordinateFormDetail confidentiality changed width="60vw"
                                                                buttonText="Valider" emit={this.changeForm}/>
                                    </div>
                                </MediaQuery>
                                <MediaQuery minWidth={Responsive.getMinTablet()}
                                            maxWidth={Responsive.getMaxTablet()}>
                                    <div style={{marginLeft: '7vw'}}>
                                        <CardWitTitleCoordinateForm width="86vw"></CardWitTitleCoordinateForm>
                                        <CoordinatesHeaderForm step={0}/>
                                        <MyCoordinateFormDetail confidentiality changed width="86vw"
                                                                buttonText="Valider" emit={this.changeForm}/>
                                    </div>
                                </MediaQuery>
                                <MediaQuery maxWidth={Responsive.getMaxMobile()}>
                                    <div>
                                        <CardWitTitleCoordinateForm width="100vw"></CardWitTitleCoordinateForm>
                                        <CoordinatesHeaderForm step={0}/>
                                        <div>
                                            <MyCoordinateFormDetail confidentiality changed width="100vw"
                                                                    buttonText="Valider" emit={this.changeForm}/>
                                        </div>
                                    </div>
                                </MediaQuery>
                            </>
                        )}
                </GIEContainer>
            </div>
        );
    }
}
