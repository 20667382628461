// import React...
import React from 'react';
import MediaQuery from 'react-responsive';
import ReactGA from 'react-ga';
import {Responsive} from '../../util/responsive';
import imgUrl from '../../assets/img/tool_reversed.jpg';
import GIEContainer from '../../components/GIEContainer/GIEContainer';
import EmergencyFormDetail from './EmergencyFormDetail';
import TitleScreen from "../../components/TitleScreen/TitleScreen";
import HeaderForm from './HeaderEmergencyForm';
import EmergencyFormDescription from './EmergencyDescription';
import MessageSuccess from '../../components/form/MessageSuccess';
import CardWithTitleForm from './CardWitTitleForm';
import EmergencyController from '../../controller/emergency.controller';
import {WebEventUtil} from '../../util/web-event-util';
import {I18n} from '../../util/i18n';
import userInformations from '../../userInformation/user_informations';

export default class EmergencyForm extends React.Component<any, any> {

    emergencyController: EmergencyController = new EmergencyController();

    constructor(props: any) {
        super(props);
        this.state = {
            step: 0,
            isSend: false,
            description: '',
            nature: ''
        }
    }

    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }

    setDataStep1 = data => {
        const description = data.description.value;
        const nature = data.nature.value;
        this.setState({description, nature});
        this.next();
    }

    next = () => {
        this.setState({step: this.state.step + 1})
    }

    previous = () => {
        this.setState({step: this.state.step - 1})
    }

    sendData = (form, callback) => {
        let contactDetailEmergency: any = {};
        Object.keys(form).forEach((name, key) => {
            contactDetailEmergency[name] = form[name].value
        });
        const noContract = userInformations.getNoContract();
        if (!noContract?.length) {
            window.location.href = "/admin/login"
        } else {
            contactDetailEmergency.breakDownDescription = this.state.description;
            const i18nFile: any = I18n.getFile();

            const nature = i18nFile[this.state.nature] != null ? i18nFile[this.state.nature] : this.state.nature;
            contactDetailEmergency.nature = nature

            const request = {
                noContrat: noContract,
                eventType: "3",
                eventCode: 'URGENCE',
                description: "Signaler une panne",
                confirm: '0',
                mail_object: WebEventUtil.getBodyOfEmail(contactDetailEmergency),
                noClient: null,
                codeSite: null,
                hour: new Date().getHours(),
                contactDetailEmergency: contactDetailEmergency
            }
            this.emergencyController.sendEmergency(request).then(data => {
                this.next();
            }).catch(err => {
                console.error(err)
            }).finally(() => {
                callback();
            })
        }
    }

    render() {
        return (
            <div className="content">
                <GIEContainer form imgUrl={imgUrl}>
                    <div>
                        <TitleScreen
                            title="Signaler une panne"
                            href="formulaire-d-urgence"
                            isForm
                            hello={true}
                        />
                        <MediaQuery minWidth={Responsive.getMinDesktop()}>
                            <div style={{marginLeft: '20vw'}}>
                                <CardWithTitleForm width="60vw" step={this.state.step}/>
                                {this.state.step < 2 ? (<HeaderForm width="60vw" step={this.state.step}/>) : null}
                                {this.state.step === 0 ? (
                                    <EmergencyFormDescription width="60vw" next={this.setDataStep1}/>) : null}
                                {this.state.step === 1 ? (<EmergencyFormDetail width="60vw" previous={this.previous}
                                                                               emit={this.sendData}/>) : null}
                                {this.state.step === 2 ? (
                                    <div style={{marginLeft: '-20vw'}}><MessageSuccess/></div>) : null}
                            </div>
                        </MediaQuery>
                        <MediaQuery minWidth={Responsive.getMinTablet()} maxWidth={Responsive.getMaxTablet()}>
                            <div style={{marginLeft: '7vw'}}>
                                <CardWithTitleForm width="86vw" step={this.state.step}/>
                                {this.state.step < 2 ? (<HeaderForm width="86vw" step={this.state.step}/>) : null}
                                {this.state.step === 0 ? (
                                    <EmergencyFormDescription width="86vw" next={this.setDataStep1}/>) : null}
                                {this.state.step === 1 ? (<EmergencyFormDetail width="86vw" previous={this.previous}
                                                                               emit={this.sendData}/>) : null}
                                {this.state.step === 2 ? (
                                    <div style={{marginLeft: '-7vw'}}><MessageSuccess/></div>) : null}
                            </div>
                        </MediaQuery>
                        <MediaQuery maxWidth={Responsive.getMaxMobile()}>
                            <div>
                                <CardWithTitleForm width="100vw" step={this.state.step}/>
                                {this.state.step < 2 ? (
                                    <HeaderForm width="100vw" step={this.state.setDataStep1}/>) : null}
                                {this.state.step === 0 ? (
                                    <EmergencyFormDescription width="100vw" next={this.next}/>) : null}
                                {this.state.step === 1 ? (
                                    <EmergencyFormDetail isMobile width="100vw" previous={this.previous}
                                                         emit={this.sendData}/>) : null}
                                {this.state.step === 2 ? (<div><MessageSuccess/></div>) : null}
                            </div>
                        </MediaQuery>
                    </div>
                </GIEContainer>
            </div>
        );
    }
}
