import React from "react";

function WhiteBar({ ...props }) {

  return (
          <div>
              <hr  style={{
                          color: 'white',
                          backgroundColor: 'wihte',
                          height: '1px',
                          borderColor : 'white',
                          width:'100%',
                          opacity: 0.2,
                          margin : '0'
                              }}/>
          </div>
  );
}


export default (WhiteBar);
