import React from 'react';
import MediaQuery from 'react-responsive';
import ReactGA from 'react-ga';
import {Responsive} from '../../util/responsive';
import GIEContainer from '../../components/GIEContainer/GIEContainer';
import imgUrl from '../../assets/img/pen.png';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import CardDocument from '../../components/CardDocument/CardDocument';
import TitleScreen from '../../components/TitleScreen/TitleScreen';

const customStyles = {
  content: {
    // marginTop:'0vh',
    // marginLeft:'-5vw'
  }
};

export default class MyDocument extends React.Component<any, any> {

  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  render() {
    return (
      <div className="content">
        <GIEContainer height='240vh' imgUrl={imgUrl}>
          <TitleScreen
            hello={true}
            isSecondLine={true}
            title="Mes Documents"
            subtitle="Quels documents "
            secondLine="souhaitez-vous consulter ?"
            href="documents"
          />
          <MediaQuery minWidth={Responsive.getMinDesktop()}>
            <div style={customStyles.content}>
              <GridContainer direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={3}
              >
                <GridItem xs={3}>
                  <CardDocument icon='handShake' url='/admin/mes-devis' linkText="Accéder aux documents" title='Mes devis' />
                </GridItem>
                <GridItem xs={3}>
                  <CardDocument icon='euro' url='/admin/mes-factures' linkText="Accéder aux documents" title='Mes factures' />
                </GridItem>
                <GridItem xs={3}>
                  <CardDocument icon='contract' big url='/admin/mes-fiches-d-intervention' linkText="Accéder aux documents" title="Mes fiches d'intervention" />
                </GridItem>
              </GridContainer>
            </div>
          </MediaQuery>
          <MediaQuery minWidth={Responsive.getMinTablet()} maxWidth={Responsive.getMaxTablet()}>
            <div style={customStyles.content}>
              <GridContainer direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={3}
              >
                <GridItem xs={4}>
                  <CardDocument icon='handShake' url='/admin/mes-devis' linkText="Accéder aux documents" title='Mes devis' />
                </GridItem>
                <GridItem xs={4}>
                  <CardDocument icon='euro' url='/admin/mes-factures' linkText="Accéder aux documents" title='Mes factures' />
                </GridItem>
              </GridContainer>
              <GridContainer direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={3}
              >
                <GridItem xs={4}>
                  <CardDocument icon='contract' big url='/admin/mes-fiches-d-intervention' linkText="Accéder aux documents" title="Mes fiches d'intervention" />
                </GridItem>
              </GridContainer>
            </div>
          </MediaQuery>
          <MediaQuery maxWidth={Responsive.getMaxMobile()}>
            <div style={customStyles.content}>
              <GridContainer direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={3}
              >
                <GridItem >
                  <CardDocument icon='handShake' marginLeft={'2.5vw'} url='/admin/mes-devis' linkText="Accéder à mes devis" title='Mes devis' />
                </GridItem>
                <GridItem >
                  <CardDocument icon='euro' marginLeft={'4.5vw'} url='/admin/mes-factures' title='Mes factures' />
                </GridItem>
                <GridItem >
                  <CardDocument icon='contract' marginLeft={'4.5vw'} url='/admin/mes-fiches-d-intervention' linkText="Accéder aux documents" title="Mes fiches d'intervention" />
                </GridItem>
              </GridContainer>
            </div>
          </MediaQuery>
        </GIEContainer>
      </div>
    );
  }
}
