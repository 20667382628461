import React from "react";
import MediaQuery from 'react-responsive';
import MaterialIcon from 'material-icons-react';
import GIEButton from '../GIEButton/GieButton';
import {Responsive} from '../../util/responsive';
import GIEHeaderMobile from './GIEHeaderMobile';
import logo from "../../assets/img/GIE2.svg";
import './Header.css';
import {HeaderUtil} from '../../util/header-util';

const customStyles = HeaderUtil.getStyleHeader()

class GIEHeader extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null
        }
    }

    goService = () => {
        window.location.href = "https://gie-sa.fr/#"
    }

    goMySpace = () => {
        window.location.href = "https://votre-espace.gie-sa.fr/admin/login"
    }

    render() {
        return (
            <div>
                <MediaQuery minWidth={Responsive.getMinDesktop()}>
                    <div style={{...customStyles.header, width: '100vw'}}>
                        <div style={customStyles.content}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <div style={customStyles.image}>
                                    <img alt="https://gie-sa.fr/" src={logo}/>
                                </div>
                                <div>
                                    <ul className="nav-gie" style={{margin: 'auto'}}>
                                        <li>
                                            <a style={{textDecoration: 'none'}}
                                               href={"https://gie-sa.fr/notre-entreprise/"}>Notre Entreprise</a>
                                        </li>
                                        <li>
                                            <a style={{textDecoration: 'none'}}
                                               href={"https://gie-sa.fr/chauffage/"}>Chauffage</a>
                                        </li>
                                        <li>
                                            <a style={{textDecoration: 'none'}}
                                               href={"https://gie-sa.fr/climatisation/"}>Climatisation</a>
                                        </li>
                                        <li>
                                            <a style={{textDecoration: 'none'}}
                                               href={"https://gie-sa.fr/energies-renouvelables/"}>Énergies
                                                Renouvelables</a>
                                        </li>
                                        <li>
                                            <a style={{textDecoration: 'none'}}
                                               href={"https://gie-sa.fr/faq/"}>FAQ</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div style={{...customStyles.buttonDiv, marginLeft: '1vw'}}>
                                <GIEButton onClick={this.goService} aria-controls="service-menu"
                                           aria-haspopup="true" style={{...customStyles.buttonGreen, marginRight: '20px'}} green={true}>
                                        <span style={{
                                            ...customStyles.buttonText,
                                            fontSize: '16px',
                                            marginTop: '-0vh',
                                            marginRight: '5px'
                                        }} className="btn-gie">{'Services en ligne'}</span>
                                    <MaterialIcon color="white" icon="expand_more"/>
                                </GIEButton>
                                <GIEButton onClick={this.goMySpace} style={{...customStyles.buttonGreenBorder}}>
                                        <span style={{
                                            ...customStyles.buttonTextBorder,
                                        }} className="btn-gie">{'Mon espace'}</span>
                                </GIEButton>
                            </div>
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery minWidth={Responsive.getMinTablet()} maxWidth={Responsive.getMaxTablet()}>
                    {/*  <HeaderTablet/>*/}
                    <GIEHeaderMobile/>
                </MediaQuery>
                <MediaQuery maxWidth={Responsive.getMaxMobile()}>
                    <GIEHeaderMobile/>
                </MediaQuery>
            </div>
        );
    }
}


export default GIEHeader;
