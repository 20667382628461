import React from "react";

export class SideBarStyleUtils {

    static getStyle = () => {
        return {
            sideBar: {
                width: '280px',
                left: '0px',
                top: '0px',
                height: '100%',
            } as React.CSSProperties,
            buttonDiv: {
                marginLeft: '37px',
            } as React.CSSProperties,
            buttonBlue: {
                borderRadius: '1px',
                fontWeight: 600,
                backgroundColor: '#202956',
                padding: '12px 20px',
                paddingRight: '15px',
                marginBottom: '30px'
            } as React.CSSProperties,
            buttonGreen: {
                borderRadius: '1px',
                padding: '12px 20px',
                paddingRight: '15px',
                marginBottom: '30px'

            } as React.CSSProperties,
            buttonQuestion: {
                //marginLeft:'20px',
                border: '1px solid #009550',
                borderRadius: '1px',
                backgroundColor: 'transparent',
                padding: '12px 20px',
                color: '#009550',
            } as React.CSSProperties,
            buttonText: {
                color: 'white',
                fontFamily: 'Hind Vadodara',
                fontSize: '16px',
                textTransform: 'none'
            } as React.CSSProperties,
            emergencyDiv: {
                display: 'flex',
                alignItems: 'center',
                marginBottom: '20px',
                marginLeft: '3px',
                color: '#626886',
                fontFamily: 'Hind Vadodara',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: "14px",
                lineHeight: "21px",
                /* identical to box height */
                letterSpacing: '0.05em',
                textTransform: 'uppercase',
            } as React.CSSProperties,
            image: {
                width: '10vw',
                marginTop: '3vh',
                marginLeft: '2vw'
            } as React.CSSProperties,
            blueSpan: {
                fontFamily: 'Hind Vadodara',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: '14px',
                lineHeight: '135%',
                color: '#253A9F',
                marginTop: '23px',
                marginLeft: '37px'
            },
            greySpan: {
                fontFamily: 'Hind Vadodara',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: '14px',
                lineHeight: '135%',
                color: '#9297B0',
                marginLeft: '33px',
                marginBottom: '16px',
                display: 'flex'
            }
        };
    }
}
