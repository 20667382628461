import Cookies from 'universal-cookie';
import jwtDecode from 'jwt-decode';

export class UserInformations {
    cookies: Cookies = new Cookies();
    noContract: string;
    userName: string;
    userNo: string;
    token: string;
    role: 'admin' | 'tenant' | null = null;

    setRole = (role: 'admin' | 'tenant' | null) => {
        this.role = role;
    }

    getRole = (): 'admin' | 'tenant' | null => {
        this.getToken();
        if (this.token && !this.role) {
            this.role = jwtDecode<any>(this.token).userNo ? 'admin' : 'tenant';
        }
        return this.role;
    }

    setNoContract = (noContract: string) => {
        this.noContract = noContract;
    }

    getNoContract = (): string => {
        this.getToken();
        if (this.token && !this.noContract) {
            this.noContract = jwtDecode<any>(this.token).contractNo;
        }
        return this.noContract;
    }

    setUserName = (userName: string) => {
        this.userName = userName;
    }

    getUserName = (): string => {
        let userName = this.userName;
        if (!userName) {
            this.getToken();
            if (this.token) {
                userName = jwtDecode<any>(this.token).userName;
            }
        }
        if (!userName) {
            userName = '';
        }
        return userName;
    }

    getUserNo(): string {
        this.getToken();
        if (!this.userNo && this.token) {
            this.userNo = jwtDecode<any>(this.token).userNo;
        }
        return this.userNo;
    }

    setToken = (token: string) => {
        this.token = token;
        this.cookies.set('token', token, {path: '/'})
    }

    removeToken = () => {
        this.cookies.remove('token', {path: '/'})
    }

    getToken() {
        if (!this.token) {
            this.token = this.cookies.get('token');
        }
        return this.token;
    }
}

const userInformations = new UserInformations();
export default userInformations;
