import React from 'react';
import GIECard from '../../components/GIECard/GieCard';
import GIEIcon from '../../components/GIEIcon/GIEIcon';
import MediaQuery from 'react-responsive';
import {Responsive} from '../../util/responsive';
import GIEIconTablet from '../../components/GIEIcon/GIEIconTablet';

const customStyles = {
    content: {
        backgroundColor: 'white',
        color: '#253A9F'
    }
}
export default class MessageSuccess extends React.Component<any, any> {
    render() {
        return (
            <div>
                <MediaQuery minWidth={Responsive.getMinDesktop()}>
                    <div style={{margin: 'auto', width: '80vw'}}>
                        <GIECard width="80vw" height="50vh" style={customStyles.content}>
                            <div style={{
                                display: 'flex',
                                height: '100%',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                <h4 style={{
                                    marginLeft: '0vw',
                                    color: '#253A9F',
                                    fontWeight: 'bold',
                                    fontSize: '20px',
                                    textAlign: 'center',
                                    padding: '0 40px'
                                }}>Nous avons bien reçu votre demande qui sera traitée dans les meilleurs délais</h4>
                                <div>
                                    <GIEIcon circle height={'15vw'} icon="mail"/>
                                </div>
                            </div>
                        </GIECard>
                    </div>
                </MediaQuery>
                <MediaQuery minWidth={Responsive.getMinTablet()} maxWidth={Responsive.getMaxTablet()}>
                    <div style={{margin: 'auto', width: '80vw'}}>
                        <GIECard width="80vw" height="50vh" style={customStyles.content}>
                            <div style={{
                                display: 'flex',
                                height: '100%',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                <h4 style={{
                                    marginLeft: '0vw',
                                    color: '#253A9F',
                                    fontWeight: 'bold',
                                    fontSize: '1.5vw',
                                    textAlign: 'center',
                                    padding: '0 40px'
                                }}>Nous avons bien reçu votre demande qui sera traitée dans les meilleurs délais</h4>
                                <div>
                                    <GIEIconTablet circle height={'15vw'} width={'7vw'} icon="mail"/>
                                </div>
                            </div>
                        </GIECard>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={Responsive.getMaxMobile()}>
                    <div style={{margin: 'auto', width: '80vw'}}>
                        <GIECard width="80vw" height="59vh" style={customStyles.content}>
                            <div style={{
                                display: 'flex',
                                height: '100%',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                wordBreak: 'break-all'
                            }}>
                                <h4 style={{
                                    marginLeft: '0vw',
                                    color: '#253A9F',
                                    fontWeight: 'bold',
                                    fontSize: '1.5vw',
                                    textAlign: 'center',
                                    padding: '0 40px'
                                }}>Nous avons bien reçu votre demande qui sera traitée dans les meilleurs délais</h4>
                                <div>
                                    <GIEIcon isMobile circle height={'10vw'} marginTop="1.5vh" marginLeft="3vw"
                                             width={'10vw'} icon="mail"/>
                                </div>
                            </div>
                        </GIECard>
                    </div>
                </MediaQuery>
            </div>
        );
    }
}
