// import React...
import React from 'react';
import ReactGA from 'react-ga';

import imgUrl from '../../assets/img/pen.png';
import GIEContainer from '../../components/GIEContainer/GIEContainer';
import GIEList from '../../components/GIEList/GIEList';
import TitleScreenList from '../../components/TitleListeScreen/TitleListScreen';
import OutstandingController from '../../controller/outstanding.controller';
import {DateUtil} from '../../util/date-util';
import GieButton from '../../components/GIEButton/GieButton';
import userInformations from '../../userInformation/user_informations';

const customStyles = {
    buttonText: {
        color: 'white',
        textTransform: 'none',
        fontSize: 'bold',
        fontFamily: 'Hind Vadodara'
    } as React.CSSProperties
};

export default class MyOutstanding extends React.Component<any, any> {
    outstandingController: OutstandingController = new OutstandingController();

    constructor(props) {
        super(props);
        this.state = {
            data: []
        }
    }

    componentDidMount() {
        this.getOutStandingList()
        ReactGA.pageview(window.location.pathname + window.location.search);
    }

    getOutStandingList = () => {
        const noContract = userInformations.getNoContract();
        if (!noContract?.length) {
            window.location.href = "/admin/login"
        } else {
            this.getData()
        }
    }

    getData = () => {
        this.outstandingController.getOutstandingList().then((data: any) => {
            this.manageData(data);
        }).catch(err => {
            console.log(err)
        })
    }

    manageData = (data: any) => {
        const outstandingList = data.map(line => {
            return {
                _id: line._id,
                number: line.documentNo ? line.documentNo : '',
                title: line.description ? line.description : '',
                date: line.postingDate ? DateUtil.convertDateInString(new Date(line.postingDate)) : '',
                amount: line.remainingAmtLCY ? line.remainingAmtLCY : '',
                customerNo: line.customerNo,
                paid: (line.remainingAmtLCY && Number(line.remainingAmtLCY) > 0) ? false : true,
                visible: true,
                downloadType: line.downloadType
            }
        })
        this.setState({data: outstandingList})
    }

    payBill = () => {
        window.location.href = "https://monetico.apayer.fr/gie/factures";
    }

    render() {
        return (
            <div className="content">
                <GIEContainer height="150vh" imgUrl={imgUrl}>
                    <TitleScreenList miniTilte="Consultez le détail de votre solde " href="en-cours"
                                     title="Votre relevé de situation"/>
                    <GIEList data={this.state.data} type={"outstanding"}/>
                    <div style={{textAlign: 'center'}}>
                        {this.state.data.length === 0 || Number(this.state.data[0].amount) <= 0 ? null : (
                            <GieButton onClick={this.payBill} style={{
                                borderRadius: '2px',

                                padding: '12px 20px'
                            }} green>
                                <span style={{...customStyles.buttonText, fontSize: '16px'}}>Payer mon solde</span>
                            </GieButton>)}
                    </div>
                </GIEContainer>
            </div>
        );
    }
}
