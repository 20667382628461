import React from 'react';
import ReactGA from 'react-ga';
import imgUrl from '../../assets/img/pen.png';
import GIEContainer from '../../components/GIEContainer/GIEContainer';
import GIEList from '../../components/GIEList/GIEList';
import TitleScreenList from '../../components/TitleListeScreen/TitleListScreen';
import InterventionController from '../../controller/intervention.controller';
import {DateUtil} from '../../util/date-util';
import userInformations from '../../userInformation/user_informations';

export default class MyWorkOrderForm extends React.Component<any, any> {
    interventionController: InterventionController = new InterventionController();

    constructor(props) {
        super(props);
        this.state = {
            data: []
        }
    }

    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search);
        this.getWorkOrderList();
    }

    getWorkOrderList = () => {
        const noContract = userInformations.getNoContract();
        if (!noContract.length) {
            window.location.href = "/admin/login"
        } else {
            this.getData()
        }

    }

    getData = () => {
        this.interventionController.getInterventionsList().then((data: any) => {
            this.manageData(data);
        }).catch(err => {
            console.log(err)
        })
    }

    manageData = data => {
        const interventionList = data.map(line => {
            return {
                _id: line._id,
                number: line.no ? line.no : '',
                title: line.description ? line.description : '',
                date: line.startingDate ? DateUtil.convertDateInString(new Date(line.startingDate)) : '',
                amount: line.amount ? line.amount : '',
                customerNo: line.customerNo,
                downloadType: "1",
                paid: true,
                visible: line.pdfExist
            }
        })
        this.setState({data: interventionList})
    }

    render() {
        return (
            <div className="content">
                <GIEContainer height="180vh" imgUrl={imgUrl}>
                    <TitleScreenList href="mes-fiches-d-intervention" miniTilte="Mes documents"
                                     title="Mes fiches d'intervention"/>
                    <GIEList data={this.state.data} type={"workOrder"}/>
                </GIEContainer>
            </div>
        );
    }
}
