import React from "react";
import {Route, Switch} from "react-router-dom";
import ReactGA from 'react-ga';
import Footer from "../components/Footer/Footer";
import Sidebar from "../components/Sidebar/Sidebar.jsx";
import Header from '../components/header/Header';
import GIEHeader from '../components/header/GIEHeader';
import FixedPlugin from "../components/FixedPlugin/FixedPlugin.jsx";
import GIEFooter from '../components/Footer/GIEFooter';
import routes from "../routes";
import NotFound from '../views/NotFound/NotFound';
import RootGuard from "../guard/RootGuard";

class Admin extends React.Component<any, any> {
    mainPanel: any;

    constructor(props) {
        super(props);
        this.state = {
            backgroundColor: "white",
            activeColor: "info",
            showSideBar: false
        };

        this.initializeGA();
        this.mainPanel = React.createRef();
    }

    initializeGA = () => {
        switch (process.env.REACT_APP_ENV) {
            case 'PROD': {
                ReactGA.initialize('UA-5503634-35');
                break;
            }
            case 'DEV': {
                ReactGA.initialize('UA-174483965-1');
                break;
            }
            default: {
                ReactGA.initialize('UA-174483965-1', {testMode: true});
                break;
            }
        }
    }

    handleActiveClick = color => {
        this.setState({activeColor: color});
    };

    handleBgClick = color => {
        this.setState({backgroundColor: color});
    };

    showSideBar = () => {
        this.setState({showSideBar: !this.state.showSideBar})
    }

    displayGIE = () => {
        return window.location.pathname.includes('login')
            || window.location.pathname.includes('replanification')
            || window.location.pathname.includes('creer-un-compte')
            || window.location.pathname.includes('verification-email')
            || window.location.pathname.includes('oublie-de-mot-de-passe')
            || window.location.pathname.includes('changer-de-mot-de-passe');
    }

    render() {
        return (
            <div style={{overflowX: 'hidden'}}>
                {this.state.showSideBar === true ? (<Sidebar
                    {...this.props}
                    routes={routes}
                    bgColor={this.state.backgroundColor}
                    activeColor={this.state.activeColor}
                />) : null}
                <div className={this.state.showSideBar === true ? ("main-panel") : ("main-panel-gie")}
                     ref={this.mainPanel}>
                    {this.displayGIE() === true ? (<GIEHeader/>) : (<Header/>)}
                    <Switch>
                        {routes.map((prop, key) => {
                            return (
                                <RootGuard key={key} role={prop.roles}
                                           path={prop.layout + prop.path}
                                           component={prop.component}>
                                </RootGuard>
                            );
                        })}
                        <Route component={NotFound}/>
                    </Switch>
                    {this.displayGIE() === true ? (<GIEFooter/>) : (<Footer fluid={true}/>)}
                </div>
                <FixedPlugin
                    bgColor={this.state.backgroundColor}
                    activeColor={this.state.activeColor}
                    handleActiveClick={this.handleActiveClick}
                    handleBgClick={this.handleBgClick}
                    showSideBar={this.showSideBar}
                />
            </div>
        );
    }
}

export default Admin;
