export class Responsive {

    static getMinDesktop=(number?: boolean)=>{
        return Responsive.getValue(1225, number);
    }

    static getMaxTablet=(number?: boolean)=>{
        return Responsive.getValue(1224, number);
    }

    static getMinTablet=(number?: boolean)=>{
        return Responsive.getValue(768, number);
    }

    static getMaxMobile=(number?: boolean)=>{
        return Responsive.getValue(767, number);
    }

    static getValue = (value: number, number?: boolean): number | string => {
        if (number) {
            return value;
        }
        return value + 'px';
    }
}
