import React from 'react';
import MediaQuery from 'react-responsive';
import ReactGA from 'react-ga';
import {Responsive} from '../../util/responsive';
import imgUrl from '../../assets/img/chair.jpg';
import GIEContainer from '../../components/GIEContainer/GIEContainer';
import MyCoordinateFormDetail from '../../components/form/MyCoordinateFormDetail';
import DownloadPdf from './DownloadPdf';
import BankFormDetail from './BankFormDetail';
import TitleScreen from '../../components/TitleScreen/TitleScreen';
import HeaderBankForm from './HeaderBankForm';
import WebEventController from '../../controller/web-event.controller';
import {WebEventUtil} from '../../util/web-event-util';
import BankTitleCard from './BankTitleCard';

export default class BankForm extends React.Component<any, any> {

    webEventController: WebEventController = new WebEventController();

    constructor(props: any) {
        super(props);
        this.state = {
            dataSend: false,
            bic: '',
            iban: '',
            coordinates: {bic: '', iban: ''},
            step: 0
        }
    }

    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }

    changeForm = (form) => {
        this.setState({dataSend: true})
    }

    next = () => {
        this.setState({step: this.state.step + 1})
    }

    previous = () => {
        this.setState({step: this.state.step - 1})
    }

    changePersonnalCoordinate = (coordinatesData) => {
        let coordinate = {};
        Object.keys(coordinatesData).forEach((name, key) => {
            coordinate[name] = coordinatesData[name].value
        });
        this.setState({coordinate})
        this.next();
    }

    changeBankCoordinates = (event) => {
        let coordinate = this.state.coordinate;
        coordinate.bic = event.bic.value;
        coordinate.iban = event.iban.value;
        this.setState({coordinate});
        const noContract = coordinate.noContract;
        if (!noContract?.length) {
            window.location.href = "/admin/login"
        } else {
            const request = {
                noContrat: noContract,
                eventType: "3",
                eventCode: 'CHGBANK',
                description: 'Souscrire au prélèvement automatique',
                confirm: '0',
                mail_object: WebEventUtil.getBodyOfEmail(coordinate),
                noClient: null,
                codeSite: null
            }
            this.webEventController.insertWebEvent(request).then(data => {
                this.next();
            }).catch(err => {
                console.log(err)
            })
        }
    }

    render() {
        return (
            <div className="content">
                <GIEContainer form imgUrl={imgUrl}>
                    <TitleScreen isForm hello={true} title="Souscrire au prélèvement automatique"
                                 href="coordonnées-bancaires"/>
                    <MediaQuery minWidth={Responsive.getMinDesktop()}>
                        <div style={{marginLeft: '20vw'}}>
                            <BankTitleCard width="60vw"/>
                            <HeaderBankForm width="60vw" step={this.state.step}/>
                            {this.state.step === 0 ? (
                                <MyCoordinateFormDetail emit={this.changePersonnalCoordinate} withButton={true}
                                                        buttonText={'Suivant'} height="100%" width="60vw"/>) : null}
                            {this.state.step === 1 ? (<BankFormDetail previous={this.previous} width="60vw"
                                                                      emit={this.changeBankCoordinates}/>) : null}
                            {this.state.step === 2 ? (<div style={{marginLeft: '0vw'}}><DownloadPdf/></div>) : null}
                        </div>
                    </MediaQuery>
                    <MediaQuery minWidth={Responsive.getMinTablet()} maxWidth={Responsive.getMaxTablet()}>
                        <div style={{marginLeft: '7vw'}}>
                            <BankTitleCard width="86vw"/>
                            {this.state.step < 2 ? (<HeaderBankForm width="86vw" step={this.state.step}/>) : null}
                            {this.state.step === 0 ? (
                                <MyCoordinateFormDetail withButton={true} buttonText={'Suivant'} height="100%"
                                                        width="86vw" emit={this.next}/>) : null}
                            {this.state.step === 1 ? (<BankFormDetail previous={this.previous} width="86vw"
                                                                      emit={this.changeBankCoordinates}/>) : null}
                            {this.state.step === 2 ? (<div style={{marginLeft: '-7vw'}}><DownloadPdf/></div>) : null}
                        </div>
                    </MediaQuery>
                    <MediaQuery maxWidth={Responsive.getMaxMobile()}>
                        <div>
                            <BankTitleCard width="100vw"/>
                            {this.state.step < 2 ? (<HeaderBankForm width="100vw" step={this.state.step}/>) : null}
                            {this.state.step === 0 ? (
                                <MyCoordinateFormDetail withButton={true} buttonText={'Suivant'} height="100%"
                                                        width="100vw" emit={this.next}/>) : null}
                            {this.state.step === 1 ? (<BankFormDetail previous={this.previous} width="100vw"
                                                                      emit={this.changeBankCoordinates}/>) : null}
                            {this.state.step === 2 ? (<DownloadPdf/>) : null}
                        </div>
                    </MediaQuery>
                </GIEContainer>
            </div>
        );
    }
}
