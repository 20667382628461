import React from 'react';
import MediaQuery from 'react-responsive';
import {Responsive} from '../../util/responsive';
import './calendar.css';
import {TitleUtil} from '../../util/title-util';
import userInformations from '../../userInformation/user_informations';

const customStyles = TitleUtil.getStyle();

export default class TitleCalendar extends React.Component<any, any> {
    userNo: string;

    constructor(props) {
        super(props);
        this.userNo = userInformations.getUserNo();
    }

    render() {
        return (
            <>
                <MediaQuery minWidth={Responsive.getMinDesktop()}>
                    <div style={customStyles.content}>
                        <div style={{marginLeft: '-85px'}}>
                            <span>
                                {this.userNo && <><a style={{fontWeight: 550}} href="accueil">Accueil</a>
                                    <span style={{color: '#009550', fontWeight: 'bold'}}> | </span></>}
                                <a style={customStyles.boldLink} href="rendez-vous">Modifier mon rendez-vous</a>
                            </span>
                        </div>
                        <div style={{marginTop: '90px'}}><span style={customStyles.text}>Consultez les créneaux disponibles </span>
                        </div>
                        <div style={{marginTop: '0'}}><span style={{...customStyles.bigText, fontSize: '42px'}}>Votre rendez-vous </span>
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery minWidth={Responsive.getMinTablet()} maxWidth={Responsive.getMaxTablet()}>
                    <div style={{...customStyles.content, marginBottom: '60px', padding: '0 75px'}}>
                        <div style={{marginLeft: '-65px'}}>
                            <span><a style={{fontWeight: 550}} href="accueil">Accueil</a><span
                                style={{color: '#009550', fontWeight: 'bold'}}> | </span><a
                                style={customStyles.boldLink} href="rendez-vous">Modifier mon rendez-vous</a></span>
                        </div>
                        <div style={{marginTop: '80px'}}><span style={customStyles.text}>Consultez les créneaux disponibles </span>
                        </div>
                        <div style={{marginTop: '0'}}><span style={{...customStyles.bigText, fontSize: '36px'}}>Votre rendez-vous  </span>
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={Responsive.getMaxMobile()}>
                    <div style={{...customStyles.content, marginBottom: '70px', padding: '0 25px'}}>
                        <div>
                            <span><a style={{fontWeight: 550}} href="accueil">Accueil</a><span
                                style={{color: '#009550', fontWeight: 'bold'}}> | </span><a
                                style={customStyles.boldLink} href="rendez-vous">Modifier mon rendez-vous</a></span>
                        </div>
                        <div style={{marginTop: '60px'}}><span style={customStyles.text}>Consultez les créneaux disponibles </span>
                        </div>
                        <div style={{marginTop: '0'}}><span style={customStyles.bigText}>Votre rendez-vous </span></div>
                    </div>
                </MediaQuery>
            </>
        );
    }
}
