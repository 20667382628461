// import React...
import React from 'react';
import MediaQuery from 'react-responsive';
import {Responsive} from '../../util/responsive';
import GieCard from '../../components/GIECard/GieCard';
import GIEIcon from '../../components/GIEIcon/GIEIcon';
import GridItem from "../../components/Grid/GridItem.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import GIEIconTablet from '../../components/GIEIcon/GIEIconTablet';
import {MyInformationsUtil} from '../../util/my-informations-util';

const customStyles = MyInformationsUtil.getStyle();
export default class CardInformations extends React.Component<any, any> {

    getEmail = () => {
        let email = '';
        if (this.props.user && this.props.user.email != null) {
            email = this.props.user.email;
        } else if (this.props.user && this.props.user.e_mail_service_order != null) {

            email = this.props.user.e_mail_service_order;
        }
        return email;
    }

    renderText = (property: string) => {
        let text = '';
        if (this.props.user != null && this.props.user[property] != null) {
            text = this.props.user[property];
        }
        return text;
    }

    render() {
        return (
            <>
                <MediaQuery minWidth={Responsive.getMinDesktop()}>
                    <GieCard width="23vw" height="23vw">
                        <div style={{
                            display: 'block',
                            width: "100%",
                            height: "100%",
                            marginLeft: '15px',
                            padding: '10% 0 5%',
                        }}>
                            <GridContainer direction="column"
                                           alignItems="center"
                                           justifyContent="flex-start"
                                           spacing={5}
                                           style={{width: "100%", height: "100%"}}>
                                <GridItem style={{height: '30%', marginBottom: '5%'}}>
                                    <GIEIcon isMobile={false} circle={true} clasName="icon" icon={'user'}
                                             height={'15vw'}/>
                                </GridItem>

                                <GridItem style={{justifyContent: 'center', height: '60%'}}>
                                    <div style={{display: 'flex', justifyContent: 'center'}}>
                                    <span style={{...customStyles.firstText, whiteSpace: 'nowrap'}}>
                                        Vos coordonnées
                                </span>
                                    </div>

                                    <div>
                                        <div style={{margin: 'auto', marginLeft: 'auto', marginTop: '10px'}}>
                                            <span style={customStyles.firstText}>Nom du titulaire : </span><span
                                            style={customStyles.text}> {this.renderText('name')}</span>
                                        </div>
                                        <div style={{margin: 'auto', marginLeft: 'auto'}}>
                                            <span style={customStyles.firstText}>Adresse du site :</span><span
                                            style={customStyles.text}> {this.renderText('address')}</span>
                                        </div>
                                        <div style={{margin: 'auto', marginLeft: 'auto'}}>
                                            <span style={customStyles.firstText}>Ville :</span><span
                                            style={customStyles.text}> {this.renderText('city')}</span>
                                        </div>
                                        <div style={{margin: 'auto', marginLeft: 'auto'}}>
                                            <span style={customStyles.firstText}>N° de tél. :</span> <span
                                            style={customStyles.text}> {this.renderText('phone_no')}</span>
                                        </div>
                                        <div style={{margin: 'auto', marginLeft: 'auto'}}>
                                            <span style={customStyles.firstText}>Mail :</span><span
                                            style={customStyles.text}> {this.getEmail()}</span>
                                        </div>
                                    </div>
                                </GridItem>
                            </GridContainer>
                        </div>
                    </GieCard>
                </MediaQuery>
                <MediaQuery minWidth={Responsive.getMinTablet()} maxWidth={Responsive.getMaxTablet()}>
                    <GieCard width="75vw" height="23vw">
                        <div style={{display: 'flex', width: "100%", height: "100%", padding: '5% 10%'}}>
                            <GridContainer direction="column"
                                           alignItems="flex-start"
                                           justifyContent="center"
                                           spacing={2}
                                           style={{width: "100%", height: "100%"}}
                            >
                                <GridItem>
                                    <GIEIconTablet isMobile={true} circle={true} clasName="icon" icon={'user'}/>
                                </GridItem>
                                <GridItem style={{justifyContent: 'flex-start', marginLeft: '-45px'}}>
                                    <div style={{display: 'flex', flexDirection: 'column'}}>
                                        <div style={{textAlign: 'center'}}>
                                        <span style={{...customStyles.firstText, fontSize: '14px'}}>
                                            Vos coordonnées
                                    </span>
                                        </div>
                                        <div>
                                            <span style={{...customStyles.firstText, fontSize: '16px'}}>Nom du titulaire : </span><span
                                            style={{
                                                ...customStyles.text,
                                                fontSize: '16px',
                                            }}> {this.renderText('name')}</span>
                                        </div>
                                        <div>
                                            <span style={{
                                                ...customStyles.firstText,
                                                fontSize: '16px',
                                            }}>Adresse du site:</span><span style={{
                                            ...customStyles.text,
                                            fontSize: '16px',
                                        }}> {this.renderText('address')}</span>
                                        </div>
                                        <div>
                                            <span style={{
                                                ...customStyles.firstText,
                                                fontSize: '16px',
                                            }}>Ville :</span><span style={{
                                            ...customStyles.text,
                                            fontSize: '16px',
                                        }}> {this.renderText('city')}</span>
                                        </div>
                                        <div>
                                            <span style={{
                                                ...customStyles.firstText,
                                                fontSize: '16px',
                                            }}>N° de tél. :</span> <span style={{
                                            ...customStyles.text,
                                            fontSize: '16px',
                                        }}> {this.renderText('phone_no')}</span>
                                        </div>
                                        <div>
                                            <span
                                                style={{...customStyles.firstText, fontSize: '16px'}}>Mail :</span><span
                                            style={{...customStyles.text, fontSize: '16px'}}> {this.getEmail()}</span>
                                        </div>
                                    </div>
                                </GridItem>
                            </GridContainer>
                        </div>
                    </GieCard>
                </MediaQuery>
                <MediaQuery maxWidth={Responsive.getMaxMobile()}>
                    <GieCard width="75vw" height="100%">
                        <div style={{
                            display: 'block',
                            width: "100%",
                            minHeight: "60vw",
                            marginLeft: '15px',
                            padding: '10% 0',
                        }}>
                            <GridContainer direction="column"
                                           alignItems="center"
                                           justifyContent="flex-start"
                                           spacing={5}
                                           style={{width: "100%", height: "100%"}}>

                                <GridItem style={{height: '25%', marginBottom: '5%'}}>
                                    <GIEIcon isMobile={true} circle={true} clasName="icon" icon={'user'}
                                             marginLeft={'5vw'} height={'15vw'} width={'5vw'}/>
                                </GridItem>
                                <GridItem style={{justifyContent: 'center', height: '50%'}}>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyItems: 'center',
                                        alignItems: 'center',
                                    }}>
                                    <span style={{...customStyles.firstText, fontSize: '14px'}}>
                                        Vos coordonnées
                                </span>
                                    </div>

                                    <div>
                                        <div>
                                            <span style={{...customStyles.firstText, fontSize: '16px'}}>Nom du titulaire : </span><span
                                            style={{
                                                ...customStyles.text,
                                                fontSize: '16px',
                                            }}> {this.renderText('name')}</span>
                                        </div>
                                        <div>
                                            <span style={{...customStyles.firstText, fontSize: '16px'}}>Adresse du site :</span><span
                                            style={{
                                                ...customStyles.text,
                                                fontSize: '16px',
                                            }}> {this.renderText('address')}</span>
                                        </div>
                                        <div>
                                            <span style={{
                                                ...customStyles.firstText,
                                                fontSize: '16px',
                                            }}>Ville :</span><span style={{
                                            ...customStyles.text,
                                            fontSize: '16px',
                                        }}> {this.renderText('city')}</span>
                                        </div>
                                        <div>
                                            <span style={{
                                                ...customStyles.firstText,
                                                fontSize: '16px',
                                            }}>N° de tél. :</span> <span style={{
                                            ...customStyles.text,
                                            fontSize: '16px',
                                        }}> {this.renderText('phone_no')}</span>
                                        </div>
                                        <div>
                                            <span
                                                style={{...customStyles.firstText, fontSize: '16px'}}>Mail :</span><span
                                            style={{...customStyles.text, fontSize: '16px'}}> {this.getEmail()}</span>
                                        </div>
                                    </div>
                                </GridItem>
                            </GridContainer>
                        </div>
                    </GieCard>
                </MediaQuery>
            </>
        );
    }
}
