import GIECalendar from './views/GIECalendar/GIECalendar'
import Home from './views/Home/Home';
import MyBill from './views/MyBill/MyBill';
import MyQuotation from './views/MyQuotation/MyQuotation';
import MyWorkOrderForm from './views/MyWorkOrderForm/MyWorkOrderForm';
import MyInformations from './views/MyInformations/MyInformations';
import EmergencyForm from './views/EmergencyForm/EmergencyForm';
import MyService from './views/MyService/MyService';
import MyCoordinateForm from './views/MyCoordinateForm/MyCoordinateForm'
import MyEquipmentForm from './views/EquipmentForm/EquipmentForm';
import BankForm from './views/BankFolder/BankForm';
import ChangeMyOffer from './views/ChangeMyOffer/ChangeMyOffer';
import MyDocuments from './views/MyDocument/MyDocument';
import LoginComponent from './views/Login/Login';
import FirstConnexion from './views/FirstConnexion/FirstConnexion';
import MyOutstanding from './views/MyOutstanding/MyOutstanding';
import PasswordForget from './views/PasswordForget/PasswordForget';
import VerifyMail from './views/VerifyEmail/VerifyEmail';
import ChangePassword from './views/ChangePassword/ChangePassword';
import LoginTenant from './views/LoginTenant/LoginTenant';

const routes = [
    {
        path: "/rendez-vous",
        name: "Modifier mon rdv en ligne",
        icon: "nc-icon nc-calendar-60",
        component: GIECalendar,
        layout: "/admin",
        roles: ['admin', 'tenant']
    },
    {
        path: "/accueil",
        name: "GIE accueil",
        icon: "nc-icon nc-bank",
        component: Home,
        layout: "/admin",
        roles: ['admin']
    },
    {
        path: "/mes-factures",
        name: "Mes Factures",
        icon: "nc-icon nc-credit-card",
        component: MyBill,
        layout: "/admin",
        roles: ['admin']
    },
    {
        path: "/mes-devis",
        name: "Mes Devis",
        icon: "nc-icon nc-bullet-list-67",
        component: MyQuotation,
        layout: "/admin",
        roles: ['admin']
    },
    {
        path: "/mes-fiches-d-intervention",
        name: "Mes fiches d'interventions",
        icon: "nc-icon nc-tablet-2",
        component: MyWorkOrderForm,
        layout: "/admin",
        roles: ['admin']
    },
    {
        path: "/mes-informations",
        name: "Mes informations",
        icon: "nc-icon nc-credit-card",
        component: MyInformations,
        layout: "/admin",
        roles: ['admin']
    },
    {
        path: "/formulaire-d-urgence",
        name: "Signaler une urgence",
        icon: "nc-icon nc-credit-card",
        component: EmergencyForm,
        layout: "/admin",
        roles: ['admin']
    },
    {
        path: "/services",
        name: "Mes services",
        icon: "nc-icon nc-credit-card",
        component: MyService,
        layout: "/admin",
        roles: ['admin']
    },
    {
        path: "/coordonnees",
        name: "Modifier mes coordonnées",
        icon: "nc-icon nc-credit-card",
        component: MyCoordinateForm,
        layout: "/admin",
        roles: ['admin']
    },
    {
        path: "/equipement",
        name: "Modifier mon équipement",
        icon: "nc-icon nc-credit-card",
        component: MyEquipmentForm,
        layout: "/admin",
        roles: ['admin']
    },
    {
        path: "/coordonnees-bancaires",
        name: "Prélevement automatique",
        icon: "nc-icon nc-credit-card",
        component: BankForm,
        layout: "/admin",
        roles: ['admin']
    },
    {
        path: "/changer-mon-offre",
        name: "changer mon offre",
        icon: "nc-icon nc-credit-card",
        component: ChangeMyOffer,
        layout: "/admin",
        roles: ['admin']
    },
    {
        path: "/documents",
        name: "mes documents",
        icon: "nc-icon nc-credit-card",
        component: MyDocuments,
        layout: "/admin",
        roles: ['admin']
    },
    {
        path: "/en-cours",
        name: "mes en-cours",
        icon: "nc-icon nc-credit-card",
        component: MyOutstanding,
        layout: "/admin",
        roles: ['admin']
    },
    {
        path: "/login",
        name: "login",
        icon: "nc-icon nc-credit-card",
        component: LoginComponent,
        layout: "/admin"
    },
    {
        path: "/replanification",
        name: "replanification",
        icon: "nc-icon nc-credit-card",
        component: LoginTenant,
        layout: "/admin"
    },
    {
        path: "/creer-un-compte",
        name: "première connexion",
        icon: "nc-icon nc-credit-card",
        component: FirstConnexion,
        layout: "/admin"
    },
    {
        path: "/oublie-de-mot-de-passe",
        name: "oublie mdp",
        icon: "nc-icon nc-credit-card",
        component: PasswordForget,
        layout: "/admin"
    },
    {
        path: "/verification-email",
        name: "verification-mail",
        icon: "nc-icon nc-credit-card",
        component: VerifyMail,
        layout: "/admin"
    },
    {
        path: "/changer-de-mot-de-passe",
        name: "changer de mot de passe",
        icon: "nc-icon nc-credit-card",
        component: ChangePassword,
        layout: "/admin"
    }
];

export default routes;
