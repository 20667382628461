
/*eslint-disable*/
import React from "react";
import WhiteBar from '../../components/WhiteBar/WhiteBar';
import GIEFooterTop from './GIEFooterTop'
import GIEFooterDown from './GIEFooterDown';

import './Footer.css'

const customStyles = {
        footer : {
            width: '100%',
            left: '0px',
            background: 'linear-gradient(225deg,#273889,#111a45)' , 
            bottom : '0px'
        }
      };
class GIEFooter extends React.Component<any,any> {




  render() {
    return (
      <footer  className="footer_wrap" style={{...customStyles.footer}}>
                <GIEFooterTop/>
                <WhiteBar/>
                <GIEFooterDown/>
      </footer>
    );
  }
}


export default GIEFooter;
