import React from "react";
import Button from "@material-ui/core/Button";

function GieButton({...props}) {
    const {
        children,
        style,
        green,
        onClick,
        disabled,
    } = props;

    return (
        <Button onClick={onClick ? onClick : console.log('no handler')}
                disabled={disabled}
                style={{borderRadius: '0%', backgroundColor: green ? '#009550' : 'white', ...style}}>
            {children}
        </Button>
    );
}

export default (GieButton);
