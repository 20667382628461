import React from 'react';
import MediaQuery from 'react-responsive';
import {Responsive} from '../../util/responsive';
import './TitleScreen.css';
import {TitleUtil} from '../../util/title-util';
import userInformations from '../../userInformation/user_informations';

const customStyles = TitleUtil.getStyle();

export default class TitleScreen extends React.Component<any, any> {

    constructor(props) {
        super(props);
        this.state = {
            name: userInformations.getUserName(),
        }
    }

    render() {
        return (
            <>
                <MediaQuery minWidth={Responsive.getMinDesktop()}>
                    <div style={customStyles.content}>
                        <div style={{marginLeft: '-85px'}}>
                            <span><a style={{fontWeight: 500}} href="accueil">Accueil</a><span
                                style={{color: '#009550', fontWeight: 'bold'}}> | </span><a
                                style={customStyles.boldLink} href={this.props.href}>{this.props.title}</a></span>
                        </div>
                        <div style={{marginTop: '90px', marginBottom: '11px'}}><span
                            style={customStyles.text}>{this.props.title}</span></div>
                        {!this.props.hello ? (
                            <div><span style={customStyles.bigText}>Bonjour {this.state.name},</span></div>) : null}
                        {this.props.isForm === true ? (
                            <div><span style={customStyles.bigText}>Transmettez-nous votre demande </span>
                            </div>) : null}
                        <div><span style={customStyles.bigText}>{this.props.subtitle}</span></div>
                        {this.props.isSecondLine === true ? (
                            <div><span style={customStyles.bigText}>{this.props.secondLine}</span></div>) : null}
                    </div>
                </MediaQuery>
                <MediaQuery minWidth={Responsive.getMinTablet()} maxWidth={Responsive.getMaxTablet()}>
                    <div style={{...customStyles.content, marginBottom: '60px', padding: '0 75px'}}>
                        <div style={{marginLeft: '-60px'}}>
                            <span><a style={{fontWeight: 500}} href="accueil">Accueil</a><span
                                style={{color: '#009550', fontWeight: 'bold'}}> | </span><a
                                style={customStyles.boldLink} href={this.props.href}>{this.props.title}</a></span>
                        </div>
                        <div style={{marginTop: '80px', marginBottom: '11px'}}><span
                            style={customStyles.text}>{this.props.title}</span></div>
                        {!this.props.hello ? (<div><span
                            style={{...customStyles.bigText, fontSize: '34px'}}>Bonjour {this.state.name},</span>
                        </div>) : null}
                        {this.props.isForm === true ? (
                            <div><span style={{...customStyles.bigText, fontSize: '34px'}}>Transmettez-nous votre demande </span>
                            </div>) : null}
                        <div><span style={{...customStyles.bigText, fontSize: '34px'}}>{this.props.subtitle}</span>
                        </div>
                        {this.props.isSecondLine === true ? (<div><span
                            style={{...customStyles.bigText, fontSize: '34px'}}>{this.props.secondLine}</span>
                        </div>) : null}
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={Responsive.getMaxMobile()}>
                    <div style={{...customStyles.content, marginBottom: '70px', padding: '0 25px 0 40px'}}>
                        <div>
                            <span><a style={{fontWeight: 500}} href="accueil">Accueil</a><span
                                style={{color: '#009550', fontWeight: 'bold'}}> | </span><a
                                style={customStyles.boldLink} href={this.props.href}>{this.props.title}</a></span>
                        </div>
                        <div style={{marginTop: '60px', marginBottom: '11px'}}><span
                            style={customStyles.text}>{this.props.title}</span></div>
                        {!this.props.hello ? (<div><span
                            style={{...customStyles.bigText, fontSize: '26px'}}>Bonjour {this.state.name},</span>
                        </div>) : null}
                        {this.props.isForm === true ? (
                            <div><span style={{...customStyles.bigText, fontSize: '26px'}}>Transmettez-nous votre demande </span>
                            </div>) : null}
                        <div><span style={{...customStyles.bigText, fontSize: '26px'}}>{this.props.subtitle}</span>
                        </div>
                        {this.props.isSecondLine === true ? (<div><span
                            style={{...customStyles.bigText, fontSize: '26px'}}>{this.props.secondLine}</span>
                        </div>) : null}
                    </div>
                </MediaQuery>
            </>
        );
    }
}
