export class ChangeMyOfferUtil {

    static getStyle = () => {
        return {
            content: {
                width: '50vw',
                height: '10vh',
            },
            inputLabel: {
                color: '#253A9F',
                fontFamily: 'Hind Vadodara',
                marginBottom: '1vh',
            },
            textValidator: {width: '100%', marginTop: 10, marginLeft: '1vw'},
            firstTitle: {
                color: '#253A9F',
                borderRadius: '2px',
                fontSize: '16px',
                fontWeight: 600,
                textAlign: 'center',
                fontFamily: 'Hind Vadodara',
                lineHeight: '135%',
            } as React.CSSProperties,
            switch: {
                //marginRight:'23vw',
                marginTop: '1vh',
                color: '#253A9F',
            } as React.CSSProperties,
            select: {width: '100%'},
            span: {
                color: 'white',
                fontWeight: 'bold',
                fontSize: '0.9vw',
            } as React.CSSProperties,
            greenButton: {
                color: 'white',
                textTransform: 'none',
                fontSize: 'bold',
                fontFamily: 'Hind Vadodara',
            } as React.CSSProperties,
        };
    }
}
