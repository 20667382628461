// import React...
import React from 'react';

const customStyles = {
    title: {
        color: '#9297b0',
        marginLeft: '20px',
    },
    title2: {
        color: '#253A9F',
        marginLeft: '20px',
        fontWeight: 'bold' as 'bold',
    },
}
export default class HeaderOfferForm extends React.Component<any, any> {

    render() {
        return (
            <div>
                {this.props.step === 1 ?
                    (<div style={{
                        width: this.props.width ? this.props.width : '60vw',
                        height: '100%',
                        backgroundColor: 'white',
                        padding: '25px',
                        marginBottom: '2vh',
                    }}>
                        <div style={customStyles.title2}><span style={{fontWeight: 'bold'}}>Les offres d’entretien GIE à partir de 115 € TTC </span>
                        </div>
                        <div style={customStyles.title2}><span style={{fontWeight: 'bold'}}>La validation définitive de l’offre et le montant exact interviendra après la visite d’un de nos techniciens sur site.</span>
                        </div>
                    </div>) : null}
                {this.props.step < 3 ?
                    (<div style={{
                        width: this.props.width ? this.props.width : '60vw',
                        height: '100%',
                        backgroundColor: 'white',
                        padding: '25px',
                        marginBottom: '-2vh',
                        display: 'flex',
                    }}>
                        <div style={customStyles.title}><span
                            style={{fontWeight: this.props.step === 0 ? 'bold' : 'normal'}}>1 - Votre équipement</span>
                        </div>
                        <div style={customStyles.title}><span
                            style={{fontWeight: this.props.step === 1 ? 'bold' : 'normal'}}>2 - Votre offre</span></div>
                        <div style={customStyles.title}><span
                            style={{fontWeight: this.props.step === 2 ? 'bold' : 'normal'}}>3 - Vos informations</span>
                        </div>
                    </div>) : (

                        <div style={{
                            marginLeft: '9.5vw',
                            width: '80vw',
                            height: '100%',
                            backgroundColor: 'white',
                            padding: '25px',
                            marginBottom: '2vh',
                        }}>
                            <div style={customStyles.title2}><span style={{fontWeight: 'bold'}}>Les offres d’entretien GIE à partir de 115 € TTC </span>
                            </div>
                            <div style={customStyles.title2}><span style={{fontWeight: 'bold'}}>La validation définitive de l’offre et le montant exact interviendra après la visite d’un de nos techniciens sur site.</span>
                            </div>
                        </div>
                    )}
            </div>
        );
    }
}
