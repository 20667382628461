// import React...
import React from 'react';
import ReactGA from 'react-ga';
import imgUrl from '../../assets/img/pen.png';
import GIEContainer from '../../components/GIEContainer/GIEContainer';
import GIEList from '../../components/GIEList/GIEList';
import TitleScreenList from '../../components/TitleListeScreen/TitleListScreen';
import QuotationController from '../../controller/quotation.controller';
import {DateUtil} from '../../util/date-util';
import userInformations from '../../userInformation/user_informations';

export default class MyQuotation extends React.Component<any, any> {
    quotationController: QuotationController = new QuotationController();

    constructor(props) {
        super(props);
        this.state = {
            data: []
        }
    }

    componentDidMount() {
        this.getQuotationList();
        ReactGA.pageview(window.location.pathname + window.location.search);
    }

    getQuotationList = () => {
        const noContract = userInformations.getNoContract();
        if (!noContract?.length) {
            window.location.href = "/admin/login"
        } else {
            this.getData()
        }
    }

    getData = () => {
        this.quotationController.getQuotation().then((data: any) => {
            this.manageData(data);
        }).catch(err => {
            console.log(err)
        })
    }

    manageData = (data: any) => {
        const quotationList = data.map(line => {
            return {
                _id: line._id,
                number: line.no ? line.no : '',
                title: line.reasonCode ? line.reasonCode : '',
                date: line.documentDate ? DateUtil.convertDateInString(new Date(line.documentDate)) : '',
                amount: line.amountIncludingVAT ? line.amountIncludingVAT : '',
                paid: true,
                visible: line.pdfExist,
                customerNo: line.customerNo,
                signed: true,
                downloadType: '0'
                //signed:line.reasonCode==='ACCEPTE'||line.reasonCode==='REFUSE'?true:false
            }
        })
        this.setState({ data: quotationList })
    }
    render() {
        return (
            <div className="content">
                <GIEContainer height='180vh' imgUrl={imgUrl}>
                    <TitleScreenList miniTilte='Mes documents' href='mes-devis' title="Mes Devis" />
                    <GIEList data={this.state.data} type={"quotation"} />
                </GIEContainer>
            </div>
        );
    }
}
