// import React...
import React from 'react';
import MediaQuery from 'react-responsive';
import ReactGA from 'react-ga';
import {Responsive} from '../../util/responsive';
import imgUrl from '../../assets/img/man_and_woman.jpg';
import GIEContainer from '../../components/GIEContainer/GIEContainer';
import MyCoordinateFormDetail from '../../components/form/MyCoordinateFormDetail';
import MessageSuccess from '../../components/form/MessageSuccess';
import MyEquipmentFormDetail from './EquipmentFormDetail';
import TitleScreen from '../../components/TitleScreen/TitleScreen';
import HeaderGearForm from './HeaderGearForm';
import WebEventController from '../../controller/web-event.controller';
import {WebEventUtil} from '../../util/web-event-util';
import {I18n} from '../../util/i18n';

export default class EquipmentForm extends React.Component<any, any> {

    webEventController: WebEventController = new WebEventController();

    constructor(props: any) {
        super(props);
        this.state = {
            dataSend: false,
            step: 0,
            dataFirstStep: null
        }
    }

    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }

    changeForm = () => {
        this.setState({dataSend: true})
    }

    getDataFirstStep = (dataFirstStep) => {
        this.setState({dataFirstStep})
        this.next();
    }

    next = () => {
        this.setState({step: this.state.step + 1})
    }

    previous = () => {
        this.setState({step: this.state.step - 1})
    }

    handleSubmit = (form) => {
        let coordinate: any = {};
        Object.keys(form).forEach((name, key) => {
            coordinate[name] = form[name].value
        });
        let dataFirstStep = this.state.dataFirstStep;
        const i18nFile: any = I18n.getFile();
        coordinate.type = i18nFile[dataFirstStep.type.value] != null ? i18nFile[dataFirstStep.type.value] : dataFirstStep.type.value;
        coordinate.informations = dataFirstStep.informations.value;
        coordinate.gearToInstall = i18nFile[dataFirstStep.gearToInstall.value] != null ? i18nFile[dataFirstStep.gearToInstall.value] : dataFirstStep.gearToInstall.value;
        coordinate.gearToReplace = i18nFile[dataFirstStep.gearToReplace.value] != null ? i18nFile[dataFirstStep.gearToReplace.value] : dataFirstStep.gearToReplace.value;
        const request = {
            noContrat: coordinate.noContract,
            eventType: "3",
            eventCode: 'MDFEQP',
            description: "Modification d'un équipement",
            confirm: '0',
            mail_object: WebEventUtil.getBodyOfEmail(coordinate),
            noClient: null,
            codeSite: null
        }
        this.webEventController.insertWebEvent(request).then(data => {
            this.setState({dataSend: true})
            this.next();
        }).catch(err => {
            console.log(err)
        })
    }

    render() {
        return (
            <div className="content">
                <GIEContainer form imgUrl={imgUrl}>
                    <div>
                        <TitleScreen isForm title="Installer ou remplacer un équipement " hello={true}
                                     href="equipement"/>

                        <MediaQuery minWidth={Responsive.getMinDesktop()}>
                            <div style={{margin: ' 0 20vw'}}>
                                {this.state.step < 2 ? (<HeaderGearForm width="60vw" step={this.state.step}/>) : null}
                                <div style={{height: '10px'}}></div>
                                {this.state.step === 0 ? (
                                    <MyEquipmentFormDetail next={this.getDataFirstStep} width="60vw"/>) : null}
                                {this.state.step === 1 ? (
                                    <MyCoordinateFormDetail confidentiality withButton={false} offer
                                                            previous={this.previous} width="60vw"
                                                            emit={this.handleSubmit}/>) : null}
                                {this.state.step === 2 ? (
                                    <div style={{margin: '0 -20vw'}}><MessageSuccess/></div>) : null}
                            </div>
                        </MediaQuery>
                        <MediaQuery minWidth={Responsive.getMinTablet()} maxWidth={Responsive.getMaxTablet()}>
                            <div style={{margin: ' 0 7vw'}}>
                                {this.state.step < 2 ? (<HeaderGearForm width="86vw" step={this.state.step}/>) : null}
                                <div style={{height: '10px'}}></div>
                                {this.state.step === 0 ? (
                                    <MyEquipmentFormDetail next={this.getDataFirstStep} width="86vw"/>) : null}
                                {this.state.step === 1 ? (
                                    <MyCoordinateFormDetail confidentiality withButton={false} offer
                                                            previous={this.previous} width="86vw"
                                                            emit={this.handleSubmit}/>) : null}
                                {this.state.step === 2 ? (
                                    <div style={{marginLeft: '0 -7vw'}}><MessageSuccess/></div>) : null}
                            </div>
                        </MediaQuery>
                        <MediaQuery maxWidth={Responsive.getMaxMobile()}>
                            <div>
                                {this.state.step < 2 ? (<HeaderGearForm width="100vh" step={this.state.step}/>) : null}
                                <div style={{height: '10px'}}></div>
                                {this.state.step === 0 ? (
                                    <MyEquipmentFormDetail next={this.getDataFirstStep} width="100vw"/>) : null}
                                {this.state.step === 1 ? (
                                    <MyCoordinateFormDetail confidentiality withButton={false} offer
                                                            previous={this.previous} width="100vw"
                                                            emit={this.handleSubmit}/>) : null}
                                {this.state.step === 2 ? (<MessageSuccess/>) : null}
                            </div>
                        </MediaQuery>
                        {/* <MyCoordinateFormDetail height='80vh'  buttonText='modifier mes équipements' emit={this.changeForm}/>*/}
                    </div>
                </GIEContainer>
            </div>
        );
    }
}
