export class ListUtil {
    /**
     * Group by key
     * @param xs a list
     * @param key
     */
    static groupBy( xs: Array<any>, key: string ) {
        return xs.reduce(( acc, x ) => Object.assign( {}, acc, {
            [x[key]]: ( acc[x[key]] || [] ).concat( x )
        } ), {} )
    }

    /**
     * Delete dublon in a list
     * @param arr
     */
    static deleteDoublon( arr: Array<any> ): Array<any> {
        return arr.filter( function( elem, index, self ) {
            return index === self.indexOf( elem );
        } )
    }

    static aIsSuperior( a: any, b: any, field: string ) {
        return a[field] > b[field]
    }

    static bIsSuperior( a: any, b: any, field: string ) {
        return b[field] > a[field]
    }

    static sortByFieldAsc = ( arr: Array<any>, field: string ) => {
        return arr.sort(( a, b ) => ListUtil.aIsSuperior( a, b, field ) ? 1 : ListUtil.bIsSuperior( a, b, field ) ? -1 : 0 );
    }

    static sortByFieldDesc = ( arr: Array<any>, field: string ) => {
        return arr.sort(( a, b ) => ListUtil.aIsSuperior( a, b, field ) ? -1 : ListUtil.bIsSuperior( a, b, field ) ? 1 : 0 );
    }
}
