import React from 'react'

// @material-ui/core components
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import SelectUi from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import './select.css'

const styles = theme => ({
  normal: {
//    margin: theme.spacing(),
    minWidth: 120,
    paddingRight: "15px",
    paddingLeft: "15px"
  }, 
  inputLabel: {
      fontFamily: 'Hind Vadodara',
      fontStyle: 'normal',
      fontWeight: 900,
      fontSize: '20px',
      lineHeight: '135%',
//      color: '#253A9F' this is in .css
  },
  fullWidth: {
//    marginTop: theme.spacing(),
//    marginBottom: theme.spacing(),
    width: '100%'
  }
});

const Select = (props) => {

  const { label, items, onChange, classes, value, fullWidth,disabled, ...rest} = props;
  return (
    
    <FormControl className={fullWidth ? classes.fullWidth : classes.normal} {...rest}>

      <InputLabel 
        htmlFor="select-entity"
        className={classes.inputLabel}
        focused
      > 
        {label} 
      </InputLabel>
      <SelectUi
        value={value}
        onChange={onChange}
        input={<Input name={label} id="select-entity" /> }
        disabled={disabled===true?true:false}
      >
        {
          items.map((item) => <MenuItem value={item.value} key={item.value}>
                                  {item.label} 
                              </MenuItem>)
        }
      </SelectUi>

    </FormControl>

  )
}

export default withStyles(styles)(Select)
