// import React...
import React from 'react';
import CircleCheckedFilled from '@material-ui/icons/CheckCircleSharp';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import Checkbox from '@material-ui/core/Checkbox';

const customStyles={
        content:{
            marginTop:'0vh',
            marginBottom:'10vh',
            display:'flex',
            marginLeft:'5vw'
        },
        card:{
            padding:'14px 14px 14px 5px',
            display:'flex',
            alignItems: 'center',
            border: '2px solid #9297b0',
            // height:'7.9vh',
            lineHeight: '21px',
            margin : '10px',
            minWidth: '20vw'


            },

            radioLabel:{
              marginLeft:'15vw' ,
              color:'#253A9F',
              fontWeight:'bold'
            }as React.CSSProperties,
            span:{
                color:'#9297b0',
            }as React.CSSProperties,
            spanChecked:{
                color:'white',
                marginTop:'-0vh'
            }as React.CSSProperties,
            li:{listStyleType: 'none',
                display:'flex',
                marginTop:'2.5vh'}as React.CSSProperties,
            title:{
                fontWeight:600,
                fontSize:'100%',
                textOverflow:'ellipsis',
                // whiteSpace:'nowrap',
                marginRight:'1vw'
            }as React.CSSProperties,
            previous:{
                color:'#009550',
                borderRadius:'2px',
                bacgroundColor:'white'
            },
            spanBlue:{
                color:'#253A9F',
                fontWeight: 550,
                fontSize: '14px'
            }
};

class EquipmentCard extends React.Component<any,any> {
    constructor (props:any) {
        super(props);
        this.state = {
                value:'',
        }
    }



  render() {
        return (
                    <div onClick={()=>this.props.emit()} style={{...customStyles.card,backgroundColor:this.props.checked===true?'#009550':'white'}}>
                        <div>
                            <Checkbox
                                  icon={<CircleUnchecked />}
                                  checkedIcon={<CircleCheckedFilled style={{ fill: '#74e9a1'}} />}
                                  onChange={()=>this.props.emit()}
                                  checked={this.props.checked===true}
                                  />
                          </div>
                          <div>
                      <span style={{...customStyles.title,marginTop:'0.5vh',color:this.props.checked===true?'white':'#9297b0'}}>{this.props.title}</span>
                      </div>
                 </div>


        );
  }
}
export default  (EquipmentCard) ;
