// import React...
import React from 'react';
import GridItem from "../../components/Grid/GridItem.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import GIECard from '../../components/GIECard/GieCard';
import EquipmentChoice from '../../components/EquipmentChoice/EquipmentChoice';
import GIETextField from '../../components/GIETextField/GieTextField';
import {FormValidator} from '../../util/form-validator';
import Select from '../../components/GIESelect/GIESelect';
import GieButton from '../../components/GIEButton/GieButton';
const ua = window.navigator.userAgent;
const isIE = /MSIE|Trident/.test( ua );
const customStyles = {
    content: {
        padding: '40px',
        width: '100%'
    },
    greenButton: {
        color: 'white',
        textTransform: 'none',
        fontSize: 'bold',
        fontFamily: 'Hind Vadodara'
    } as React.CSSProperties

};

const dataEquipment = [
    { label: 'Installer un nouvel équipement', value: 'new' },
    { label: 'Remplacer un équipement', value: 'replace' },
    //{label:'Autre intervention',value:'other'}
]



export default class EquipmentFormDetail extends React.Component<any, any> {
    formValidator: FormValidator = new FormValidator();


    constructor( props: any ) {
        super( props );
        this.state = {
            type: {
                value: 'new',
                error: false,
                errorMessage: ''

            },
            informations: {
                value: '',
                error: false,
                errorMessage: ''

            },
            gearToInstall: {
                value: '',
                error: false,
                errorMessage: ''
            },
            gearToReplace: {
                value: '',
                error: false,
                errorMessage: ''
            },
            gearToInstallError: false,
            gearToReplaceError: false
        }
    }

    changeChoice = ( event ) => {
        let type = this.state.type;
        type.value = event.target.value;
        this.setState( { type } )
    }
    gearToInstall = ( value ) => {
        let gearToInstall = this.state.gearToInstall;
        gearToInstall.value = value;
        this.setState( { gearToInstall } )
    }

    gearToReplace = ( value ) => {
        let gearToReplace = this.state.gearToReplace;
        gearToReplace.value = value;
        this.setState( { gearToReplace } )
    }


    changeHandler = ( event ) => {
        const name = event.target.name;
        const value = event.target.value;
        const error = this.formValidator.returnError( value, name );
        this.setState( {
            informations: {
                value,
                error: error.error,
                errorMessage: error.errorMessage
            }
        } )

    }

    checkGearError = () => {
        let error = false;
        if ( this.state.gearToInstall.value.length === 0 ) {
            this.setState( { gearToInstallError: true } );
            error = true
        }

        if ( this.state.gearToReplace.value.length === 0 && this.state.type.value !== 'new' ) {
            this.setState( { gearToReplaceError: true } );
            error = true
        }
        return error;
    }
    checkValidForm = () => {
        let isError = this.checkGearError();
        this.checkGearError();
        return isError;
    }
    handleSubmit = () => {
        if ( this.checkValidForm() === false ) {
            const data = {
                type: this.state.type,
                informations: this.state.informations,
                gearToInstall: this.state.gearToInstall,
                gearToReplace: this.state.gearToReplace
            }
            setTimeout(() => { this.props.next( data ) }, 200 );
        }
    }

    render() {
        return (
            <div>
                <GIECard width={this.props.width} height={isIE ? '650px' : '100%'} >
                    <div style={{ padding: '40px' }}>
                        <GridContainer
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                        >
                            <GridItem xs={12}>
                                <div style={{ marginBottom: '20px' }}>
                                    <Select value={this.state.type.value} fullWidth required
                                        onChange={this.changeChoice} label="Vous souhaitez" items={dataEquipment} />
                                </div>
                            </GridItem>
                        </GridContainer>
                        <GridContainer
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center">

                            <GridItem xs={12}>
                                {this.state.type.value !== 'new' ? (
                                    <EquipmentChoice gearError={this.state.gearToReplaceError} emit={this.gearToReplace}
                                        title={"Quel équipement souhaitez-vous remplacer ? "} /> ) : ( null )}
                                <EquipmentChoice gearError={this.state.gearToInstallError} emit={this.gearToInstall}
                                    title={'Quel équipement souhaitez-vous installer ?'} />

                            </GridItem>
                        </GridContainer>
                        <GridContainer
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                        >
                            <GridItem xs={12}>
                                <div style={{ marginTop: '20px' }}>
                                    <GIETextField label="Informations complémentaires"
                                        name="informations"
                                        onChange={this.changeHandler}
                                        multiline
                                        value={this.state.informations.value}
                                        helperText={this.state.informations.errorMessage}
                                        error={this.state.informations.error}
                                        rows={3}
                                    />
                                </div>
                            </GridItem>
                        </GridContainer>
                        <GridContainer direction="column"
                            justifyContent="flex-end"
                            alignItems="flex-end"
                        >
                            <GridItem xs={6}>
                                <GieButton onClick={this.handleSubmit} green={true} style={{ borderRadius: '2px', marginTop: '20px', padding: '12px 30px' }}><span style={{ ...customStyles.greenButton, fontSize: '16px' }}>Suivant</span></GieButton>

                            </GridItem>
                        </GridContainer>
                    </div>
                </GIECard>
            </div>
        );
    }
}
