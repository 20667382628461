import React from 'react';
import GridItem from "../../components/Grid/GridItem.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import {FormValidator} from '../../util/form-validator';
import DataDefault from '../../data/dataDefault';
import GIETextField from '../../components/GIETextField/GieTextField';

export default class YourAskingForm extends React.Component<any, any> {
    dataDefault: DataDefault = new DataDefault();
    formValidator: FormValidator = new FormValidator();

    constructor( props: any ) {
        super( props );
        this.state = {
            formControls: {
                brand: {
                    value: '',
                    error: false,
                    errorMessage: ''

                },
                model: {
                    value: '',
                    error: false,
                    errorMessage: ''

                },
                year: {
                    value: '',
                    error: false,
                    errorMessage: ''

                },
                installYear: {
                    value: '',
                    error: false,
                    errorMessage: ''

                },
                gear: {
                    value: '',
                    error: false,
                    errorMessage: ''

                }
            }
        }
        this.checkValidForm = this.checkValidForm.bind( this )
    }

    changeHandler = ( event ) => {
        const name = event.target.name;
        const value = event.target.value;
        const error = this.formValidator.returnError( value, name );
        this.setState( {
            formControls: {
                ...this.state.formControls,
                [name]: {
                    ...this.state.formControls[name],
                    value,
                    error: error.error,
                    errorMessage: error.errorMessage
                }
            }
        } );
        setTimeout( ()=> { this.props.emit(this.state.formControls) }, 500);
    }
    checkValidForm = () => {
        let formControls = this.state.formControls;
        let isError = false;
        Object.keys( formControls ).forEach( name => {
            const error = this.formValidator.returnError( formControls[name].value, name )
            formControls[name].error = error.error;
            formControls[name].errorMessage = error.errorMessage;
            if ( error.error === true ) {
                isError = true
            }
        } );
        this.setState( formControls )
        return isError;
    }


    render() {
        return (
                <div>
                    <GridContainer
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                    >
                        <GridItem xs={12}>
                            <GIETextField label="Quelle est la marque de votre équipement ?" required
                                onChange={this.changeHandler}
                                name="brand" value={this.state.formControls.brand.value}
                                helperText={this.state.formControls.brand.errorMessage}
                                error={this.state.formControls.brand.error} />
                        </GridItem>
                    </GridContainer>
                    <GridContainer
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                    >
                        <GridItem xs={12}>
                            <GIETextField label="Quel est le modèle de votre équipement ? " required
                                onChange={this.changeHandler}
                                name="model" value={this.state.formControls.model.value}
                                helperText={this.state.formControls.model.errorMessage}
                                error={this.state.formControls.model.error} />
                        </GridItem>
                    </GridContainer>
                    <GridContainer
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                    >
                        <GridItem xs={12}>
                            <GIETextField label="Quelle est l’année d’installation de votre équipement ?" required
                                onChange={this.changeHandler}
                                type='number'
                                name="year" value={this.state.formControls.year.value}
                                helperText={this.state.formControls.year.errorMessage}
                                error={this.state.formControls.year.error} />
                        </GridItem>
                    </GridContainer>
                    <GridContainer
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                    >
                        <GridItem xs={12}>
                            <GIETextField label="Quelle est la date de construction de votre logement ?" required
                                type='number'
                                onChange={this.changeHandler}
                                name="installYear" value={this.state.formControls.installYear.value}
                                helperText={this.state.formControls.installYear.errorMessage}
                                error={this.state.formControls.installYear.error} />
                        </GridItem>
                    </GridContainer>
                </div>
        );
    }
}
