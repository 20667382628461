/* istanbul ignore file */
export class I18n {

    static getFile = () => {
        return {
            lastName: "nom",
            phoneNumber: 'telephone',
            customerNumber: 'numero client',
            contractNUmber: 'numéro de contrat',
            contractNumber: 'numéro de contrat',
            breakDownDescription: 'description',
            zipCode: 'code postal',
            city: 'ville',
            noClient: 'numéro client',
            noContract: 'numéro de contrat',
            phoneMobile: 'téléphone mobile',
            phoneHome: 'téléphone du domicile',
            phoneOffice: 'téléphone profesionnelle',
            confidentiality: 'confidentialité',
            isNew: 'Nouvelle equipement',
            gear: 'equipement',
            gearToReplace:'équipement à remplacer',
            gearToInstall:'équipement à installer',
            gas: 'gaz',
            air_conditioning: 'air conditionne',
            heat_pump: 'pompe à chaleur',
            solar: 'solaire',
            brand: 'marque',
            model: 'modele',
            year: 'annee',
            installYear: 'annee d installation',
            offerType: 'type d offre',
            tranquility: 'tranquilite',
            serenity: 'serenite',
            comfort: 'confort',
            gearChanged: 'equipement a changé',
            new: 'nouvel equipement',
            replace: 'remplacement',
            other: 'autre demande',
            liming_failure: 'Panne de chauffage',
            leakage: 'Fuite sur l’installation',
			security:'sécurité',
			undefined:'indéfini',
			false:'faux',
			true:'vrai'
        }
    }
}
