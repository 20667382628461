// import React...
import React from 'react';
import GIETextField from '../../components/GIETextField/GieTextField';
import GridItem from "../../components/Grid/GridItem.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import GIECard from '../../components/GIECard/GieCard';
import GieButton from '../../components/GIEButton/GieButton';
import {FormValidator} from '../../util/form-validator';
import Select from '../../components/GIESelect/GIESelect';
import './emergencyDesciption.css'
const ua = window.navigator.userAgent;
const isIE = /MSIE|Trident/.test(ua);

const customStyles = {
    greenButton: {
        color: 'white',
        textTransform: 'none',
        fontSize: 'bold',
        fontFamily: 'Hind Vadodara'
    } as React.CSSProperties
};
const dataEmergency = [
    { label: 'Panne de chauffage et/ou eau chaude', value: 'liming_failure' },
    { label: 'Fuite sur l’installation', value: 'leakage' },
    //{label:'Autre intervention',value:'other'}
]

export default class EmergencyDescription extends React.Component<any, any> {
    [x: string]: any;
    formValidator: FormValidator = new FormValidator();

    constructor(props: any) {
        super(props);
        this.state = {
            isValid: true,
            formControls: {
                description: {
                    value: '',
                    error: false,
                    errorMessage: ''

                },
                nature: {
                    value: 'liming_failure',
                    error: false,
                    errorMessage: ''

                }
            }
        }
        this.checkValidForm = this.checkValidForm.bind(this);
    }

    checkValidForm = () => {
        let formControls = this.state.formControls;
        Object.keys(formControls).forEach(name => {
            const error = this.formValidator.returnError(formControls[name].value, name)
            formControls[name].error = error.error;
            formControls[name].errorMessage = error.errorMessage;
        });
        this.setState(formControls)
    }



    handleSubmit = () => {
        this.checkValidForm();
        if (this.state.formControls.description.error === false) {
            this.props.next(this.state.formControls);
        }
    }

    changeHandler = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        const error = this.formValidator.returnError(value, name);
        this.setState({
            formControls: {
                ...this.state.formControls,
                [name]: {
                    ...this.state.formControls[name],
                    value,
                    error: error.error,
                    errorMessage: error.errorMessage
                }
            }
        });

    }
    changeChoice = (event) => {
        let formControls = this.state.formControls;
        formControls.nature.value = event.target.value;
        this.setState({ formControls })
    }
    render() {

        return (
            <GIECard width={this.props.width} height={isIE ? '200px' : '100%'}>
                <div style={{ padding: '40px' }}>
                    <GridContainer
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >
                        <GridItem xs={12}>
                            <div style={{ marginBottom: '20px' }}>
                                <Select value={this.state.formControls.nature.value} fullWidth required
                                    onChange={this.changeChoice} label="Nature de votre panne: " items={dataEmergency} />
                            </div>
                        </GridItem>
                    </GridContainer>
                    <GridContainer direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                    >
                        <GridItem xs={12}>
                            <GIETextField
                                label="Description de votre panne : " required
                                onChange={this.changeHandler}
                                name="description" value={this.state.formControls.description.value}
                                multiline
                                error={this.state.formControls.description.error}
                                helperText={this.state.formControls.description.errorMessage}
                                rows={5}
                                placeholder="Description de votre panne  "
                                alone
                            />
                        </GridItem>
                    </GridContainer>
                    <GridContainer direction="column"
                        justifyContent="flex-end"
                        alignItems="flex-end">
                        <GridItem xs={6}>
                            <GieButton
                                onClick={this.handleSubmit}
                                green
                                style={{ borderRadius: '2px', marginTop: '20px', padding: '12px 30px' }}
                            >
                                <span style={{ ...customStyles.greenButton, fontSize: '16px' }}>
                                    Suivant
                                </span>
                            </GieButton>
                        </GridItem>
                    </GridContainer>
                </div>
            </GIECard>


        );
    }
}
