import React from 'react';
import ReactGA from 'react-ga';
import MediaQuery from 'react-responsive';
import {Responsive} from '../../util/responsive';
import GIEContainer from '../../components/GIEContainer/GIEContainer';
import imgUrl from '../../assets/img/family.jpg';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import CardDocument from '../../components/CardDocument/CardDocument';
import TitleScreen from '../../components/TitleScreen/TitleScreen'
import './Service.css';
const customStyles = {
    content: {
        // marginTop:'0vh',
        // marginLeft:'-5vw'
    }
};

export default class MyService extends React.Component<any, any> {

    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }

    render() {
        return (
            <>
                <MediaQuery minWidth={Responsive.getMinDesktop()}>
                    <div className="content">
                        <GIEContainer height='140vh' imgUrl={imgUrl}>
                            <TitleScreen
                                hello
                                title="Services en ligne"
                                subtitle="Quel est votre besoin ?"
                                href="services"
                            />
                            <div style={customStyles.content}>
                                <GridContainer direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    spacing={3}
                                >
                                    <GridItem xs={3}>
                                        <CardDocument icon='cog' url='/admin/changer-mon-offre' title='Modifier mon offre' />
                                    </GridItem>
                                    <GridItem xs={3}>
                                        <CardDocument icon='euro' bigText url='/admin/coordonnees-bancaires' title='Souscrire au prélèvement automatique' />
                                    </GridItem>
                                    <GridItem xs={3}>
                                        <CardDocument icon='box' external url='https://gie-sa.fr/services-en-ligne/je-demenage/' title='Je déménage' />
                                    </GridItem>
                                </GridContainer>
                                <div style={{ height: '15px' }}></div>
                                <GridContainer direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    spacing={3}>
                                    <GridItem xs={3}>
                                        <CardDocument icon='sun' bigText breakline title='Installer ou remplacer un équipement' url='/admin/equipement' />
                                    </GridItem>
                                    <GridItem xs={3}>
                                        <CardDocument icon='warning' url='/admin/formulaire-d-urgence' title='Signaler une panne' />
                                    </GridItem>
                                    <GridItem xs={3}>
                                        <CardDocument icon='mail' external url='https://gie-sa.fr/contact/#' title='Autre demande' />
                                    </GridItem>
                                </GridContainer>
                            </div>
                        </GIEContainer>
                    </div>
                </MediaQuery>
                <MediaQuery minWidth={Responsive.getMinTablet()} maxWidth={Responsive.getMaxTablet()}>
                    <div className="content">
                        <GIEContainer height='240vh' imgUrl={imgUrl}>
                            <TitleScreen
                                hello
                                title="Services en ligne"
                                subtitle="Quel est votre besoin ?"
                                href="services"
                            />
                            <div style={customStyles.content}>
                                <GridContainer direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    spacing={3}
                                >
                                    <GridItem xs={5}>
                                        <CardDocument icon='cog' url='/admin/changer-mon-offre' title='Modifier mon offre' />
                                    </GridItem>
                                    <GridItem xs={5}>
                                        <CardDocument icon='euro' url='/admin/coordonnees-bancaires' title='Souscrire au prélèvement automatique' />
                                    </GridItem>

                                </GridContainer>
                                <div style={{ height: '15px' }}></div>
                                <GridContainer direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    spacing={3}>
                                    <GridItem xs={5}>
                                        <CardDocument icon='box' external url='https://gie-sa.fr/services-en-ligne/je-demenage/' title='Je déménage' />
                                    </GridItem>
                                    <GridItem xs={5}>
                                        <CardDocument icon='sun' title='Installer ou remplacer un équipement' url='/admin/equipement' />
                                    </GridItem>

                                </GridContainer>
                                <div style={{ height: '15px' }}></div>
                                <GridContainer direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    spacing={3}>

                                    <GridItem xs={5}>
                                        <CardDocument icon='warning' url='/admin/formulaire-d-urgence' title='Signaler une panne' />
                                    </GridItem>
                                    <GridItem xs={5}>
                                        <CardDocument icon='mail' external url='https://gie-sa.fr/contact/#' title='Autre demande' />
                                    </GridItem>
                                </GridContainer>
                            </div>
                        </GIEContainer>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={Responsive.getMaxMobile()}>
                    <div className="content">
                        <GIEContainer height='470vh' imgUrl={imgUrl}>
                            <TitleScreen
                                hello
                                title="Services en ligne"
                                subtitle="Quel est votre besoin ?"
                                href="services"
                            />
                            <div style={customStyles.content}>
                                <GridContainer direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    spacing={6}
                                >
                                    <GridItem >
                                        <CardDocument marginLeft={'3.8vw'} icon='cog' url='/admin/changer-mon-offre' title='Modifier mon offre' />
                                    </GridItem>
                                    <GridItem >
                                        <CardDocument marginLeft={'3.8vw'} icon='euro' url='/admin/coordonnees-bancaires' title='Souscrire au prélèvement automatique' />
                                    </GridItem>
                                    <GridItem >
                                        <CardDocument marginLeft={'3.2vw'} external icon='box' url='https://gie-sa.fr/services-en-ligne/je-demenage/' title='Je déménage' />
                                    </GridItem>
                                    <GridItem >
                                        <CardDocument marginLeft={'4.2vw'} icon='sun' title='Installer ou remplacer un équipement' url='/admin/equipement' />
                                    </GridItem>
                                    <GridItem>
                                        <CardDocument marginLeft={'3.6vw'} icon='warning' url='/admin/formulaire-d-urgence' title='Signaler une panne' />
                                    </GridItem>
                                    <GridItem>
                                        <CardDocument marginLeft={'3.2vw'} external icon='mail' url='https://gie-sa.fr/contact/#' title='Autre demande' />
                                    </GridItem>
                                </GridContainer>
                            </div>
                        </GIEContainer>
                    </div>
                </MediaQuery>
            </>
        );
    }
}
