import eye from '../assets/img/icons/eye.svg';
import invoice from '../assets/img/icons/invoice.svg';
import creditCard from '../assets/img/icons/credit_card.svg';
import pen from '../assets/img/icons/pen.svg';
import contract from '../assets/img/icons/contract.svg';
import calendar from '../assets/img/icons/calendar.svg';
import euro from '../assets/img/icons/euro.svg';
import warning from '../assets/img/icons/warning.svg';
import folder from '../assets/img/icons/folder.svg';
import tools from '../assets/img/icons/tools.svg';
import car from '../assets/img/icons/car.svg';
import box from '../assets/img/icons/box.svg';
import sun from '../assets/img/icons/sun.svg';
import mail from '../assets/img/icons/mail.svg';
import greyWarning from '../assets/img/icons/greyWarning.svg';
import menu from '../assets/img/icons/menu.svg';
import user from '../assets/img/icons/user.svg';
import multiContract from '../assets/img/icons/multi-contract.svg';
import contractPen from '../assets/img/icons/contract-pen.svg';
import handShake from '../assets/img/icons/handShake.svg';
import send from '../assets/img/icons/send.svg';
import account from '../assets/img/icons/account.svg';
import disconnect from '../assets/img/icons/disconnect.svg';
import logout from '../assets/img/icons/logout.svg';
import cog from '../assets/img/icons/cog.svg';
import file from '../assets/img/icons/file.svg';
import download from '../assets/img/icons/download.svg';

export class IconUtil {

    static getIconList = () => {
        return [
            'download', 'eye', 'invoice', 'creditCard', 'pen', 'contract',
            'calendar', 'euro', 'warning', 'folder', 'tools', 'car', 'box', 'sun',
            'mail', 'greyWarning', 'menu', 'user', 'multiContract',
            'contractPen',
            'handShake',
            'send',
            'account',
            'disconnect',
            'logout',
            'cog',
            'file'
        ]
    }

    static getIcon( iconText ) {
        let icon = '';
        switch ( iconText ) {
            case 'download': {
                icon = download
                break;
            }
            case 'eye': {
                icon = eye
                break;
            }
            case 'invoice': {
                icon = invoice
                break;
            }
            case 'creditCard': {
                icon = creditCard
                break;
            }
            case 'pen': {
                icon = pen
                break;
            }
            case 'contract': {
                icon = contract
                break;
            }
            case 'calendar': {
                icon = calendar
                break;
            }
            case 'euro': {
                icon = euro
                break;
            }
            case 'warning': {
                icon = warning
                break;
            }
            case 'folder': {
                icon = folder
                break;
            }
            case 'tools': {
                icon = tools
                break;
            }
            case 'car': {
                icon = car
                break;
            }
            case 'box': {
                icon = box
                break;
            }
            case 'sun': {
                icon = sun
                break;
            }
            case 'mail': {
                icon = mail
                break;
            }
            case 'greyWarning': {
                icon = greyWarning
                break;
            }
            case 'menu': {
                icon = menu
                break;
            }
            case 'user': {
                icon = user
                break;
            }
            case 'multiContract': {
                icon = multiContract
                break;
            }
            case 'contractPen': {
                icon = contractPen
                break;
            }
            case 'handShake': {
                icon = handShake
                break;
            }
            case 'send': {
                icon = send
                break;
            }
            case 'account': {
                icon = account
                break;
            }
            case 'disconnect': {
                icon = disconnect
                break;
            }
            case 'logout': {
                icon = logout
                break;
            }
            case 'cog': {
                icon = cog
                break;
            }
            case 'file': {
                icon = file
                break;
            }
            default: {
                //statements;
                break;
            }
        }
        return icon;
    }
}
