import React from 'react';
import GIECard from '../../components/GIECard/GieCard';
import MediaQuery from 'react-responsive';
import {Responsive} from '../../util/responsive';
import download from '../../assets/img/icons/download.svg';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import DownloadController from '../../controller/download.controller';
import ConfigUrl from '../../config/config-url';

const customStyles = {
    content: {
        backgroundColor: 'white',
        color: '#253A9F',
        marginLeft: '200w'
    }
}
export default class DownloadPdf extends React.Component<any, any> {
    downloadController: DownloadController = new DownloadController();
    configUrl: ConfigUrl = new ConfigUrl();

    downloadMandate = () => {
        window.open( this.configUrl.getBaseBackUrlStatic() + '/bank/Demande de prélévement SEPA.pdf' );
    }

    returnComponent = () => {
        return ( < div style={{ display: 'flex', height: '100%', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }
        }>
            <div style={{ marginTop: 0, width: '100%' }}>
                <span style={{
                    alignItems: 'center',
                    justifyContent: 'center', marginLeft: '0vw',
                    marginTop: 20, color: '#253A9F', fontWeight: 'bold', fontSize: '15px', textAlign: 'center', padding: '0 40px', display: 'flex'
                }}>
                    Pour finaliser la mise en place du prélèvement automatique, merci de bien vouloir télécharger,
        imprimer et compléter le mandat de prélèvement ci-dessous et l’accompagner d’un Relevé d’Identité Bancaire (RIB),
        à adresser par la poste à l’adresse GIE SA, 14 place des Halles, 67000 Strasbourg </span>
            </div>
            <div onClick={this.downloadMandate} style={{ display: 'flex', marginTop: 20, cursor: 'pointer' }}>

                <Tooltip title="Télécharger le mandat de prélèvement">
                    <div style={{ marginTop: 8, textDecoration: 'underline' }}>
                        <span style={{
                            fontFamily: 'Hind Vadodara',
                            color: '#202956',
                            borderRadius: '2px',
                            fontWeight: 'bold',
                            fontSize: '15px',
                            lineHeight: '120%',
                            verticalAlign: 'middle',
                            marginLeft: 10,
                        }}>
                            Demande de prélévement SEPA
                        </span>
                        <Icon style={{ margin: 'auto', marginTop: '0px', marginLeft: '14px', height: '100%', width: '20px', fontSize: '1rem', verticalAlign: 'middle' }}>
                            <img style={{ height: '20px' }} alt="icon" src={download} />
                        </Icon>
                    </div>
                </Tooltip>
            </div>
        </div > )
    }

    render() {
        return (
            <div>
                <MediaQuery minWidth={Responsive.getMinDesktop()}>
                    <div style={{ margin: 'auto', width: '80vw' }}>
                        <GIECard width='60vw' height='50vh' style={customStyles.content}>
                            {this.returnComponent()}
                        </GIECard>
                    </div>
                </MediaQuery >
                <MediaQuery minWidth={Responsive.getMinTablet()} maxWidth={Responsive.getMaxTablet()}>
                    <div style={{ margin: 'auto', marginLeft: '7vw', width: '80vw' }}>
                        <GIECard width='86vw' height='40vh' >
                            {this.returnComponent()}
                        </GIECard>
                    </div>
                </MediaQuery >
                <MediaQuery maxWidth={Responsive.getMaxMobile()}>
                    <div style={{ margin: 'auto',marginLeft:0, width: '80vw' }}>
                        <GIECard width='100vw' height='50vh' style={customStyles.content}>
                            {this.returnComponent()}
                        </GIECard>
                    </div>
                </MediaQuery >
                <MediaQuery maxWidth={Responsive.getMaxMobile()}>
                    <div style={{ margin: 'auto',marginLeft:0, width: '80vw' }}>
                        <GIECard width='100vw' height='50vh' style={customStyles.content}>
                            {this.returnComponent()}
                        </GIECard>
                    </div>
                </MediaQuery >
            </div>
        );
    }
}
