/* istanbul ignore file */
import GenericController from './generic.controller';

export default class BillController extends GenericController {
    getBill = () => {
        return this.requestGetBack('bill/user').then(data => {
            return data;
        }).catch(err => {
            console.log(err);
            throw err;
        })
    }
}
