import React from 'react';

const customStyles = {
    content: {
        width: '50vw',
        height: '100%',
        backgroundColor: 'white',
        marginBottom: '-2.2vh',
        padding: '25px'
    },
    span: {
        fontfamily: 'Hind Vadodara',
        fontStyle: 'normal',
        marginLeft: '20px'
    }
}

export default class HeaderEmergencyForm extends React.Component<any, any> {

    render() {
        return (
            <div style={{...customStyles.content, width: this.props.width}}>
                <div style={{display: 'flex', color: '#9297b0'}}>
                    <span style={{...customStyles.span, fontWeight: this.props.step === 0 ? 600 : 500}}>
                        1- Identification de la panne
                    </span>
                    <span style={{...customStyles.span, fontWeight: this.props.step === 1 ? 600 : 500}}>2- Vos coordonnées</span>
                </div>
            </div>
        );
    }
}
