import React from 'react';
import './CoordinateTitle.css';

const customStyles = {
    content: {
        width: '50vw',
        backgroundColor: 'white',
        marginBottom: '20px',
        padding: '20px'
    },
    span: {
        fontfamily: 'Hind Vadodara',
        fontStyle: 'normal',
        textAlign: 'justify'
    } as React.CSSProperties
}

export default class CardWitTitleCoordinateForm extends React.Component<any, any> {

    render() {
        return (
            <div>
                <div style={{...customStyles.content, width: this.props.width}}>
                    <div style={{display: 'flex', color: '#253A9F'}}>
                        <span style={{...customStyles.span, fontWeight: 600}}>
                         La mise à jour de vos coordonnées sera effective dès traitement de votre demande par nos services.

                        </span>
                    </div>
                    <div style={{display: 'flex', color: '#253A9F'}}>
                        <span style={{...customStyles.span, fontWeight: 600}}>
                           Pour nous signaler un changement d’adresse, rendez-vous sur notre service <a
                            href="https://gie-sa.fr/services-en-ligne/je-demenage/">Je déménage</a>, dans la rubrique Services en ligne.

                        </span>
                    </div>
                </div>
            </div>
        );
    }
}
