import React from 'react';
import MediaQuery from 'react-responsive';
import GIETextField from '../../components/GIETextField/GieTextField';
import {ValidatorForm} from 'react-material-ui-form-validator';
import GridItem from "../../components/Grid/GridItem.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import {FormValidator} from '../../util/form-validator';
import GIECard from '../../components/GIECard/GieCard';
import GIEButton from '../../components/GIEButton/GieButton';
import InformationsController from '../../controller/informations.controller';
import {Responsive} from '../../util/responsive';
import GIECheckBox from '../../components/GIECheckBox/GieCheckBox';
import userInformations from '../../userInformation/user_informations';

const ua = window.navigator.userAgent;
const isIE = /MSIE|Trident/.test(ua);

const customStyles = {
    content: {
        padding: ' 0 40px'
    }, inputLabel: {
        color: '#253A9F', fontDamily: 'Hind Vadodara'
    }, textValidator: {
        width: '100%', marginTop: 10
    }, textValidatorMultiLine: {
        width: '100%', marginTop: 10, height: 50
    }, previous: {
        color: '#009550', borderRadius: '2px', bacgroundColor: 'white'
    }, spanGreen: {
        color: '#009550', textTransform: 'none', fontSize: 'bold', fontFamily: 'Hind Vadodara'

    } as React.CSSProperties, buttonText: {
        color: 'white', textTransform: 'none', fontSize: '16px', fontFamily: 'Hind Vadodara'
    } as React.CSSProperties, text: {
        fontFamily: 'Hind Vadodara',
        color: '#253A9F',
        borderRadius: '2px',
        fontWeight: 'bold',
        fontSize: '14px',
        lineHeight: '21px',
        display: 'flex',
        alignItems: 'center',
        letterSpacing: '0.04em',
        textTransform: 'uppercase'
    } as React.CSSProperties, greenButton: {
        color: 'white', textTransform: 'none', fontFamily: 'Hind Vadodara', fontSize: '16px'
    } as React.CSSProperties
};
export default class MyCoordinateFormDetail extends React.Component<any, any> {
    [x: string]: any;

    formValidator: FormValidator = new FormValidator();
    informationsController: InformationsController = new InformationsController();

    constructor(props: any) {
        super(props);
        this.state = {
            isValid: true, initialFormControls: {}, formControls: {
                lastName: {
                    value: '', error: false, errorMessage: ''

                }, email: {
                    value: '', error: false, errorMessage: ''

                }, phoneNumber: {
                    value: '', error: false, errorMessage: ''

                }, adresse: {
                    value: '', error: false, errorMessage: ''

                }, zipCode: {
                    value: '', error: false, errorMessage: ''

                }, city: {
                    value: '', error: false, errorMessage: ''

                }, noClient: {
                    value: '', error: false, errorMessage: ''

                }, noContract: {
                    value: '', error: false, errorMessage: ''

                }, phoneMobile: {
                    value: '', error: false, errorMessage: ''

                }, phoneHome: {
                    value: '', error: false, errorMessage: ''

                }, phoneOffice: {
                    value: '', error: false, errorMessage: ''

                }, confidentiality: {
                    value: false, error: false, errorMessage: ''

                }
            }
        }
        this.checkValidForm = this.checkValidForm.bind(this);
    }

    componentDidMount() {
        const noContract = userInformations.getNoContract();
        if (!noContract?.length) {
            window.location.href = "/admin/login"
        } else {
            this.getData()
        }
    }

    getData = () => {
        this.informationsController.getUserInformations().then((data: any) => {
            this.setData(data)
        }).catch(err => {
            console.log(err)
        })
    }

    setData = (data) => {
        let formControls = this.state.formControls;
        formControls.lastName.value = data.user.name;
        formControls.email.value = data.user.email;
        formControls.phoneNumber.value = data.user.phone_no;
        formControls.adresse.value = data.user.address;
        formControls.zipCode.value = data.user.post_Code;
        formControls.city.value = data.user.city;
        formControls.noClient.value = data.user.no;
        formControls.noContract.value = userInformations.getNoContract();
        formControls.phoneMobile.value = data.user.mobile_phone_no;
        formControls.phoneHome.value = data.user.home_no;
        formControls.phoneOffice.value = data.user.office_No;
        let initialFormControls = JSON.parse(JSON.stringify(formControls));
        this.setState({formControls, initialFormControls})
    }

    checkValidForm = () => {
        let formControls = this.state.formControls;
        let isError = false;
        let nonRequiredFields = ['phoneMobile', 'phoneHome', 'phoneOffice', 'firstName'];
        if (this.props.confidentiality !== true) {
            nonRequiredFields = [...nonRequiredFields, 'confidentiality'];
        }
        Object.keys(formControls).forEach(name => {
            if (!nonRequiredFields.includes(name)) {
                const error = this.formValidator.returnError(formControls[name].value, name)
                formControls[name].error = error.error;
                formControls[name].errorMessage = error.errorMessage;
                if (error.error === true) {
                    isError = true
                }
            }
        });
        this.setState({formControls, isError: isError})
        return isError;
    }


    addAsterisqueToChangedField = () => {
        let formControls = this.state.formControls
        Object.keys(formControls).forEach(name => {
            if (formControls[name].value !== this.state.initialFormControls[name].value) {
                formControls[name].value = '*' + formControls[name].value
            }
        });
        return formControls
    }

    handleSubmit = () => {
        const isError = this.checkValidForm();
        if (isError === false) {
            setTimeout(() => {
                const formControls = this.props.changed === true ? this.addAsterisqueToChangedField() : this.state.formControls;
                this.props.emit(formControls)
            }, 100);
        }
    }

    changeHandler = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        const nonRequiredFields = ['phoneMobile', 'phoneHome', 'phoneOffice'];
        const error = nonRequiredFields.includes(name) ? {
            error: false, errorMessage: ''
        } : this.formValidator.returnError(value, name);
        this.setState({
            formControls: {
                ...this.state.formControls, [name]: {
                    ...this.state.formControls[name], value, error: error.error, errorMessage: error.errorMessage
                }
            }
        });
    }

    checkCGV = (event) => {
        let formControls = this.state.formControls;
        formControls.confidentiality.value = !formControls.confidentiality.value
        this.setState({formControls})
    }

    manageHeight = () => {
        let vh = '60vw';
        if (this.checkValidForm() === false && this.props.height != null) {
            vh = this.props.height;
        } else if (this.checkValidForm() === true && this.props.heightError != null) {
            vh = this.props.heightError;
        }
        return vh
    }

    renderGieCheckBox = () => {
        if (this.props.confidentiality === true) {
            return <GIECheckBox name="confidentiality" errorText={this.state.formControls.confidentiality.errorMessage}
                                error={this.state.formControls.confidentiality.error} isGrey
                                checked={this.state.formControls.confidentiality.value === true}
                                emit={(event) => this.checkCGV(event)}
                                label="En cochant cette case, j’accepte que mes données personnelles soient utilisées par GIE à des fins de traitement de ma demande."/>;
        } else {
            return null;
        }
    }

    render() {
        return (<GIECard style={{backgroundColor: 'white'}}
                         width={this.props.width != null ? this.props.width : '60vw'}
                         height={isIE ? '1050px' : this.manageHeight}>
            <div>
                <ValidatorForm
                    ref="form"
                    instantValidate={true}
                    onSubmit={submit => console.log(submit)}
                    onError={errors => console.log(errors)}>
                    <GridContainer direction="row"
                                   justifyContent="flex-start"
                                   alignItems="flex-start"
                                   style={{...customStyles.content, paddingTop: '40px'}}>
                        <GridItem xs={12}>
                            <GIETextField label="Nom du titulaire" required disabled
                                          onChange={this.changeHandler}
                                          name="lastName" value={this.state.formControls.lastName.value}
                                          helperText={this.state.formControls.lastName.errorMessage}
                                          error={this.state.formControls.lastName.error}/>
                        </GridItem>
                    </GridContainer>
                    <GridContainer direction="row"
                                   justifyContent="flex-start"
                                   alignItems="center"
                                   style={customStyles.content}>
                        <GridItem xs={12}>
                            <GIETextField
                                label="Votre email"
                                onChange={this.changeHandler}
                                name="email"
                                required
                                value={this.state.formControls.email.value}
                                helperText={this.state.formControls.email.errorMessage}
                                error={this.state.formControls.email.error}/>
                        </GridItem>
                        <GridItem xs={12}>
                            <GIETextField
                                label="Votre n° de tel. principal" required
                                onChange={this.changeHandler}
                                name="phoneNumber" value={this.state.formControls.phoneNumber.value}
                                error={this.state.formControls.phoneNumber.error}
                                helperText={this.state.formControls.phoneNumber.errorMessage}/>
                        </GridItem>
                    </GridContainer>
                    {this.props.changed ? (<GridContainer direction="row"
                                                          justifyContent="flex-start"
                                                          alignItems="center"
                                                          style={customStyles.content}>
                        <GridItem xs={12}>
                            <GIETextField
                                label="Votre n° de mobile"
                                onChange={this.changeHandler}
                                name="phoneMobile"
                                value={this.state.formControls.phoneMobile.value}
                                helperText={this.state.formControls.phoneMobile.errorMessage}
                                error={this.state.formControls.phoneMobile.error}/>
                        </GridItem>
                        <GridItem xs={12}>
                            <GIETextField
                                label="Votre n° professionnel"
                                onChange={this.changeHandler}
                                name="phoneOffice" value={this.state.formControls.phoneOffice.value}
                                error={this.state.formControls.phoneOffice.error}
                                helperText={this.state.formControls.phoneOffice.errorMessage}/>
                        </GridItem>
                        <GridItem xs={12}>
                            <GIETextField
                                label="Votre n° de domicile"
                                onChange={this.changeHandler}
                                name="phoneHome" value={this.state.formControls.phoneHome.value}
                                error={this.state.formControls.phoneHome.error}
                                helperText={this.state.formControls.phoneHome.errorMessage}/>
                        </GridItem>
                    </GridContainer>) : null}
                    <GridContainer direction="row"
                                   justifyContent="flex-start"
                                   alignItems="center"
                                   style={customStyles.content}>
                        <GridItem xs={12}>
                            <GIETextField label="Adresse du site" required disabled
                                          onChange={this.changeHandler}
                                          name="adresse" value={this.state.formControls.adresse.value}
                                          error={this.state.formControls.adresse.error}
                                          helperText={this.state.formControls.adresse.errorMessage}/>
                        </GridItem>
                        <GridItem xs={12}>
                            <GIETextField label="Vile du site" required disabled
                                          onChange={this.changeHandler}
                                          name="city" value={this.state.formControls.city.value}
                                          error={this.state.formControls.city.error}
                                          helperText={this.state.formControls.city.errorMessage}/>
                        </GridItem>
                    </GridContainer>
                    <GridContainer direction="row"
                                   justifyContent="flex-start"
                                   alignItems="center"
                                   style={customStyles.content}>
                        <GridItem xs={12}>
                            <GIETextField label="Votre numéro de client" required
                                          onChange={this.changeHandler}
                                          name="noClient" value={this.state.formControls.noClient.value}
                                          helperText={this.state.formControls.noClient.errorMessage}
                                          aria-describedby="my-helper-text"
                                          disabled
                                          style={customStyles.textValidator}
                                          error={this.state.formControls.noClient.error}/>
                        </GridItem>
                        <GridItem xs={12}>
                            <GIETextField alone label="Votre numéro de contrat" required
                                          onChange={this.changeHandler}
                                          name="noContract" value={this.state.formControls.noContract.value}
                                          helperText={this.state.formControls.noContract.errorMessage}
                                          aria-describedby="my-helper-text"
                                          disabled
                                          style={customStyles.textValidator}
                                          error={this.state.formControls.noContract.error}/>
                        </GridItem>
                    </GridContainer>
                    <MediaQuery minWidth={Responsive.getMinDesktop()}>
                        {this.props.withButton !== false ? (<GridContainer direction="row"
                                                                           justifyContent="flex-end"
                                                                           alignItems="flex-end"
                                                                           style={{padding: '20px 40px 40px'}}>
                            <GridItem xs={12}>
                                {this.renderGieCheckBox()}
                            </GridItem>
                            <GridItem xs={3}>
                                <GIEButton onClick={this.handleSubmit} green={true} style={{
                                    borderRadius: '2px', marginTop: '20px', padding: '12px 30px'
                                }}><span
                                    style={customStyles.greenButton}>{this.props.buttonText}</span></GIEButton>
                            </GridItem>
                        </GridContainer>) : null}
                        {this.props.offer === true ? (<div>
                            <GridContainer direction="column"
                                           justifyContent="flex-start"
                                           alignItems="flex-start"
                                           style={{padding: '20px 40px 40px'}}>
                                <GridItem xs={12}>
                                    <GIECheckBox isGrey
                                                 checked={this.state.formControls.confidentiality.value === true}
                                                 name="confidentiality"
                                                 errorText={this.state.formControls.confidentiality.errorMessage}
                                                 error={this.state.formControls.confidentiality.error}
                                                 emit={(event) => this.checkCGV(event)}
                                                 label="En cochant cette case, j’accepte que mes données personnelles soient utilisées par GIE à des fins de traitement de ma demande"/>
                                </GridItem>
                            </GridContainer>
                            <GridContainer direction="row"
                                           justifyContent="space-between"
                                           alignItems="center"
                                           style={{padding: '30px 0'}}>
                                <GridItem>
                                    <div style={{marginLeft: '40px'}}>
                                        <GIEButton style={{border: '1px solid #009550', padding: '12px 20px'}}
                                                   onClick={this.props.previous}><span style={{
                                            ...customStyles.spanGreen, fontSize: '16px'
                                        }}>Précédent</span> </GIEButton>
                                    </div>
                                </GridItem>
                                <GridItem>
                                    <div style={{marginRight: '40px'}}>
                                        <GIEButton onClick={this.handleSubmit} green={true}
                                                   style={{padding: '12px 30px'}}><span style={{
                                            ...customStyles.greenButton, fontSize: '16px'
                                        }}>Suivant</span></GIEButton>
                                    </div>
                                </GridItem>
                            </GridContainer></div>) : null}
                    </MediaQuery>
                    <MediaQuery minWidth={Responsive.getMinTablet()} maxWidth={Responsive.getMaxTablet()}>
                        {this.props.withButton !== false ? (<div>
                            <GridContainer direction="column"
                                           justifyContent="flex-start"
                                           alignItems="flex-start"
                                           style={{padding: '0px 40px 40px'}}>
                                <GridItem xs={12}>
                                    {this.renderGieCheckBox()}
                                </GridItem>
                                <GridItem xs={6}>
                                    <div style={{marginLeft: '450px'}}>
                                        <GIEButton onClick={this.handleSubmit}
                                                   green={true}
                                                   style={{
                                                       borderRadius: '2px',
                                                       marginTop: '20px',
                                                       padding: '12px 30px'
                                                   }}>
                                            <span style={customStyles.greenButton}>{this.props.buttonText}</span>
                                        </GIEButton>
                                    </div>
                                </GridItem>
                            </GridContainer>
                        </div>) : null}
                        {this.props.offer === true ? (<div>
                            <GridContainer direction="column"
                                           justifyContent="flex-start"
                                           alignItems="flex-start"
                                           style={{padding: '20px 40px 40px'}}>
                                <GridItem xs={12}>
                                    <GIECheckBox isGrey
                                                 checked={this.state.formControls.confidentiality.value === true}
                                                 name="confidentiality"
                                                 errorText={this.state.formControls.confidentiality.errorMessage}
                                                 error={this.state.formControls.confidentiality.error}
                                                 emit={(event) => this.checkCGV(event)}
                                                 label="En cochant cette case, j’accepte que mes données personnelles soient utilisées par GIE à des fins de traitement de ma demande"/>
                                </GridItem>
                            </GridContainer>
                            <GridContainer direction="row"
                                           justifyContent="space-between"
                                           alignItems="center"
                                           style={{padding: '30px 0'}}>
                                <GridItem>
                                    <div style={{marginLeft: '40px'}}>
                                        <GIEButton style={{border: '1px solid #009550', padding: '12px 20px'}}
                                                   onClick={this.props.previous}><span style={{
                                            ...customStyles.spanGreen, fontSize: '16px'
                                        }}>Précédent</span> </GIEButton>
                                    </div>
                                </GridItem>
                                <GridItem>
                                    <div style={{marginRight: '40px'}}>
                                        <GIEButton onClick={this.handleSubmit} green={true}
                                                   style={{padding: '12px 30px'}}><span style={{
                                            ...customStyles.greenButton, fontSize: '16px'
                                        }}>Suivant</span></GIEButton>
                                    </div>
                                </GridItem>
                            </GridContainer>
                        </div>) : null}
                    </MediaQuery>
                    <MediaQuery maxWidth={Responsive.getMaxMobile()}>
                        {this.props.withButton !== false ? (<GridContainer direction="column"
                                                                           justifyContent="flex-end"
                                                                           alignItems="flex-end"
                                                                           style={{padding: '30px 40px'}}>
                            <GridItem xs={12}>
                                {this.renderGieCheckBox()}
                            </GridItem>
                            <GridItem xs={6}>
                                <GIEButton onClick={this.handleSubmit} green={true} style={{
                                    borderRadius: '2px', marginTop: '20px', padding: '12px 30px'
                                }}><span
                                    style={customStyles.greenButton}>{this.props.buttonText}</span></GIEButton>
                            </GridItem>
                        </GridContainer>) : null}
                        {this.props.offer === true ? (<div>
                            <GridContainer direction="column"
                                           justifyContent="flex-end"
                                           alignItems="flex-end"
                                           style={{padding: '20px 40px 40px'}}>
                                <GridItem xs={12}>
                                    <GIECheckBox isGrey
                                                 checked={this.state.formControls.confidentiality.value === true}
                                                 name="confidentiality"
                                                 errorText={this.state.formControls.confidentiality.errorMessage}
                                                 error={this.state.formControls.confidentiality.error}
                                                 emit={(event) => this.checkCGV(event)}
                                                 label="En cochant cette case, j’accepte que mes données personnelles soient utilisées par GIE à des fins de traitement de ma demande"/>
                                </GridItem>
                            </GridContainer>
                            <GridContainer direction="row"
                                           justifyContent="space-between"
                                           alignItems="center"
                                           style={{padding: '30px 40px'}}>
                                <GridItem>
                                    <div>
                                        <GIEButton style={{border: '1px solid #009550', padding: '12px 20px'}}
                                                   onClick={this.props.previous}><span style={{
                                            ...customStyles.spanGreen, fontSize: '16px'
                                        }}>Précédent</span> </GIEButton>
                                    </div>
                                </GridItem>
                                <GridItem>
                                    <div>
                                        <GIEButton onClick={this.handleSubmit} green={true}
                                                   style={{padding: '12px 30px', width: '110px'}}><span style={{
                                            ...customStyles.greenButton, fontSize: '16px'
                                        }}>Suivant</span></GIEButton>
                                    </div>
                                </GridItem>
                            </GridContainer>
                        </div>) : null}
                    </MediaQuery>
                </ValidatorForm>
            </div>
        </GIECard>);
    }
}
