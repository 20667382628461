/* istanbul ignore file */
import GenericController from './generic.controller';

export default class DownloadController extends GenericController {

    downloadDoc = (request) => {
        return new Promise((resolve, reject) => {
            this.requestPostBack('soap-download-doc/download-doc', request).then(data => {
                resolve(data)
            }).catch(err => {
                console.log(err);
                reject(err)
            })
        })
    }

    downloadCGV = () => {
        return new Promise((resolve, reject) => {
            this.requestGetBack('cgv/get-cgv').then(data => {
                resolve(data)
            }).catch(err => {
                console.log(err);
                reject(err)
            })
        })
    }
}
