/*eslint-disable*/
import React from "react";
// used for making the prop types of this component
import MediaQuery from 'react-responsive'
import WhiteBar from '../../components/WhiteBar/WhiteBar';
import FooterTop from './FooterTop'
import FooterMiddle from './FooterMiddle';
import FooterDown from './FooterDown';

import './Footer.css'

const customStyles = {
    footer: {
        width: '100%',
        left: '0px',
        background: 'linear-gradient(225deg,#273889,#111a45)',
        bottom: '0px',
        position: 'sticky',
        zIndex: 12
    } as React.CSSProperties
};

class Footer extends React.Component<any, any> {

    render() {
        return (
            <footer className="footer_wrap" style={{...customStyles.footer}}>
                <FooterTop/>
                <WhiteBar/>
                <FooterMiddle/>
                <MediaQuery minWidth={'1225px'}>
                    <WhiteBar/>
                </MediaQuery>
                <FooterDown/>
            </footer>
        );
    }
}


export default Footer;
