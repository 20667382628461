import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Icon from '@material-ui/core/Icon';
import ReactTooltip from 'react-tooltip';
import error from '../../assets/img/icons/error.svg';
import info from '../../assets/img/icons/info.svg';

import './GIETextField.css';
import {Responsive} from '../../util/responsive';

const ua = window.navigator.userAgent;
const isIE = /MSIE|Trident/.test(ua);
const styles = theme => ({
    gieLabel: {
        fontFamily: 'Hind Vadodara',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '20px',
        color: '#253A9F',
        marginBottom: '30px',
        lineHeight: '1%',
        marginTop: '10px !important'
    },
    gieLabelMobile: {
        fontFamily: 'Hind Vadodara',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '4vw',
        lineHeight: '1%',
        color: '#253A9F',
        width: '1000px'
    },
    icon: {
        marginTop: '-10px'
    },
    helperText: {
        marginLeft: '100px'
    },
    input: {
        '&::placeholder': {
            textOverflow: 'ellipsis !important',
            color: 'blue'
        }
    }

});

class GieTextField extends React.Component<any, any> {

    isMobile = () => {
        let isMobile = false;
        if (window.screen.width <= Responsive.getMaxMobile(true)) {
            isMobile = true
        }
        return isMobile;
    }

    getMarginBottom = () => {
        return this.props.alone ? '0vh' : '5vh'
    }

    getBackgroundColor = () => {
        return this.props.error ? '#fff0f0' : 'white';
    }

    getValue = () => {
        return this.props.value == null ? '' : this.props.value;
    }

    getMarginTop = () => {
        return !this.props.value?.length ? '370%' : '50px'
    }

    render() {
        const {classes} = this.props;
        return (
            <div style={{marginBottom: this.getMarginBottom(), backgroundColor: this.getBackgroundColor()}}>
                <div style={{display: 'flex'}}>
                    <TextField style={{width: '100%'}}
                               label={this.props.label}
                               onChange={this.props.onChange}
                               name={this.props.name} value={this.getValue()}
                               InputLabelProps={{
                                   shrink: true,
                                   className: this.isMobile() ? classes.gieLabelMobile : classes.gieLabel,
                                   disableAnimation: true,
                                   margin: 'dense'
                               }}
                               type={this.props.type}
                               multiline={this.props.multiline}
                               autoComplete={this.props.autocomplete}
                               InputProps={{
                                   className: classes.input,
                                   autoFocus: this.props.autofocus,
                                   startAdornment: (
                                       <InputAdornment position="start">
                                           <div>
                                               {this.props.error ? (<div style={{marginTop: this.getMarginTop()}}>
                                                   <Icon style={{
                                                       height: isIE ? '22px' : '30px',
                                                       width: isIE ? '17px' : '15px'
                                                   }}>
                                                       <img style={{height: isIE ? '75%' : '100%'}} alt="icon"
                                                            src={error}/>
                                                   </Icon>
                                               </div>) : null}
                                           </div>
                                       </InputAdornment>
                                   )
                               }}
                               rowsMax={4}
                               helperText={this.props.helperText}
                               error={this.props.error}
                               placeholder={this.props.placeholder}
                               required={this.props.required}
                               disabled={this.props.disabled}/>
                    {this.props.infoText != null ? (
                        <div style={{marginTop: '-5px'}}>
                            <ReactTooltip/>
                            <Icon data-tip={this.props.infoText}
                                  style={{height: isIE ? '22px' : '30px', width: isIE ? '17px' : '15px'}}>
                                <img style={{height: isIE ? '75%' : '100%'}} alt="icon" src={info}/>
                            </Icon>
                        </div>
                    ) : null}
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(GieTextField);
