import React from 'react';
import ReactGA from 'react-ga';
import GIECard from '../../components/GIECard/GieCard';
import GridItem from "../../components/Grid/GridItem.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import GIETextField from '../../components/GIETextField/GieTextField';
import GIEButton from '../../components/GIEButton/GieButton';
import GIEIcon from '../../components/GIEIcon/GIEIcon';
import {FormValidator} from '../../util/form-validator';
import LoginController from '../../controller/login.controller';

const ua = window.navigator.userAgent;
const isIE = /MSIE|Trident/.test(ua);
const customStyles = {
    content: {
        backgroundColor: '#202956',
        height: '100%',
        minHeight: '100vh',
        display: 'flex',
        paddingBottom: '10%'
    },
    contentTablet: {
        backgroundColor: '#202956',
        height: '100vh'
    },
    title: {
        fontFamily: 'Hind Vadodara',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '28px',
        lineHeight: '135%',
        color: '#202956',
        textAlign: 'center'
    } as React.CSSProperties,
    span: {
        fontFamily: 'Hind Vadodara',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '135%',
        color: '#202956'
    },
    spanMobile: {
        fontFamily: 'Hind Vadodara',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '135%',
        color: '#202956'
    },
    greenButton: {
        color: 'white',
        textTransform: 'none',
        fontSize: 'bold',
        fontFamily: 'Hind Vadodara'
    } as React.CSSProperties,
    connexion: {
        fontFamily: 'Hind Vadodara',
        fontStyle: 'normal',
        fontSize: '14px',
        lineHeight: '140%',
        color: '#253A9F'
    } as React.CSSProperties,
    connexionMobile: {
        fontFamily: 'Hind Vadodara',
        fontStyle: 'normal',
        fontSize: '14px',
        lineHeight: '140%',
        color: '#253A9F'
    } as React.CSSProperties
};

export default class ChangePassword extends React.Component<any, any> {
    formValidator: FormValidator = new FormValidator();
    loginController: LoginController = new LoginController();

    constructor(props) {
        super(props);
        this.state = {
            formControls: {
                createPassword: {
                    value: '',
                    error: false,
                    errorMessage: ''
                },
                confirmPassword: {
                    value: '',
                    error: false,
                    errorMessage: ''
                }
            },
            passwordChanged: false
        }
    }

    componentDidMount() {
        const token = window.location.href.split('/')[window.location.href.split('/').length - 1]
        const noContract = window.location.href.split('/')[window.location.href.split('/').length - 2]
        this.setState({noContract, token})
        ReactGA.pageview(window.location.pathname + window.location.search);
    }

    changeHandler = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        const error = this.formValidator.returnError(value, name);
        this.setState({
            formControls: {
                ...this.state.formControls,
                [name]: {
                    ...this.state.formControls[name],
                    value,
                    error: error.error,
                    errorMessage: error.errorMessage
                }
            }
        });
    }

    checkValidForm = () => {
        let formControls = this.state.formControls;
        let isError = false;

        Object.keys(formControls).forEach(name => {
            const error = this.formValidator.returnError(formControls[name].value, name)
            formControls[name].error = error.error;
            formControls[name].errorMessage = error.errorMessage;
            if (error.error === true) {
                isError = true
            }
        });

        if (formControls["createPassword"].value !== formControls["confirmPassword"].value) {
            formControls["confirmPassword"].error = true;
            formControls["confirmPassword"].errorMessage = "Les mots de passes ne sont pas identiques";
        }
        this.setState(formControls)
        return isError;
    }

    authenticate = () => {
        if (this.checkValidForm() === false) {
            const request = {
                noContract: this.state.noContract,
                token: this.state.token,
                password: this.state.formControls.confirmPassword.value
            }
            this.loginController.changePassword(request).then(data => {
                this.setState({passwordChanged: true})
            }).catch(err => {
                console.log(err)
            })
        }
    }

    getHeight = () => {
        let height = '';
        if (isIE && this.state.passwordChanged) {
            height = '310px';
        } else if (isIE && !this.state.passwordChanged) {
            height = '510px';
        } else {
            height = '100%'
        }
        return height;
    }

    render() {
        return (
            <div className="content">
                <div style={customStyles.content}>
                    <div style={{margin: 'auto', marginTop: '10%', position: "relative"}}>
                        <GIECard width="410px" height={this.getHeight()}>
                            <div style={{width: '100%', padding: '10% 10% 5%'}}>
                                {this.state.passwordChanged === false ? (<GridContainer direction="row"
                                                                                        justifyContent="flex-start"
                                                                                        alignItems="flex-start">
                                    <GridItem xs={12}>
                                        <div>
                                            <h2 style={customStyles.title}>Changer de mot de passe</h2>
                                        </div>
                                    </GridItem>
                                    <GridItem xs={12}>
                                        <GIETextField label="Votre nouveau mot de passe" required
                                                      type="password"
                                                      onChange={this.changeHandler}
                                                      name="createPassword"
                                                      value={this.state.formControls.createPassword.value}
                                                      helperText={this.state.formControls.createPassword.errorMessage}
                                                      error={this.state.formControls.createPassword.error}/>
                                    </GridItem>
                                    <GridItem xs={12}>
                                        <GIETextField label="Confirmer votre mot de passe" required
                                                      type="password"
                                                      onChange={this.changeHandler}
                                                      name="confirmPassword"
                                                      value={this.state.formControls.confirmPassword.value}
                                                      helperText={this.state.formControls.confirmPassword.errorMessage}
                                                      error={this.state.formControls.confirmPassword.error}/>
                                    </GridItem>
                                    <GridItem xs={12}>
                                        <GridContainer direction="row"
                                                       justifyContent="space-between"
                                                       alignItems="center"
                                                       style={{padding: '30px 0'}}>
                                            <GridItem>
                                                <div>
                                                    <a style={customStyles.connexion} href="/admin/login">
                                                        Se connecter
                                                    </a>
                                                </div>
                                            </GridItem>
                                            <GridItem>
                                                <GIEButton onClick={this.authenticate} green={true}
                                                           style={{height: '50px', width: '116px'}}><span
                                                    style={customStyles.greenButton}>Valider</span></GIEButton>
                                            </GridItem>
                                        </GridContainer>
                                    </GridItem>
                                </GridContainer>) : (
                                    <GridContainer direction="column"
                                                   justifyContent="flex-start"
                                                   alignItems="flex-start">
                                        <GridItem xs={12}>
                                            <div style={{marginLeft: '10%', marginTop: '20px', width: '300px'}}>
                                                <span style={{...customStyles.span, marginLeft: '15px'}}>Vous avez modifié votre mot de passe</span>
                                            </div>
                                            <div style={{marginLeft: '125px', marginTop: '10px'}}>
                                                <GIEIcon circle height={'15vw'} icon="mail"/>
                                            </div>
                                            <div style={{width: '310px', marginTop: '10px', marginLeft: ''}}>
                                                <a style={{...customStyles.connexion, marginLeft: '130px'}}
                                                   href="/admin/login"> Se connecter </a>
                                            </div>
                                        </GridItem>
                                    </GridContainer>)}
                            </div>
                        </GIECard>
                    </div>
                </div>
            </div>
        );
    }
}
